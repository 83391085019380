import { AlertColor, Backdrop, Box, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, FormHelperText, Grow, IconButton, Link, MenuItem, Modal, Paper, Select, TextField, ThemeProvider, Tooltip, Typography, Zoom } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import theme from "../../Theme";
import dayjs from 'dayjs';
import { FormEvent, useEffect, useRef, useState } from "react";
import { AllergenOut, DriveThruEventDayOut, DriveThruOrderIn, DriveThruOrderProductIn, EventVehicles, ShopOut } from "../../assets";
import { apiClient } from "../../functions/Login";
import ShopNotFound from "../../components/ShopNotFound";
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useLocation } from "react-router";
import {Helmet} from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import pdf from '../../AV.pdf'
import Alerter from "../../components/Alerter";
import ThempTheme from "../../TempTheme";

export default function DriveThruShop() {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale('nl')
    
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
    
    const downloadPDF = () => {
    const element = document.createElement("a");
    element.href = pdf;
    element.download = 'Algemene voorwaarden en Privacy beleid.pdf';
    document.body.appendChild(element); 
    element.click();
    };

    // Alerter constants
    const [openAlerter, setOpenAlerter] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>('info');
    const [text, setText] = useState('')

    // Scroll functionality
    const ref = useRef<null | HTMLDivElement>(null);
    const scrollClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    // Modal functionality
    const style = {
        position: 'absolute' as 'absolute',
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
      };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false)
    };
    const openModal = () => {
        setOpen(true)
    };

    const [pbOpen, setPbOpen] = useState(false);
    const closePbModal = () => {
        setPbOpen(false)
    };

    // helper functions
    const [dates, setDates] = useState<DriveThruEventDayOut[]>([]);
    const getAddress = () => {
        return ''//shopInfo?.drive_thru_event_locations![0].location!.company.delivery_address!.street! 
        // + " " 
        // //+ shopInfo?.drive_thru_event_locations![0].location.company.delivery_address.number.toString()
        // + ", "
        // + shopInfo?.drive_thru_event_locations![0].location.company.delivery_address.city
    }
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: '200px'
          },
        },
      };
    const stringToUrl = (s: string) => {
        return s.replaceAll(' ', "%20")
    }
    const sendQRCode = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        apiClient().mailing.sendDriveThruQrEmailMailingSendDriveThruQrEmailOrderEmailPost(resendEmail)

        setSeverity('success');
        setText('We hebben je verzoek ontvangen, houd je inbox in de gaten!');
        closeModal();
        setOpenAlerter(true);
    }
    const getVehicles = () => {
        var result = 'met de ';
        // if (shopInfo!.drive_thru_event_locations![0].drive_thru_event_vehicles!.length === 1 
        //     && 
        //     shopInfo!.drive_thru_event_locations![0].drive_thru_event_vehicles![0].drive_thru_event_vehicle === EventVehicles.VOETGANGER) {
        //         return '';
        //     }
        // shopInfo!.drive_thru_event_locations![0].drive_thru_event_vehicles!.forEach((vehicle, i) => {
        //     if (i === 0) {
        //         if (vehicle.drive_thru_event_vehicle !== EventVehicles.VOETGANGER) {
        //             result = result.concat(vehicle.drive_thru_event_vehicle) 
        //         }
                
        //     } 
        //     else if (vehicle.drive_thru_event_vehicle === EventVehicles.VOETGANGER) {
        //         result = result.concat(', te voet')
        //     }
        //     else {
        //         result = result.concat(', ' + vehicle.drive_thru_event_vehicle)
        //     }
            
        // })
        const position = result.lastIndexOf(',');
        if (position !== -1) {
            result = result.slice(0, position) + ' of' + result.slice(position + 1);
        }
        return result.toLowerCase()
    };

    const getVehiclesHandout = () => {
        var result = ' ';
        // shopInfo!.drive_thru_event_locations![0].drive_thru_event_vehicles!.forEach((vehicle, i) => {
        //     var newVehicle = '';
        //     if (vehicle.drive_thru_event_vehicle === EventVehicles.AUTO) {
        //         newVehicle = 'door je autoraam';
        //     }
        //     else if (vehicle.drive_thru_event_vehicle === EventVehicles.FIETS) {
        //         newVehicle = 'op de fiets';
        //     } else {
        //         newVehicle = 'tijdens je wandeling';
        //     }

        //     if (i === 0) {
        //         result = result.concat(newVehicle) 
        //     } else {
        //         result = result.concat(', ' + newVehicle)
        //     }
            
        // })
        const position = result.lastIndexOf(',');
        if (position !== -1) {
            result = result.slice(0, position) + ' of' + result.slice(position + 1);
        }
        return result.toLowerCase()
    };

    // general constants
    const [processing, setProcessing] = useState(false);
    const [starting, setStarting] = useState(true);

    // form constants
    const [name, setName] = useState<string>(''); 
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [vehicle, setVehicle] = useState('');
    const [date, setDate] = useState('');
    const [slot, setSlot] = useState('');

    const [resendEmail, setResendEmail] = useState('');

    // cart constants
    type cartItem = {
        id: string;
        productName: string;
        productOption: string;
        productDescription: string;
        price_incl: number;
        price_excl: number;
        image_url: string;
        allergens: AllergenOut[];
        amount: number;
        inCart: boolean;
    }
    const [cart, setCart] = useState<cartItem[]>([]);
    const cartTotal = () => {
        var total = 0.00;
        cart.filter(item => item.amount !== 0).forEach((item) => {
            total = total + (item.amount * item.price_incl)
        });
        return total.toFixed(2);
    }   
    const handleSetAmount = (i: number, amount: number, inCart: boolean = true) => {
        if (!Number.isNaN(amount) && amount >= 0) {
            const newCart = cart.map((item, index) => {
                if (index === i) {
                    return {...item, amount: amount, inCart: inCart};
                }
                return item;
            })
            setCart(newCart);
        }
        
    }

    const [shopInfo, setShopInfo] = useState<ShopOut | null>(null);
    useEffect(() => {
        if (!localStorage.getItem('host')) {
            localStorage.setItem('host', window.location.hostname.split('.')[0]);
        }
        apiClient().driveThru.getShopInfoByWebshopUrlDriveThruShopInfoLocationIdPost( '', {webshop_url: window.location.origin + window.location.pathname}).then((r) => {
            if (r === null) {
            } else {
                setShopInfo(r);
                
                // if (r.drive_thru_event_locations![0].drive_thru_event_vehicles!.length === 1) {
                //     setVehicle(r.drive_thru_event_locations![0].drive_thru_event_vehicles![0].drive_thru_event_vehicle)
                // }
                // const newDates: DriveThruEventDayOut[] = [];
                // r.drive_thru_event_locations![0].drive_thru_event_days!.forEach((day) => {
                //     newDates.push(day);
                // });
                // if (newDates.length === 1) {
                //     setDate(newDates[0].event_date_start_datetime);
                // }
                // setDates(newDates);

                var newCartItems: cartItem[] = [];
                // r.variant_actives!.forEach((va) => {
                //     va.variant.product_options!.forEach((v) => {
                //         var newCartItem = {
                //             id: va.id,
                //             productName: va.variant.sales_product?.sales_product_name,
                //             productOption: v.product_option_name,
                //             productDescription: va.variant.sales_product!.sales_product_description,
                //             price_incl: va.full_promotional_price_incl_vat_rounded,
                //             price_excl: va.full_promotional_price_excl_vat,
                //             image_url: va.variant.variant_image![0],
                //             allergens: va.variant.sales_product!.allergens!,
                //             amount: 0,
                //             inCart: false
                //         } as cartItem
                //         newCartItems.push(newCartItem);
                //     })
                // })
                setCart(newCartItems);
            }
            setTimeout(() => {
                setStarting(false);
            }, 1000)
        }).catch(() => {
            setStarting(false)
        });
        
    }, [])
    
    const submitOrder = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setProcessing(true)

        if (name !== '' && email !== '' && slot !== '') {
            const newOrder = {
                order_client_name: name,
                order_client_email: email,
                order_client_business: company,
                //event_location_id: shopInfo!.drive_thru_event_locations![0].id,
                timeslot_id: slot,
                shop_id: shopInfo!.id
            } as DriveThruOrderIn
            apiClient().driveThru.createOrderPublicDriveThruCreateOrderPost(newOrder).then((r) => {
                const items = cart.filter(item => item.amount > 0);
                const newProducts: DriveThruOrderProductIn[] = [];
                items.forEach((item, i) => {
                    const newOrderProduct = {
                        amount: item.amount,
                        price_excl_vat: item.price_excl,
                        vat_price: item.price_incl - item.price_excl,
                        variant_active_id: item.id,
                        drive_thru_order_id: r.id
                    } as DriveThruOrderProductIn
                    newProducts.push(newOrderProduct);
                });

                // apiClient().driveThru.addProductToOrderDriveThruAddProductsToOrderOrderIdPost(r.id, newProducts).then((p) => {
                //     if (p.items.length === newProducts.length) {
                //         apiClient().driveThru.createPaymentByOrderIdDriveThruPaymentsPublicOrderIdGet(r.id).then((r) => {
                //             setProcessing(false);
                //             window.location.href = r.checkout_link!;
                //         }).catch(() => {
                //             paymentFailed();
                //         })
                //     } else {
                //         paymentFailed();
                //     }
                // }).catch(() => {
                //     paymentFailed();
                // })
                // KAPOT
            });
        } else {
            paymentFailed();
        }
        
    };

    const paymentFailed = () => {
        setProcessing(false);
        setSeverity('error');
        setText('Er lijkt iets fout te gaan, controleer je gegevens en probeer het opnieuw.');
        setOpenAlerter(true);
    }
    return (
        <ThemeProvider theme={ThempTheme}>
            <Helmet>
                <title>{'Drive-thru - ' + (shopInfo ? shopInfo.shop_name : 'onbekend')}</title>
                <meta name="description" content={`Oliebollen Drive-thru ${shopInfo?.shop_name}`} />
            </Helmet>
            <Alerter
            open={openAlerter}
            setOpen={setOpenAlerter}
            severity={severity}
            text={text}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: 20 }}
                open={processing}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            {/* QR-code Popup */}
           <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="Qr-code popup"
            aria-describedby="Missing QR-code popup"
            >
            <Box sx={[style, {width: isMobile ? '90%' : '550px'}]}>
                <Box sx={{height: '64px', display: 'flex'}}>
                    <IconButton onClick={closeModal} sx={{ml: 'auto', height: '40px'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box sx={{px: isMobile ? '16px' : '64px', pb: '64px', display: 'flex', flexDirection: 'column'}}>
                    <Typography variant='headlineMedium'>
                        QR-code van je bestelling kwijt?
                    </Typography>
                    <Typography variant='bodyLarge' sx={{ my: '32px' }}>
                        Vul onderstaand je emailadres in. Als dit emailadres bij ons bekend is, ontvang je binnen enkele minuten de QR-code opnieuw in je mail! Controleer ook je spamfolder.
                    </Typography>
                    <form onSubmit={sendQRCode} autoComplete="chrome-off">
                        <TextField type='email' required onBlur={(e) => {setResendEmail(e.target.value)}} label="E-mailadres" variant="outlined" sx={{width: '100%' }}/>
                        <FormControlLabel
                            control={
                            <Checkbox required onChange={() => {}} name="email-check" />
                            }
                            label="Ik heb eerder een bestelling geplaatst met dit mailadres voor dit evenement."
                            sx={{my: '32px'}}
                        />
                        <Button type="submit" variant="contained" color="primary" sx={{width: isMobile ? '100%' : '200px', height: '40px', mr: 'auto', borderRadius: '20px'}}>
                            <Typography variant="labelLarge" sx={{px: '12px'}}>
                                Verzend QR-code  
                            </Typography> 
                        </Button>
                    </form>
                </Box>
                
            </Box>
            </Modal>
             {/* Algemene voorwaarden en privacybeleid Popup */}
           <Modal
            open={pbOpen}
            onClose={closePbModal}
            aria-labelledby="algemene voorwaarden popup"
            aria-describedby="algemene voorwaarden en privacybeleid popup"
            >
            <Box sx={[style, {width: '90%'}]}>
                <Box sx={{height: '64px', display: 'flex'}}>
                    <IconButton onClick={closePbModal} sx={{ml: 'auto', height: '40px'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box sx={{px: '64px', pb: '64px', display: 'flex', flexDirection: 'column'}}>
                    <Typography variant='headlineMedium' sx={{mb: '32px'}}>
                       Algemene voorwaarden en Privacybeleid
                    </Typography>
                    {
                        isMobile ?
                        <Button onClick={downloadPDF} variant="contained" sx={{height: '32px'}}>
                            Download
                        </Button>
                        :
                        <iframe style={{height: '60vh'}} src={pdf}>

                        </iframe>
                    }
                </Box>
            </Box>
            </Modal>

            { starting ?
                <Box sx={{display: "flex", justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                    <CircularProgress />
                </Box>
                
            :
                shopInfo === null ?
                <Box sx={{display: "flex", justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                    <ShopNotFound />
                </Box>
                : 
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <Box sx={{height: '90px', backgroundColor: '#705842', display: 'flex', alignItems: 'center'}}>
                    <img 
                        style={{marginLeft: isMobile ? '20px' : '64px', width: 'auto', height: 'auto', maxWidth: isMobile ? '64px' : '90px', maxHeight: isMobile ? '32px' : '42px'}} 
                        src={"https://loaf-public-metadata-bucket-prod.s3.eu-west-1.amazonaws.com/hogenboom/9b806faf-72bc-425f-bc5d-17a7be815a8b.png"}
                        alt="Hogenboom logo" 
                    />
                    <Button onClick={openModal} sx={{mx: '32px', ml: 'auto', color: '#ECE5DE', borderRadius: '20px', borderColor: '#FFA239', borderWidth: '1px', border: 1, height: '40px'}}>
                        <Typography variant="labelLarge" sx={{px: '12px'}}>
                            QR-code kwijt?  
                        </Typography> 
                    </Button>
                </Box>
                {/* Shop overview */}
                <Box sx={{ backgroundColor: 'secondary.main', display: 'flex', alignItems: 'stretch', flexDirection: isMobile ? 'column' : 'row'}}>
                    <Box sx={{ width: isMobile ? '90%' : '50%', mx: isMobile ? 'auto' : '64px', py: isMobile ? '16px' : '88px', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="headlineLarge">
                            {shopInfo.shop_name}
                        </Typography> 
                        <Typography variant="bodyLarge" sx={{my: '32px'}}>
                            Afhalen van jouw bestelling kan {dates.length === 2 ? dayjs(dates[0].event_date_start_datetime).format('[op] D MMMM ') + dayjs(dates[dates.length - 1]!.event_date_start_datetime).format('[en] D MMMM') : dates.length > 2 ? dayjs(dates[0].event_date_start_datetime).format('[van] D MMMM ') + dayjs(dates[dates.length - 1]!.event_date_start_datetime).format('[tot en met] D MMMM') : dayjs(dates[dates.length - 1]!.event_date_start_datetime).format('[op] D MMMM')} te: {getAddress()} 
                        </Typography>
                        <Box sx={{mb: '16px', display: 'flex'}}>
                            <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                            <Typography variant="bodyLarge">
                                Vooraf online bestellen
                            </Typography>
                        </Box> 
                        <Box sx={{mb: '16px', display: 'flex'}}>
                            <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                            <Typography variant="bodyLarge">
                                Af te halen op jouw eigen gekozen moment
                            </Typography>
                        </Box>
                        <Box sx={{mb: '32px', display: 'flex'}}>
                            <CheckCircleOutlineIcon sx={{mr: '8px'}}/>
                            <Typography variant="bodyLarge">
                                Gemakkelijk en snel af te halen {getVehicles()}
                            </Typography>
                        </Box>
                        <Box sx={{width: isMobile ? '300px' : '300px', display: 'flex', flexDirection: 'column', mx: isMobile ? 'auto' : ''}}>
                            <Button onClick={scrollClick} variant="contained" color="primary" sx={{borderRadius: '20px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Bestel en reserveer jouw tijdslot
                                </Typography> 
                            </Button>
                            <Typography variant="bodySmall" sx={{py: '12px', alignSelf: 'center'}}>
                                Voor tijdsloten geldt: VOL = VOL
                            </Typography> 
                        </Box>
                    </Box>
                    <Box sx={{width: isMobile ? '75%' : '50%', px: isMobile ? '8px' : '64px', py: isMobile ? '32px' : '88px', display: 'flex', mx: isMobile ? 'auto' : ''}}>
                        <iframe
                            title="Event locatie"
                            height={'100%'}
                            width={'100%'}
                            style={{margin: 'auto', borderRadius: '12px', border: 0}}
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
                                &q=${stringToUrl(getAddress())}`}
                            >
                        </iframe>
                    </Box>
                </Box>
                {/* Step overview */}
                <Box sx={{ p: isMobile ? '32px' : '64px', display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '16px' : ''}}>
                    <Box sx={{display: 'flex', width: isMobile ? '100%' : '30%'}}>
                        <Box sx={{backgroundColor: '#ECE5DE', p: '12px', borderRadius: '32px', width: '24px', height: '24px', display: 'flex', justifyContent: 'center'}}>
                            <ShoppingCartOutlinedIcon sx={{color: 'primary.main'}} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="headlineSmall" sx={{pt: '8px', pl: '16px'}}>
                                Online bestellen
                            </Typography> 
                            <Typography variant="bodyLarge" sx={{pt: '16px', alignSelf: 'end', pl: '16px'}}>
                                Plaats jouw online bestelling en kies een tijdslot op een moment dat het jou uitkomt. Zo spreiden wij de drukte.
                            </Typography> 
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', width: isMobile ? '100%' : '30%'}}>
                        <Box sx={{backgroundColor: '#ECE5DE', p: '12px', borderRadius: '32px', width: '24px', height: '24px', display: 'flex', justifyContent: 'center'}}>
                            <QrCode2OutlinedIcon sx={{color: 'primary.main'}} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="headlineSmall" sx={{pt: '8px', pl: '16px'}}>
                                QR-code in de mail
                            </Typography> 
                            <Typography variant="bodyLarge" sx={{pt: '16px', alignSelf: 'end', pl: '16px'}}>
                                Enkele minuten na het plaatsen van de bestelling, ontvang je een bestelbevestiging met scanbare QR-code via de mail.
                            </Typography> 
                        </Box>
                    
                    </Box>
                    <Box sx={{display: 'flex', width: isMobile ? '100%' : '30%'}}>
                        <Box sx={{backgroundColor: '#ECE5DE', p: '12px', borderRadius: '32px', width: '24px', height: '24px', display: 'flex', justifyContent: 'center'}}>
                            <DirectionsCarFilledOutlinedIcon sx={{color: 'primary.main'}} />
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="headlineSmall" sx={{pt: '8px', pl: '16px'}}>
                                Bestelling afhalen
                            </Typography> 
                            <Typography variant="bodyLarge" sx={{pt: '16px', alignSelf: 'end', pl: '16px'}}>
                                Op de dag van het evenement laat je de QR-code scannen en ontvang je jouw bestelling {getVehiclesHandout()}.
                            </Typography> 
                        </Box>
                    </Box>
                </Box>
                {/* Shop */}
                <Box ref={ref} sx={{backgroundColor: '#F8FAFA', display: 'flex', justifyContent: 'space-between', gap: '16px', p: '32px', flexDirection: isMobile ? 'column' : 'row'}}>
                    <Paper variant='outlined'  sx={{width: isMobile ? '80%' : '65%', p: '32px', display: 'flex', flexDirection: 'column', mx: isMobile ? 'auto' : ''}}>
                        <Typography variant="headlineLarge">
                            Mijn winkelmandje
                        </Typography> 
                        <Box sx={{display: 'flex', flexDirection: 'column', my: '8px'}}>
                            {
                                cart.map((item, i) => 
                                    <Paper key={item.id} variant='outlined' sx={{display: 'flex', my: '8px', flexDirection: isMobile ? 'column' : ''}}>
                                        {/* picture */}
                                        <Box sx={{height: '200px', minWidth: '200px', mr: '16px', width: isMobile ? '100%' : '', display: 'flex', alignItems: 'center'}}>
                                            <img src={item.image_url} alt="Product foto" style={{
                                                width: 'auto', height: 'auto', maxWidth: '200px', maxHeight: '200px', marginLeft: 'auto', marginRight: 'auto'
                                            }}/>
                                        </Box>
                                        {/* description */}
                                        <Box sx={{p: '16px', display: 'flex', flexDirection: 'column', width: isMobile ? 'auto' : '100%'}}>
                                            <Typography variant="titleLarge">
                                                {item.productName} ({item.productOption})
                                            </Typography> 
                                            <Typography sx={{mb: '24px', mt: '8px'}} variant="bodyLarge">
                                                {item.productDescription}
                                            </Typography> 
                                            <Box sx={{display: 'flex', gap: '8px', mb: '16px', mt: '-8px'}}>
                                            {
                                                item.allergens.map(a => {
                                                    return <img key={a.id} style={{width: '48px', height: '48px'}} src={a.allergen_icon ? a.allergen_icon : undefined} alt={a.allergen_name} />
                                                })
                                            }
                                            </Box>
                                            
                                            <Box sx={{display: 'flex', mt: 'auto', height: isMobile ? '70px' : '32px', flexDirection: isMobile ? 'column' : '', width: '100%'}}>
                                                <Box sx={{display: 'flex', alignItems: 'center', mb: isMobile ?'8px' : '0px'}}>
                                                    <EuroSymbolOutlinedIcon />
                                                    <Typography variant="headlineSmall" sx={{pl: '4px'}}>
                                                        {item.price_incl!.toFixed(2)}
                                                    </Typography> 
                                                </Box>
                                                {
                                                    !item.inCart ?
                                                        <IconButton onClick={() => {handleSetAmount(i, (item.amount + 1))}} sx={{width: '32px', height: '32px', ml: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor: isMobile ? 'primary.main': 'primary.dark'}}}>
                                                            <AddOutlinedIcon />
                                                        </IconButton>
                                                    :
                                                        <Grow 
                                                            in={item.inCart}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                            {...(item.inCart ? { timeout: 400 } : {})}
                                                        >
                                                            <Box sx={{ml: 'auto', display: 'flex', alignItems: 'center'}}>
                                                                {
                                                                    item.amount <= 1 ?
                                                                    <IconButton onClick={() => {handleSetAmount(i, 0, false)}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor: isMobile ? 'primary.main': 'primary.dark'}}}>
                                                                        <DeleteOutlinedIcon />
                                                                    </IconButton>
                                                                    : 
                                                                    <IconButton onClick={() => {handleSetAmount(i, (item.amount - 1))}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor: isMobile ? 'primary.main': 'primary.dark'}}}>
                                                                        <RemoveOutlinedIcon />
                                                                    </IconButton>

                                                                }
                                                                <TextField
                                                                    id={item.id}
                                                                    variant='outlined'
                                                                    type="tel"
                                                                    size="small"
                                                                    value={item.amount === 0 ? '' : item.amount}
                                                                    onChange={(e) => {handleSetAmount(i, +e.target.value)}}
                                                                    onBlur={(e) => {if (+e.target.value === 0) {handleSetAmount(i, 0, false)}}}
                                                                    InputProps={{ inputProps: { style: {textAlign: 'center'}, pattern: "[0-9]*"  } }}
                                                                    sx={{width: '60px', mx: '12px', '& fieldset': {
                                                                        borderRadius: '8px',
                                                                      },}}
                                                                /> 
                                                                <IconButton onClick={() => {handleSetAmount(i, (item.amount + 1))}} sx={{width: '32px', height: '32px', my: 'auto', backgroundColor: 'primary.main', color: '#fff', "&:hover": { backgroundColor: isMobile ? 'primary.main': 'primary.dark'}}}>
                                                                    <AddOutlinedIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Grow>
                                                }
                                                
                                            </Box>
                                        </Box>
                                    </Paper>
                                )
                            }
                        </Box>
                        <Box sx={{mt: 'auto', display: 'flex', justifyContent: 'space-between'}}>
                            <Typography sx={{my: 'auto'}} variant="labelLarge">
                                Totaalbedrag
                            </Typography> 
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <EuroSymbolOutlinedIcon />
                                <Typography variant="headlineSmall" sx={{pl: '4px'}}>
                                    {cartTotal()}
                                </Typography> 
                            </Box>
                        </Box>
                    </Paper>
                    <Paper variant='outlined' sx={{width: isMobile ? '80%' : '25%', p: '32px', mx: isMobile ? 'auto' : '', position: 'sticky', zIndex: '5', top: '10px', height: '700px'}}>
                        <Typography variant="headlineLarge">
                            Klantgegevens
                        </Typography> 
                        <form onSubmit={(e) => {submitOrder(e)}} autoComplete="chrome-off">
                            <TextField autoComplete="off" onChange={(e) => {setName(e.target.value)}} required label="Naam" variant="outlined" sx={{width: '100%', my: '16px' }}/>
                            <TextField autoComplete="off" type='email' onChange={(e) => {setEmail(e.target.value)}} required label="Emailadres" variant="outlined" sx={{width: '100%'}}/>
                            <TextField onChange={(e) => {setCompany(e.target.value)}} label="Bedrijfsnaam" variant="outlined" sx={{width: '100%', my: '16px' }}/>
                            <Typography variant="titleMedium">
                                Tijdslot
                            </Typography> 
                            {/* <Select
                            required
                            displayEmpty
                            value={vehicle}
                            disabled={shopInfo.drive_thru_event_locations![0].drive_thru_event_vehicles!.length === 1}
                            onChange={(e) => {setVehicle(e.target.value); setSlot('')}}
                            sx={{width: '100%', mt: '16px'}}
                            >
                                <MenuItem disabled value='' key=''>
                                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                        <DirectionsCarFilledOutlinedIcon />
                                        <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                            Voertuig   
                                        </Typography> 
                                    </Box>
                                </MenuItem>
                                {   
                                    shopInfo.drive_thru_event_locations![0].drive_thru_event_vehicles!.map((vehicle) => {
                                        return <MenuItem value={vehicle.drive_thru_event_vehicle} key={vehicle.drive_thru_event_vehicle}>
                                            <Typography variant="labelLarge">
                                                {vehicle.drive_thru_event_vehicle}
                                            </Typography>
                                        </MenuItem>
                                    })
                                }
                            </Select> */}
                            <Tooltip
                                TransitionComponent={Zoom}
                                enterTouchDelay={0}
                                TransitionProps={{ timeout: 100 }} 
                                title={(vehicle === '' && dates.length !== 1)? 'Selecteer eerst een voertuig.' : ''} 
                                arrow
                            >
                                <Select
                                required
                                displayEmpty
                                disabled={ vehicle === ''}
                                value={dates.length === 1 ? dates[0].event_date_start_datetime : date}
                                onChange={(e) => {setDate(e.target.value); setSlot('')}}
                                sx={{width: '100%', mt: '16px', display: dates.length === 1 ? 'none' : 'flex'}}
                                >
                                    <MenuItem disabled value='' key=''>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            <CalendarTodayOutlinedIcon />
                                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                                Datum   
                                            </Typography> 
                                        </Box>
                                    </MenuItem>
                                    {
                                        dates.map((date) => {
                                            return <MenuItem key={date.event_date_start_datetime} value={date.event_date_start_datetime}>
                                                <Typography variant="labelLarge">
                                                    {dayjs(date.event_date_start_datetime).format('DD-MM-YYYY')}   
                                                </Typography> 
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </Tooltip>
                            <Tooltip
                                TransitionComponent={Zoom}
                                enterTouchDelay={0}
                                TransitionProps={{ timeout: 100 }} 
                                title={vehicle === '' ? 'Selecteer eerst een voertuig.' : (date === '' && dates.length !== 1) ? 'selecteer eerst een datum.' : ''} 
                                arrow
                            >
                                <Select
                                required
                                disabled={vehicle === '' || (date === '' && dates.length !== 1)}
                                key={vehicle}
                                displayEmpty
                                value={slot}
                                onChange={(e) => {setSlot(e.target.value)}}
                                sx={{width: '100%', mt: '16px'}}
                                MenuProps={MenuProps}
                                >
                                    <MenuItem disabled value='' key=''>
                                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                            <AccessTimeOutlinedIcon />
                                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                                Tijdslot   
                                            </Typography> 
                                        </Box>
                                    </MenuItem>
                                    {
                                        // shopInfo.drive_thru_event_locations![0].drive_thru_event_vehicles!.find(v =>
                                        //     v.drive_thru_event_vehicle === vehicle)?.timeslots!.filter(ts => 
                                        //         dayjs(ts.timeslot_datetime_start).startOf('day').isSame(dayjs(date).startOf('day'))).sort((a,b) =>
                                        //         new Date(a.timeslot_datetime_start).valueOf() - new Date(b.timeslot_datetime_start).valueOf()).map((timeslot) => {
                                        //     return <MenuItem sx={{display: 'flex'}} disabled={timeslot.available_vehicles_timeslot <= 0 || dayjs.utc(timeslot.timeslot_datetime_start).isBefore(dayjs())} value={timeslot.id} key={timeslot.id}>
                                        //         <Typography variant="labelLarge">
                                        //             {dayjs.utc(timeslot.timeslot_datetime_start).local().format('HH:mm') 
                                        //             + dayjs.utc(timeslot.timeslot_datetime_end).local().format('[ -] HH:mm ')
                                        //             }
                                        //         </Typography>
                                        //         <Typography sx={{ml: 'auto'}} variant="labelLarge">
                                        //             {
                                        //                 (timeslot.available_vehicles_timeslot <= 5 ? timeslot.available_vehicles_timeslot <= 0 ? 'vol' : 'nog ' + timeslot.available_vehicles_timeslot.toString() + ' beschikbaar' : '')
                                        //             }
                                        //         </Typography>
                                        //     </MenuItem>
                                        // })
                                    }
                                </Select>
                            </Tooltip>
                            <Box sx={{my: '16px', display: 'flex', alignItems: 'center'}}>
                                <Checkbox required onChange={() => {}} name="statements" />
                                <Typography variant="labelMedium">
                                        Ik ga akkoord met de <Link sx={{cursor: 'pointer'}} onClick={() => {setPbOpen(true)}}>algemene voorwaarden en het privacybeleid</Link> *
                                    </Typography>
                            </Box>
                            <Button disabled={cartTotal() === "0.00"} type="submit" variant="contained" color="primary" sx={{borderRadius: '20px', width: '100%', height: '40px'}}>
                                <Typography variant="labelLarge" sx={{px: '12px'}}>
                                    Afrekenen
                                </Typography> 
                            </Button>
                            <FormHelperText error={true} sx={{mx: 'auto', mb: '16px', visibility: cartTotal() === '0.00' ? 'visible' : 'hidden'}}>
                                <Typography variant="labelSmall">
                                    Voeg eerst producten toe aan je winkelmandje.
                                </Typography>
                                
                            </FormHelperText>  
                        </form>
                        <Box sx={{display: 'flex'}}>
                            <VerifiedUserOutlinedIcon />
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant="bodyMedium" sx={{px: '12px', mb: '8px'}}>
                                    Veilige betaling
                                </Typography>
                                <Typography variant="bodySmall" sx={{px: '12px'}}>
                                    Verbindingen zijn versleuteld met SSL 
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Box>
            }
        </ThemeProvider>
    );
}


