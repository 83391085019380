import { Box, TableCell, Typography } from "@mui/material";
import StatusChip from "../orders/StatusChip";
import WebshopIcon from "../orders/WebshopIcon";
import PaymentStatusChip from "./PaymentStatusChip";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import EuroOutlined from "@mui/icons-material/EuroOutlined";

interface CustomTableCellProps {
    value: any
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({value}) => {
    // set dayjs configarution
    dayjs.extend(utc);
    dayjs.extend(timezone);

    let cellValue: any;

    let betaalStatus = [
        'Betaald', 'Open', 'Refund'
    ]

    let webshops = [
        'Abbonnement', 'Drive-thru', 'Standaard', 'Zakelijk', 'Filiaal'
    ]
    
    let status = [
        'Besteld',  'Orderbevestiging', 'In behandeling',
        'Voorraadcontrole', 'Gescand', 'Orderpicking', 'Gereed voor levering',
        'Onderweg', 'Geleverd', 'Facturatie', 'Afgesloten', 'Geannuleerd', 'Actief' ,'Inactief'
    ] 
    interface ProductGroup {
        name: string
        aantallen: string[]
    }
    const instanceOfProductGroup = (object: any): object is ProductGroup => {
        return 'aantallen' in object;
    }

    interface ProductGroup {
        naam: string
        opties: string[]
    }
    const instanceOfProductOption = (object: any): object is ProductGroup => {
        return 'opties' in object;
    }

    interface Product {
        Naam: string
        Varianten: string[]
    }
    const instanceOfProduct = (object: any): object is Product => {
        return 'Varianten' in object;
    }

    // Check if the cell value is special and needs a different representation. Above is the list of keywords 
    // per type of representation.
    if (status.includes(value)) {
        cellValue = <StatusChip status={value} />
    } else if (webshops.includes(value)) {
        cellValue = <WebshopIcon webshop={value} />
    } else if (betaalStatus.includes(value)) {
        cellValue = <PaymentStatusChip status={value} />
    } else if (dayjs(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
        cellValue = dayjs.utc(value).tz('Europe/Amsterdam').format('DD/MM/YYYY HH:mm')
    } else if (value === null || value.length === 0) {
        cellValue = '-'
    } else if (Array.isArray(value)) {
        if (typeof value[0] === 'string' && value[0].includes('bucket')){
            cellValue = <img src={value[0]} alt='' style={{maxHeight: '60px'}} />
        } else {
            cellValue = value.join(', ')
        }
    } else if (typeof value === 'object' && instanceOfProductGroup(value)) {
        cellValue = <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant='bodyMedium' sx={{fontWeight: '700' }}>{value.name}</Typography>
            <Typography variant='bodyMedium'>{value.aantallen ? value.aantallen.join(' | ') : ''}</Typography>
        </Box>
    } else if (typeof value === 'object' && instanceOfProduct(value)) {
        cellValue = <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant='bodyMedium' sx={{fontWeight: '700' }}>{value.Naam}</Typography>
            <Typography variant='bodyMedium'>{value.Varianten ? value.Varianten.join(' | ') : ''}</Typography>
        </Box>
    } else if (typeof value === 'object' && instanceOfProductOption(value)) {
        cellValue = <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            <Typography variant='bodyMedium' sx={{fontWeight: '700' }}>{value.naam}</Typography>
            <Box sx={{display: 'flex', gap: '4px'}}>
                {
                    value.opties.filter(o => o.length > 0).map((optie) => 
                        <Box sx={{display: 'flex', borderRadius: '24px', width: 'fit-content', backgroundColor: '#D18C5C30', py: '4px', px: '8px',  border: '1px solid #D18C5C', overflow: 'hidden'}}>
                            <Typography variant='bodyMedium' sx={{color: '#D18C5C'}}>{optie}</Typography>
                        </Box>
                    )
                }
            </Box>
        </Box>
    } else if (typeof value === 'string' && value.includes('bucket')) {
        cellValue = <img src={value} alt='' style={{maxHeight: '60px'}} />
    } else if (typeof value === 'number') {
        cellValue = <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
            <EuroOutlined />
            <Typography variant='bodyMedium' sx={{fontWeight: '700' }}>{value.toFixed(2).toString().replaceAll('.', ',')}</Typography>
        </Box>
    } else {
        cellValue = <Typography variant='bodyMedium' sx={{fontWeight: '700' }}>{value}</Typography>
    }

    return (
      <TableCell width={'fit-content'}>
        { cellValue }
      </TableCell>
    );
  };

export default CustomTableCell;