/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Allergens {
    GLUTEN = 'Gluten',
    SCHAALDIEREN = 'Schaaldieren',
    EI = 'Ei',
    VIS = 'Vis',
    PINDA = 'Pinda',
    SOJA = 'Soja',
    MELK = 'Melk',
    NOTEN = 'Noten',
    SELDERIJ = 'Selderij',
    MOSTERD = 'Mosterd',
    SESAMZAAD = 'Sesamzaad',
    ZWAVELDIOXIDE = 'Zwaveldioxide',
    LUPINE = 'Lupine',
    WEEKDIEREN = 'Weekdieren',
}
