import { Box, Step, StepLabel, Stepper, Typography} from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignals } from "@preact/signals-react/runtime";
import ButtonFull from "../../../components/buttons/ButtonFull";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useEffect, useRef } from "react";

interface FourthPageProps {
    setStep: any
    steps: Array<string>
    activeStep: Signal<number>
    host: Signal<string>;
    email: Signal<string>;
    password: Signal<string>;
  }
  
const FourthPage: React.FC<FourthPageProps> = ({ setStep, steps, activeStep, host, email, password}) => {
    useSignals();

    const redirect = () => {
        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
            window.location.href = `http://${host.value}.loaf-it.dev/bedrijf/gegevens`
        } else {
            window.location.href = `https://${host.value}.loaf-it.com/bedrijf/gegevens`
        }
    }

    return (
        <Box sx={{display: 'flex', height: '75%', width: '75%'}}>
            {/* Left side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '32px', width: '70%', backgroundColor: '#fff', height: '100%', borderRadius: '16px 0 0 16px'}}>
                <Stepper activeStep={activeStep.value}>
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step sx={{cursor: activeStep.value > index ? 'pointer' : ''}} onClick={(e) => {if (activeStep.value > index) setStep(e, index)}} key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', gap: '32px'}}>
                    <Typography variant='headlineMedium'>
                        Gefeliciteerd! Je staat op het punt om jouw winstgevende online omgeving in te stellen.
                    </Typography>
                    <Typography variant='bodyLarge'>
                        Je kunt nu aan de slag met het instellen van jouw omgeving. Bekijk onze instructievideo’s voor extra ondersteuning bij het instellen.
                    </Typography>
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <ButtonFull disabled={host.value.length === 0} width='50%' text='Start met instellen' onClick={redirect}/>
                    </Box>
                   
                </Box>
            </Box>

            {/* Right side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '64px', width: '30%', backgroundColor: 'primary.main', height: '100%', alignItems: 'start', justifyContent: 'center', borderRadius: '0 16px 16px 0'}}>
            <Typography variant='headlineLarge' sx={{fontWeight: '700', color: '#fff'}}>
                    All-in tarief
                </Typography>
                <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Wij vragen een <Typography variant='bodyLarge' sx={{fontWeight: '700', color: '#fff'}}>omzetfee van 6%</Typography>. Je betaalt pas als je iets verkoopt, zo creëeren wij een win-win situatie!
                </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Webshop in jouw huisstijl
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Drive-thru mobiele applicatie (inclusief bestellen tijdens het evenement)
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Drive-thru desk applicatie voor verzamelen en afmelden van bestellingen
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Dashboard met overzicht van bestellingen per tijdslot en details van bestellingen
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Artikelen beheren en in één overzicht zien hoeveel artikelen jij moet produceren
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default FourthPage;