import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Checkbox, Chip, FormControl, FormHelperText, IconButton, InputAdornment, MenuItem, Paper, Select, Slider, Switch, TextField, ThemeProvider, Typography } from "@mui/material";
import Confirm from "../../components/modals/Confirm";
import theme from "../../Theme";
import ButtonBack from "../../components/buttons/ButtonBack";
import ButtonOutlined from "../../components/buttons/ButtonOutlined";
import ButtonFull from "../../components/buttons/ButtonFull";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import { apiClient } from "../../functions/Login";
import { useNavigate, useParams } from "react-router-dom";
import Split from "../../components/layout/Split";
import Card from "../../components/layout/Card";
import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { ApiError, ArtikelNaamOut, ComposedProductOut, EventVehicles, LocationNaamOut, ProductType, SalesProductOut, ShopSubCategoryOut, VariantOut } from "../../assets";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DriveThruCategoryAdd from "../../components/drivethru/DriveThruCategoryAdd";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import locale from 'antd/es/date-picker/locale/nl_NL';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import OpeningTimesOverview from "../../components/drivethru/OpeningTimesOverview";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';

interface Location {
    selectedLocation: string
    startEvent: Dayjs | null
    endEvent: Dayjs | null
    timeslotTime: Dayjs | null
    openingTimes: OpeningTime[]
    desks: number
    vehicles: Vehicle[]
}

interface OpeningTime {
    active: boolean
    date:  Dayjs
    startTime: Dayjs | null
    endTime: Dayjs | null
}

interface Vehicle {
    type: string
    amount: number
}


export default function DriveThruEdit() {
    var utc = require("dayjs/plugin/utc");
    var timezone = require("dayjs/plugin/timezone"); 
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const navigate = useNavigate();
    const shop_id = String(useParams().shop_id);

    useSignals();
    const openDeleteModal: Signal<boolean> = useSignal(false);
    const name: Signal<string> = useSignal('');
    const categories: Signal<ShopSubCategoryOut[]> = useSignal([]);
    const selectedCategory: Signal<string> = useSignal('');
    const openCategoryAdd: Signal<boolean> = useSignal(false);
    const urlExist: Signal<boolean> = useSignal(false);
    const urlExtension: Signal<string> = useSignal('');
    const webshopFrom: Signal<Dayjs | null> = useSignal(null);
    const webshopTill: Signal<Dayjs | null> = useSignal(null);
    const locations: Signal<Location[]> = useSignal([{
        selectedLocation: '',
        startEvent: null,
        endEvent: null,
        timeslotTime: null,
        openingTimes: [],
        desks: 1,
        vehicles: [{
            amount: 0,
            type: ''
        } as Vehicle]
    } as Location]);
    const allLocations: Signal<LocationNaamOut[]> = useSignal([]);
    const expanded: Signal<number> = useSignal(0);
    const productsExpanded: Signal<number> = useSignal(0);
    const perLocation: Signal<boolean> = useSignal(false);
    const products: Signal<ArtikelNaamOut[]> = useSignal([]);
    const selectedProducts: Signal<((Omit<SalesProductOut, 'variants'> & {'variants': (VariantOut & {is_active: boolean})[]}) | ComposedProductOut)[][]> = useSignal([[]]);
    const selectedProductsNaam: Signal<ArtikelNaamOut[][]> = useSignal([[]]);

    const saveDriveThru = () => {

    }

    const urlPrefix = window.location.origin + '/drive-thru/'
    const checkUrl = (extension: string) => {
        apiClient().shops.checkWebshopUrlShopsShopsWebshopUrlPost({webshop_url: urlPrefix + extension}).then(() => {
            urlExist.value = false;
        }).catch((error: ApiError) => {
            if (error.status === 409){
                urlExist.value = true;
            }
        })
        urlExtension.value = extension;
    } 
    
    const checkOpeningTimes = () => {
        locations.value = locations.value.map((location) => {
            if (location.startEvent !== null && location.endEvent !== null) {
                 // filter out dates that are no longer relevant
                let arr: OpeningTime[] = location.openingTimes.filter(ot => (ot.date.isBefore(location.endEvent) && ot.date.isAfter(location.startEvent)) || ot.date.isSame(location.startEvent))

                let currDate: Dayjs = location.startEvent
                for (currDate = location.startEvent; !currDate.isAfter(location.endEvent); currDate = currDate.add(1, 'day')) {
                    if (arr.every(ot => !ot.date.isSame(currDate))) {
                        arr = [...arr, {
                            active: true,
                            date: currDate,
                            startTime: currDate.hour(9).minute(0),
                            endTime: currDate.hour(17).minute(0)
                        } as OpeningTime]
                    }
                }

                return {
                    ...location,
                    openingTimes: arr
                }
            }
            return {...location}
        })
    }

    const checkNumeric = (input: string) => {
        const decimalRegex = /^\d+\,?\d{0,2}$/;
        
        if (input.match(decimalRegex) || input === '') {
            return true
        } 
        return false
    }

    const updateLocation = (index: number, value: any, field: string) => {
        locations.value = locations.value.map((location, i) => i === index ? {...location, [field]: value} : {...location})
    }

    const updateVehicle = (index: number, vehicleIndex: number, value: any, field: string) => {
        locations.value = locations.value.map((location, i) => i === index ? {...location, vehicles: location.vehicles.map((v, ii) =>
            vehicleIndex === ii ? {...v, [field]: value} : {...v}
        )} : {...location})
    }

    const addVehicle = (index: number) => {
        locations.value = locations.value.map((location, i) => i === index ? {...location, vehicles: [...location.vehicles, {amount: 0, type: ''} as Vehicle]} : {...location})
    }

    const removeVehicle = (index: number, vehicleIndex: number) => {
        locations.value = locations.value.map((location, i) => i === index ? {...location, vehicles: location.vehicles.filter((v, ii) =>
            ii !== vehicleIndex
        )} : {...location})
    }

    const updateProducts = (productsIndex: number, productIndex: number, id: string) => {
        selectedProducts.value = selectedProducts.value.map(((products, i) => 
            i === productsIndex ? products.map((product, ii) => ii === productIndex ? {...product} : {...product}) : products
        ))
    }

    const updateVariants = (productsIndex: number, productIndex: number, variantIndex: number, field: string, value: any) => {
        selectedProducts.value = selectedProducts.value.map(((products, i) => 
            i === productsIndex ? products.map((product, ii) => (ii === productIndex && 'variants' in product) ? {...product, variants: product.variants.map((v, iii) => iii === variantIndex ? {...v, [field]: value} : v)} : product) : products
        ))
    }

    const addOrRemoveProduct = (productsIndex: number, value: ArtikelNaamOut[]) => {

        if (selectedProductsNaam.value[productsIndex].length > value.length) {
            let id = selectedProductsNaam.value[productsIndex].filter(s => !value.includes(s))[0].id;
            selectedProductsNaam.value = selectedProductsNaam.value.map((p, i) => i === productsIndex ? value : p);
            selectedProducts.value = selectedProducts.value.map(((products, i) => 
                i === productsIndex ? products.filter((product) => product.id !== id) : products
            ))
        } else {
            let id = value[value.length - 1].id;
            selectedProductsNaam.value = selectedProductsNaam.value.map((p, i) => i === productsIndex ? value : p);
            if (value[value.length - 1].product_type === ProductType.LOS_ARTIKEL) {
                apiClient().itemManagement.getSalesProductByIdItemsSalesProductsSalesProductIdGet(id).then((r) => {
                    selectedProducts.value = selectedProducts.value.map(((products, i) => 
                        i === productsIndex ? [...products, {...r, variants: r.variants ? r.variants.map((v) => {return {...v, is_active: true}}) : []}] : products
                    ))
                })
            } else {
                apiClient().itemManagement.getComposedProductByIdItemsComposedProductsComposedProductIdGet(id).then((r) => {
                    selectedProducts.value = selectedProducts.value.map(((products, i) => 
                        i === productsIndex ? [...products, r] : products
                    ))
                })
            }
           
        }
    } 

    const deleteProduct = (productsIndex: number, productIndex: number) => {
        selectedProductsNaam.value = selectedProductsNaam.value.map((s, i) => i === productsIndex ? s.filter((_, ii) => ii !== productIndex) : s);
        selectedProducts.value = selectedProducts.value.map(((products, i) => 
            i === productsIndex ? products.filter((product, ii) => ii !== productIndex) : products
        ))
    }

    useEffect(() => {
        apiClient().shops.getShopByIdShopsShopsShopIdGet(shop_id).then((r) => {
            name.value = r.shop_name;
            selectedCategory.value = r.sub_category_id ? r.sub_category_id : '';
            urlExtension.value = r.webshop_url.replace(window.location.origin + '/drive-thru/', '');
            webshopFrom.value = dayjs.utc(r.shop_opening_datetime).tz('Europe/Amsterdam');
            webshopTill.value = dayjs.utc(r.shop_closing_datetime).tz('Europe/Amsterdam');
            locations.value = r.locations ? r.locations.map((location, i) => {
                selectedProducts.value = [...selectedProducts.value, []];
                selectedProductsNaam.value = [...selectedProductsNaam.value, []];

                let salesProducts: (Omit<SalesProductOut, 'variants'> & {'variants': (VariantOut & {is_active: boolean})[]})[]= [];
                let composedProducts: ComposedProductOut[] = [];

                if (location.variant_actives) {
                    location.variant_actives.forEach((variant, i) => {
                        if (variant.variant.sales_product && !salesProducts.map(s => s.id).includes(variant.variant.sales_product.id)) {
                            salesProducts.push({...variant.variant.sales_product, variants: []});
                        } 
                    })
                }
                if (location.composed_product_actives) {
                    composedProducts = location.composed_product_actives.map(c => c.composed_product as ComposedProductOut);
                }

                selectedProducts.value = selectedProducts.value.map((sp, ii) => i === ii ? composedProducts : sp);
                selectedProducts.value = selectedProducts.value.map((sp, ii) => i === ii ? sp.concat(salesProducts) : sp);
                selectedProducts.value = selectedProducts.value.map((sp, ii) => i === ii ? sp.map((p) => 'sales_product_name' in p ? {...p, variants: location.variant_actives!.map((va) =>{ return {...va.variant, is_active: va.is_active} as VariantOut & {is_active: boolean}})}: p) : sp)
                selectedProductsNaam.value = selectedProductsNaam.value.map((sel, ii) => i === ii ? products.value.filter(p => selectedProducts.value[i].map(sp => sp.id).includes(p.id)) : sel)

                return {
                    selectedLocation: location.location_id,
                    startEvent: location.drive_thru_event_locations ? dayjs.utc(location.drive_thru_event_locations[0].event_datetime_start).tz('Europe/Amsterdam'): dayjs(),
                    endEvent: location.drive_thru_event_locations ? dayjs.utc(location.drive_thru_event_locations[0].event_datetime_end).tz('Europe/Amsterdam') : dayjs(),
                    timeslotTime: location.drive_thru_event_locations ? dayjs().hour(Math.floor(location.drive_thru_event_locations[0].timeslot_duration_minutes / 60)).minute(location.drive_thru_event_locations[0].timeslot_duration_minutes % 60): dayjs(),
                    desks: location.drive_thru_event_locations ? location.drive_thru_event_locations[0].distribution_points : 0,
                    vehicles: location.drive_thru_event_locations ? location.drive_thru_event_locations[0].drive_thru_event_vehicles ? location.drive_thru_event_locations[0].drive_thru_event_vehicles.map((vehicle) => {return {
                        amount: vehicle.max_vehicles_timeslot,
                        type: vehicle.drive_thru_event_vehicle
                    } as Vehicle}) : [] : [],
                    openingTimes: location.drive_thru_event_locations ? location.drive_thru_event_locations[0].drive_thru_event_days ? location.drive_thru_event_locations[0].drive_thru_event_days.map((day) => {return {
                        active: day.is_open,
                        startTime: dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam'),
                        endTime: dayjs.utc(day.event_date_end_datetime).tz('Europe/Amsterdam'),
                        date: dayjs.utc(day.event_date_start_datetime).tz('Europe/Amsterdam').hour(0).minute(0).second(0),
                    } as OpeningTime}) : [] : []
                } as Location
            }) : [];
        })
        apiClient().itemManagement.getAllProductsItemsProductsGet().then((r) => {
            products.value = r.items
        })
        apiClient().userManagement.getAllSubsidiariesUsersSubsidiariesGet().then((r) => {
            allLocations.value = r.items;
        })
    }, [])

    useEffect(() => {
        apiClient().shops.getAllShopSubCategoriesShopsShopSubCategoriesGet().then((r) => {
            categories.value = r.items;
        })
    }, [openCategoryAdd.value])

    return (
        <ThemeProvider theme={theme}>
            <DriveThruCategoryAdd open={openCategoryAdd} category={selectedCategory}/>
            <Confirm 
                open={openDeleteModal} 
                func={() => {apiClient().shops.deleteShopByIdShopsShopsShopIdDelete(shop_id).then(() => {navigate('/webshops/drive-thru')})}}
                information_text='Door op bevestigen te drukken wordt deze webshop permanent verwijdert.'
            />
            <Box sx={{mr: '16px', mb: '16px'}}>
                {/* Header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                    <ButtonBack />

                    <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                        {name.value}
                    </Typography> 

                    <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Artikel verwijderen'/>
                    <ButtonFull type='submit' form='product' onClick={() => {}} text='Gegevens opslaan'/>
                </Box>

                {/* Body */}
                <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveDriveThru()}} id='product' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>  
                <Split 
                    left={
                        <Card title='Evenement'
                            children={
                                <>
                                <TextField required label="Naam event" variant="outlined" value={name.value} onChange={(e) => {name.value = e.currentTarget.value}}/>
                                <Select
                                        displayEmpty
                                        required
                                        onChange={(e) => {selectedCategory.value = e.target.value}}
                                        value={selectedCategory.value}
                                        defaultValue=''
                                        sx={{width: '100%', mt: '16px'}}
                                    >
                                        <MenuItem value='' disabled>
                                            <Typography variant="labelLarge" sx={{my: 'auto'}}>
                                                Categorieën   
                                            </Typography> 
                                        </MenuItem>
                                        {
                                            categories.value.map((category) => 
                                            <MenuItem value={category.id} key={category.id}>
                                                <Typography variant='labelLarge'>{category.shop_sub_category_name}</Typography>
                                            </MenuItem>
                                            )
                                        }
                                        <MenuItem sx={{display: 'flex', alignItems: 'center', '&.Mui-selected': {backgroundColor: '#fff'}}}>
                                            <Box onClick={(e) => {e.stopPropagation(); e.preventDefault()}} sx={{display: 'flex', justifyContent: 'end', width: '100%'}}>
                                                <IconButton onClick={() => {openCategoryAdd.value = true}} sx={{width: '40px', height: '40px'}}>
                                                    <AddOutlinedIcon />
                                                </IconButton>
                                            </Box>
                                        </MenuItem>

                                    
                                    </Select>
                                </>
                            }
                        />
                    }
                    right={
                        <Card title='Webshop'
                            children={
                                <>
                                    <TextField 
                                    required
                                    label="Webshop URL" 
                                    defaultValue={urlExtension.value}
                                    value={urlExtension.value}
                                    error={urlExist.value}
                                    variant="outlined" 
                                    onBlur={(e) => {checkUrl(e.target.value)}}
                                    onChange={(e) => {urlExtension.value = e.currentTarget.value}}
                                    sx={{width: '100%', mt: '16px' }} 
                                    InputProps={{
                                        startAdornment: <InputAdornment sx={{mr: 0}} position="start">{urlPrefix}</InputAdornment>,
                                    }}
                                />
                                <FormHelperText error={true} sx={{mx: 'auto', visibility: urlExist.value ? 'visible' : 'hidden'}}>
                                    <Typography variant="labelMedium">
                                        Deze URL bestaat al.
                                    </Typography>
                                </FormHelperText>
                                <Typography variant='bodyMedium'>Kopieer deze URL en voeg deze toe aan jouw eigen website. Op die manier weten je klanten jouw drive-thru te vinden!</Typography>
                                <Box sx={{display: 'flex', gap: '16px'}}>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                            DatePicker: {
                                                activeBorderColor: '#28555e',
                                                cellRangeBorderColor: '#28555e',
                                                hoverBorderColor: '#28555e',
                                                colorPrimary: '#28555e',
                                                colorBorder: '#c4c4c4',
                                                borderRadius: 4,
                                                colorTextPlaceholder: '#000',
                                                activeShadow: 'none',
                                                presetsWidth: 360,
                                                zIndexPopup: 2000,
                                                zIndexPopupBase: 2000,
                                                zIndexBase: 2000
                                            },
                                            },
                                        }}
                                    >
                                        <DatePicker maxDate={webshopTill.value ? webshopTill.value : undefined} required style={{width: '100%', height: '56px'}} placeholder='Openingstijd webshop' onChange={(e) => {webshopFrom.value = e}} value={webshopFrom.value} format={'DD-MM-YYYY'} locale={locale} />
                                        <DatePicker minDate={webshopFrom.value ? webshopFrom.value : undefined} required style={{width: '100%', height: '56px'}} placeholder='Sluitingstijd webshop' onChange={(e) => {webshopTill.value = e}} value={webshopTill.value} format={'DD-MM-YYYY'} locale={locale} />
                                    </ConfigProvider>
                                </Box>
                                </>
                            }
                        />
                    }
                />
                <Card title='Locatie(s)'
                    children={
                        locations.value.length <= 1 ?
                        <>
                            <Split 
                                leftWidth={60}
                                left={
                                    <>
                                        <Typography variant='labelLarge' sx={{fontWeight: '700'}}>Locatie</Typography>
                                        <FormControl>
                                            <Select
                                                displayEmpty
                                                IconComponent={ExpandMoreOutlinedIcon}
                                                value={locations.value[0].selectedLocation}
                                                renderValue={ (selected: string) => {
                                                    if (selected === '') {
                                                        return (
                                                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                                                <RoomOutlinedIcon />
                                                                <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                                                    Kies locatie   
                                                                </Typography> 
                                                            </Box>
                                                        )
                                                    } else {
                                                        return (
                                                            allLocations.value.filter(l => l.id === selected)[0].name
                                                        )
                                                    }
                                                }
                                                }
                                                MenuProps={{ style: {maxHeight: '300px'}}}
                                                onChange={(e) => {locations.value = locations.value.map((l, i) => i === 0 ? {...l, selectedLocation: e.target.value} : {...l})}}
                                                sx={{width: '100%', maxHeight: '60px', borderRadius: '4px'}}
                                            >
                                                {
                                                    allLocations.value.map((location) => 
                                                        <MenuItem value={location.id} key={location.id}>
                                                            {location.name}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                            <ConfigProvider
                                                        theme={{
                                                            components: {
                                                            DatePicker: {
                                                                activeBorderColor: '#28555e',
                                                                cellRangeBorderColor: '#28555e',
                                                                hoverBorderColor: '#28555e',
                                                                colorPrimary: '#28555e',
                                                                colorBorder: '#c4c4c4',
                                                                borderRadius: 4,
                                                                colorTextPlaceholder: '#000',
                                                                activeShadow: 'none',
                                                                presetsWidth: 360,
                                                                zIndexPopup: 2000,
                                                                zIndexPopupBase: 2000,
                                                                zIndexBase: 2000
                                                            },
                                                            },
                                                        }}
                                            >
                                            <Box sx={{display: 'flex', gap: '16px'}}>
                                                <DatePicker minDate={webshopFrom.value ? webshopFrom.value : undefined} maxDate={locations.value[0].endEvent ? locations.value[0].endEvent : undefined} required style={{width: '100%', height: '56px'}} placeholder='Startdatum evenement' onChange={(e) => {updateLocation(0, e, 'startEvent'); checkOpeningTimes();}} value={locations.value[0].startEvent} format={'DD MMMM'} locale={locale} />
                                                <DatePicker minDate={locations.value[0].startEvent ? locations.value[0].startEvent : undefined} required style={{width: '100%', height: '56px'}} placeholder='Einddatum evenement' onChange={(e) => {updateLocation(0, e, 'endEvent'); checkOpeningTimes();}} value={locations.value[0].endEvent} format={'DD MMMM'} locale={locale} />
                                            </Box>
                                            <TimePicker required style={{width: '100%', height: '56px'}} minuteStep={5} placeholder='Duur tijdslot' title='Duur tijdslot' onChange={(e) => {updateLocation(0, e, 'timeslotTime')}} value={locations.value[0].timeslotTime} format={'HH:mm'} locale={locale} />
                                            </ConfigProvider>
                                        </Box>
                                    </>
                                }
                                right={
                                    <>
                                    
                                    </>
                                }
                            />
                            <Box sx={{height: '32px'}}></Box>
                            <Split 
                                gap={64}
                                left={
                                    <>
                                        <OpeningTimesOverview locations={locations} index={0} />
                                    </>
                                }
                                right={
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                         <Typography variant='labelLarge' sx={{fontWeight: '700'}}>Aantal uitgiftepunten</Typography>
                                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                    <Slider value={locations.value[0].desks} onChange={(e, val) => {updateLocation(0, val, 'desks')}} valueLabelDisplay='off' max={10} min={1} sx={{mx: '12px'}}/>
                                                    <TextField sx={{width: '60px', pointerEvents: 'none'}} required variant="outlined" value={locations.value[0].desks} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) updateLocation(0, +e.currentTarget.value, 'desks')}}/>
                                                </Box>
                                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>Voertuigen</Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                                {locations.value[0].vehicles.map((v, ii) => 
                                                    <Box key={v.type + ii} sx={{display: 'flex', width: '100%', gap: '8px'}}>
                                                            <Select
                                                                displayEmpty
                                                                required
                                                                onChange={(e) => {updateVehicle(0, ii, e.target.value, 'type')}}
                                                                value={locations.value[0].vehicles[ii].type}
                                                                sx={{width: '100%', my: '16px'}}
                                                            >
                                                                <MenuItem disabled value='' key=''>
                                                                    <Typography variant="labelLarge" sx={{my: 'auto', ml: '8px'}}>
                                                                        Voertuig 
                                                                    </Typography> 
                                                                </MenuItem>
                                                                {
                                                                Object.values(EventVehicles).map((key, index) => 
                                                                    <MenuItem disabled={locations.value[0].vehicles.some(vehicle => vehicle.type === key) ? true : false} value={key} key={key}>
                                                                        <Typography variant="labelLarge">
                                                                            {key}
                                                                        </Typography>
                                                                    </MenuItem>
                                                                    )
                                                                }
                                                            </Select>
                                                            <TextField
                                                            id="outlined-number"
                                                            label="Aantal per tijdslot"
                                                            type="Number"
                                                            value={(locations.value[0].vehicles[ii].amount)}
                                                            onChange={(e) => {if (checkNumeric(e.target.value)) updateVehicle(0, ii, +e.target.value, 'amount')}}
                                                            inputProps={{ min: 1}}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{my: '16px', width: '100%'}}
                                                        /> 
                                                        {locations.value[0].vehicles.length > 1 ? 
                                                            <IconButton onClick={() => {removeVehicle(0, ii)}} sx={{my: 'auto', width: '40px', height: '40px'}}>
                                                                <ClearOutlinedIcon />
                                                            </IconButton> :
                                                            <></>
                                                            }
                                                    </Box>
                                                    
                                                )}
                                                {locations.value[0].vehicles.length < Object.keys(EventVehicles).length ? 
                                                    <IconButton onClick={() => {addVehicle(0)}} sx={{ml: 'auto', width: '40px', height: '40px', mb: '8px', backgroundColor: 'secondary.main', color: 'white'}}>
                                                        <AddOutlinedIcon />
                                                    </IconButton> :
                                                    <></>
                                                }
                                            </Box>
                                    </Box>
                                }
                            />
                        </>
                        : 
                        <>

                        </>
                    }
                />
                <Card title='Artikelen in webshop'
                    children={
                        <>
                            <Typography variant='bodyLarge'>Onderstaand zoek je naar artikelen en varianten die je in de webshop wil tonen. Per artikel en/of variant kan je de prijs naar wens aanpassen. Deze aanpassingen zijn alleen geldig in deze webshop en overschrijven de standaard configuratie. Dit heeft geen invloed op andere webshops.</Typography>
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={perLocation.value} onChange={(e) => {perLocation.value = !perLocation.value}}/>
                                <Typography variant='bodyMedium'>
                                    Artikelaanbod is per locatie afwijkend
                                </Typography>
                            </Box>
                            {
                                perLocation.value ? 
                                <>
                                {
                                    selectedProducts.value.map((p, iii) =>
                                        <Box key={p.length + iii.toString()} sx={{display: 'flex', gap: '8px', alignItems: 'start'}}>
                                        <Accordion sx={{width: '100%', boxShadow: 'none', border: '1px solid #D5D8DC'}} expanded={productsExpanded.value === iii} onChange={() => {productsExpanded.value === iii ? productsExpanded.value = -1 : productsExpanded.value = iii}} disableGutters>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreOutlinedIcon />}
                                                id={iii.toString()}
                                                sx={{display: 'flex', alignItems: 'center', gap: '16px'}}
                                            >
                                                <Typography variant='titleMedium'>{allLocations.value.find(loc => loc.id === locations.value[iii].selectedLocation)?.name}</Typography>
                                            </AccordionSummary>
                                
                                            <AccordionDetails sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                            <Autocomplete
                                                multiple
                                                id="products-autocomplete"
                                                limitTags={2}
                                                options={products.value}
                                                getOptionLabel={(option) => option.naam}
                                                value={selectedProductsNaam.value[iii]}
                                                onChange={(e, val) => {addOrRemoveProduct(iii, val)}}
                                                renderTags={(val, props) => {
                                                    return val.map((option, index) => <Chip {...props({index})} label={option.naam} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>) 
                                                }}
                                                renderOption={(props, option, { selected }) => {
                                                    const { id, ...optionProps } = props;
                                                    return (
                                                    <li key={id} {...optionProps} style={{gap: '8px'}}>
                                                        <img src={option.image_url ? option.image_url : ''} alt="" height='40px' width='40px'/>
                                                        {option.naam}
                                                    </li>
                                                    );
                                                }}
                                                style={{ width: '100%'}}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Zoek in artikelen" placeholder="Zoek op artikelnaam of artikelcode" />
                                                )}
                                            />
                                            <Box sx={{display: 'flex', gap: '8px', pl: '24px', pr: '38px', justifyContent: 'space-between'}}>
                                                <Typography variant='bodyLarge' sx={{color: 'grey'}}>Variant</Typography>
                                                <Typography variant='bodyLarge' sx={{color: 'grey'}}>Prijs (incl. btw)</Typography>
                                            </Box>
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '-1px'}}>
                                            {
                                                selectedProducts.value[iii].map((product, i) => 
                                                    'sales_product_name' in product ?
                                                        <Accordion sx={{width: '100%', boxShadow: 'none', border: '1px solid #D5D8DC'}} key={product.sales_product_name + i.toString()} disableGutters>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreOutlinedIcon />}
                                                                id={i.toString()}
                                                                sx={{display: 'flex', alignItems: 'center', gap: '8px', px: '8px'}}
                                                            >
                                                                <Box sx={{display: 'flex', alignItems: 'center', gap: '8px', px: '8px', width: '100%'}}>
                                                                    <img src={product.sales_product_image ? product.sales_product_image[0] : ''} alt='' width='40px' height='40px'/>
                                                                    <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.sales_product_name}</Typography>
                                                                    <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                                        <EuroSymbolOutlinedIcon />
                                                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.price_incl_vat_rounded.toFixed(2)}</Typography>
                                                                    </Box>
                                                                    <IconButton onClick={() => {deleteProduct(iii, i)}}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Box>
                                                                
                                                            </AccordionSummary>
                                                
                                                            <AccordionDetails sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                                                {
                                                                    product.variants?.map((variant, ii) => 
                                                                        <Box sx={{display: 'flex', gap: '8px', pl: '48px', pr: '16px', width: '100%', alignItems: 'center'}}>
                                                                            <Typography variant='bodyMedium'>{variant.product_options?.map(option => option.product_option_name).join(' / ')}</Typography>
                                                                            <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                                                <EuroSymbolOutlinedIcon />
                                                                                <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{variant.price_incl_vat_rounded ? variant.price_incl_vat_rounded.toFixed(2) : '0,00'}</Typography>
                                                                            </Box>
                                                                            <Switch checked={variant.is_active} onClick={() => {updateVariants(iii, i, ii, 'is_active', !variant.is_active)}} />
                                                                        </Box>
                                                                    )
                                                                }
                                                                
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    :   
                                                    'composed_product_name' in product ?
                                                        <Paper variant='outlined' sx={{display: 'flex', alignItems: 'center', gap: '8px', pl: '8px', pr: '48px', width: '100%', height: '64px', border: '1px solid #bbbec1aa', borderRadius: '1px'}}>
                                                            <img src={product.composed_product_image ? product.composed_product_image[iii] : ''} alt='' width='40px' height='40px'/>
                                                            <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.composed_product_name}</Typography>
                                                            <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                                <EuroSymbolOutlinedIcon />
                                                                <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.price_incl_vat_rounded.toFixed(2)}</Typography>
                                                            </Box>
                                                            <IconButton onClick={() => {deleteProduct(iii, i)}}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Paper>   
                                                    : <></>
                                                )
                                            }
                                            </Box>
                                            </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    )
                                }
                                </>
                                :
                                <>
                                    <Autocomplete
                                        multiple
                                        id="products-autocomplete"
                                        limitTags={2}
                                        options={products.value}
                                        getOptionLabel={(option) => option.naam}
                                        value={selectedProductsNaam.value[0]}
                                        onChange={(e, val) => {addOrRemoveProduct(0, val)}}
                                        renderTags={(val, props) => {
                                            return val.map((option, index) => <Chip {...props({index})} label={option.naam} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>) 
                                        }}
                                        renderOption={(props, option, { selected }) => {
                                            const { id, ...optionProps } = props;
                                            return (
                                            <li key={id} {...optionProps} style={{gap: '8px'}}>
                                                <img src={option.image_url ? option.image_url : ''} alt="" height='40px' width='40px'/>
                                                {option.naam}
                                            </li>
                                            );
                                        }}
                                        style={{ width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Zoek in artikelen" placeholder="Zoek op artikelnaam of artikelcode" />
                                        )}
                                    />
                                    <Box sx={{display: 'flex', gap: '8px', pl: '24px', pr: '38px', justifyContent: 'space-between'}}>
                                        <Typography variant='bodyLarge' sx={{color: 'grey'}}>Variant</Typography>
                                        <Typography variant='bodyLarge' sx={{color: 'grey'}}>Prijs (incl. btw)</Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '-1px'}}>
                                    {
                                        selectedProducts.value[0].map((product, i) => 
                                            'sales_product_name' in product ?
                                                <Accordion sx={{width: '100%', boxShadow: 'none', border: '1px solid #D5D8DC'}} key={product.sales_product_name + i.toString()} disableGutters>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreOutlinedIcon />}
                                                        id={i.toString()}
                                                        sx={{display: 'flex', alignItems: 'center', gap: '8px', px: '8px'}}
                                                    >
                                                        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px', px: '8px', width: '100%'}}>
                                                            <img src={product.sales_product_image ? product.sales_product_image[0] : ''} alt='' width='40px' height='40px'/>
                                                            <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.sales_product_name}</Typography>
                                                            <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                                <EuroSymbolOutlinedIcon />
                                                                <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.price_incl_vat_rounded.toFixed(2)}</Typography>
                                                            </Box>
                                                            <IconButton onClick={() => {deleteProduct(0, i)}}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                        
                                                    </AccordionSummary>
                                        
                                                    <AccordionDetails sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                                        {
                                                            product.variants?.map((variant, ii) => 
                                                                <Box sx={{display: 'flex', gap: '8px', pl: '48px', pr: '16px', width: '100%', alignItems: 'center'}}>
                                                                    <Typography variant='bodyMedium'>{variant.product_options?.map(option => option.product_option_name).join(' / ')}</Typography>
                                                                    <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                                        <EuroSymbolOutlinedIcon />
                                                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{variant.price_incl_vat_rounded ? variant.price_incl_vat_rounded.toFixed(2) : '0,00'}</Typography>
                                                                    </Box>
                                                                    <Switch checked={variant.is_active} onClick={() => {updateVariants(0, i, ii, 'is_active', !variant.is_active)}} />
                                                                </Box>
                                                            )
                                                        }
                                                        
                                                    </AccordionDetails>
                                                </Accordion>
                                            :   
                                            'composed_product_name' in product ?
                                                <Paper variant='outlined' sx={{display: 'flex', alignItems: 'center', gap: '8px', pl: '8px', pr: '48px', width: '100%', height: '64px', border: '1px solid #bbbec1aa', borderRadius: '1px'}}>
                                                    <img src={product.composed_product_image ? product.composed_product_image[0] : ''} alt='' width='40px' height='40px'/>
                                                    <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.composed_product_name}</Typography>
                                                    <Box sx={{display: 'flex', gap: '4px', ml: 'auto', alignItems: 'center'}}>
                                                        <EuroSymbolOutlinedIcon />
                                                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{product.price_incl_vat_rounded.toFixed(2)}</Typography>
                                                    </Box>
                                                    <IconButton onClick={() => {deleteProduct(0, i)}}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Paper>   
                                            : <></>
                                        )
                                    }
                                    </Box>
                                </>
                            }
                        </>
                    }
                />
                </form>
            </Box>
        </ThemeProvider>
    );
}

