/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductOptionTypes {
    OPTIESELECTOR = 'Optieselector',
    VRIJ_TEKSTVELD = 'Vrij tekstveld',
    VRIJ_NUMERIEK_VELD = 'Vrij numeriek veld',
    BESTAND_UPLOAD_BIJV_FOTO_VOOR_TAART_ = 'Bestand upload (bijv. foto voor taart)',
}
