import { batch, Signal } from "@preact/signals-react";
import { useSignal,  useSignals } from "@preact/signals-react/runtime";
import VerticalStepper from "../modals/stepper/VerticalStepper";
import { Autocomplete, Box, Checkbox, Chip, Collapse, FormControlLabel, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import ButtonFull from "../buttons/ButtonFull";
import ButtonOutlined from "../buttons/ButtonOutlined";
import ArticleTypeSelect from "../modals/ArticleTypeSelect";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { apiClient } from "../../functions/Login";
import { AllergenOut, ComposedProductIn, CompositionIn, ProductOptionCategoryNaamOut, ProductOptionCategoryOut, ProductWeekdayIn, SalesProductGroupNaamOut, SalesProductIn, VariantIn, VatOut } from "../../assets";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { ConfigProvider, DatePicker, Upload, UploadFile } from "antd";
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import VariantsToProduct from "./VariantsToProduct";
import { Dayjs } from "dayjs";
import locale from 'antd/es/date-picker/locale/nl_NL';
import VariantsToComposedProduct from "./VariantsToComposedProduct";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

interface ProductAddProps {
    open: Signal<boolean>
}

interface variant {
    img_url: string
    option_names: (string | string[])[]
    option_ids: string[]
    price: number
    active: boolean
  }

interface composition {
    option_names: string[]
    composition_name: string
    variants: string[];
    amount: number
}

const ProductAdd: React.FC<ProductAddProps> = ({open}) => {
    useSignals();
    const selectedProductType: Signal<string> = useSignal('');
    const toStepper: Signal<boolean> = useSignal(false);

    const product_name: Signal<string> = useSignal('');
    const product_legal_name: Signal<string> = useSignal('');
    const product_code: Signal<string> = useSignal('');
    const is_bread: Signal<boolean> = useSignal(false);

    const description: Signal<string> = useSignal('');
    const allergens: Signal<AllergenOut[]> = useSignal([]);
    const selectedAllergens: Signal<string[]> = useSignal([]);
    const img_urls: Signal<string[]> = useSignal([]);

    const groups: Signal<SalesProductGroupNaamOut[]> = useSignal([]);
    const selectedGroups: Signal<string[]> = useSignal([]);

    const price_incl: Signal<string> = useSignal('0,00');
    const price_excl: Signal<string> = useSignal('0,00');
    const cost_price: Signal<string> = useSignal('0,00');
    const vats: Signal<VatOut[]> = useSignal([]);
    const selectedVat: Signal<string> = useSignal('');

    const has_options: Signal<boolean> = useSignal(false);
    const option_groups: Signal<ProductOptionCategoryNaamOut[]> = useSignal([]);
    const selectedOptionGroups: Signal<ProductOptionCategoryOut[]> = useSignal([]);
    const variants: Signal<variant[]> = useSignal([]);
    const compositions: Signal<composition[]> = useSignal([]);

    const availability: Signal<{ [key: string]: boolean }> = useSignal({
        Maandag: false,
        Dinsdag: false,
        Woensdag: false,
        Donderdag: false,
        Vrijdag: false,
        Zaterdag: false,
        Zondag: false
    })
    const activeFrom: Signal<Dayjs | null> = useSignal(null);
    const activeTill: Signal<Dayjs | null> = useSignal(null);

    const fileList: Signal<UploadFile[]> = useSignal([]);
    const uploadLogo = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file} = options;

        apiClient().userManagement.uploadLogoToS3UsersUploadLogoImagePost({file: file as Blob}).then((r) => {
            if (onSuccess) onSuccess('OK');
            img_urls.value = [...img_urls.value, r];
        }).catch((error: UploadRequestError) => {
            if (onError) onError(error, 'Er is iets fout gegaan, probeer het opnieuw');
        })
    }

    const changeOptionGroups = (val: ProductOptionCategoryNaamOut[]) => {
        if (selectedOptionGroups.value.length > val.length) {
            selectedOptionGroups.value = selectedOptionGroups.value.filter(s => val.map(v => v.id).includes(s.id));

            // Rebuild variants
            variants.value = [];
            createVariants();
        } else {
            apiClient().itemManagement.getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(val[val.length -1].id).then((r) => {
                selectedOptionGroups.value = [...selectedOptionGroups.value, {...r, product_options: r.product_options?.map((o) => {return {...o, image_type_docx: true}})}]
                createVariants()
            })
        }
    }

    const createVariants = () => {
        // create new variants with the existing ones and the option configurations in the new group
        if (variants.value.length === 0) {
            selectedOptionGroups.value.forEach((group, i) => {
                if (variants.value.length > 0) {
                    let new_variants: variant[] = []
                    variants.value.forEach((variant, ii) => {
                        group.product_options?.forEach((option, ii) => {           
                            if (option.product_option_name && variants.value.some((v) => v.option_names.includes(option.product_option_name!))) {
                                return;
                            }     
                            if (!option.image_type_docx) {
                                return;
                            }   
                            new_variants = [...new_variants, {
                                img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                                option_names: option.product_option_name ? [...variant.option_names, option.product_option_name] : [],
                                option_ids: [...variant.option_ids, option.id],
                                price: option.price_factor ? variant.price * option.price_factor : variant.price,
                                active: true
                            }];
                        })
                    })
                    variants.value = new_variants;
                } else {
                    group.product_options?.forEach((option, ii) => {    
                        if (!option.image_type_docx) {
                            return;
                        }         
                        variants.value = [...variants.value, {
                            img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                            option_names: option.product_option_name ? [option.product_option_name] : [],
                            option_ids: [option.id],
                            price: option.price_factor ? Number.parseFloat(price_incl.value.replaceAll(',', '.')) * option.price_factor : Number.parseFloat(price_incl.value.replaceAll(',', '.')),
                            active: true
                        }];
                    })
                }
            })
        } else {
            let new_variants: variant[] = []
            variants.value.forEach((variant, i) => {
                selectedOptionGroups.value.forEach((group, i) => {
                    group.product_options?.forEach((option, ii) => {  
                        if (option.product_option_name && variants.value.some((v) => v.option_names.includes(option.product_option_name!))) {
                            return;
                        }        
                        if(!option.image_type_docx) {
                            return;
                        }
                        new_variants = [...new_variants, {
                            img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                            option_names: option.product_option_name ? [...variant.option_names, option.product_option_name] : [],
                            option_ids: [...variant.option_ids, option.id],
                            price: option.price_factor ? variant.price * option.price_factor : variant.price,
                            active: true
                        }];
                    })
                })
            })
            variants.value = new_variants;
        }
    }

    const checkNumeric = (input: string) => {
        const decimalRegex = /^\d+\,?\d{0,2}$/;
        
        if (input.match(decimalRegex) || input === '') {
            return true
        } 
        return false
    }

    const reset = () => {
        batch(() => {
            selectedProductType.value = '';
            toStepper.value = false;
            product_name.value = '';
            product_legal_name.value = '';
            product_code.value = '';
            is_bread.value = false;
        
            description.value = '';
            selectedAllergens.value = [];
            img_urls.value = [];
        
            selectedGroups.value = [];
        
            price_incl.value = '0,00';
            price_excl.value = '0,00';
            cost_price.value = '0,00';
            selectedVat.value = '';
        
            has_options.value = false;
            selectedOptionGroups.value = [];
            variants.value = [];
            compositions.value = [];
        
            availability.value = {
                Maandag: false,
                Dinsdag: false,
                Woensdag: false,
                Donderdag: false,
                Vrijdag: false,
                Zaterdag: false,
                Zondag: false
            }
            activeFrom.value = null;
            activeTill.value = null;
        
            fileList.value = [];
        })
    }

    useEffect(() => {
        apiClient().itemManagement.getAllAllergensItemsAllergensGet().then((r) => {
            allergens.value = r.items;
        })
        apiClient().itemManagement.getAllSalesProductGroupsItemsSalesProductGroupsGet().then((r) => {
            groups.value = r.items;
        })
        apiClient().admin.getAllVatsAdminVatsGet().then((r) => {
            vats.value = r.items;
        })
        apiClient().itemManagement.getAllProductOptionCategoriesItemsProductOptionCategoriesGet().then((r) => {
            option_groups.value = r.items;
        })
    }, [])

    useEffect(() => {
        if (selectedVat.value !== '') {
            let vat = vats.peek().find(v => v.id === selectedVat.value)!.vat_percentage / 100;

            if (price_incl.peek() !== '0,00') {
                price_excl.value = (Number.parseFloat(price_incl.peek().replaceAll(',', '.')) / (1 + vat)).toFixed(2).toString().replaceAll('.', ',');
            } else if (price_excl.peek() !== '0,00') {
                price_incl.value = (Number.parseFloat(price_excl.peek().replaceAll(',', '.')) * (1 + vat)).toFixed(2).toString().replaceAll('.', ',');
            }
        }
    }, [selectedVat.value])
    
    const createSingleProduct = (activeStep: Signal<number>) => {
        const vars: VariantIn[] = Array.from(variants.value, (v) => {
            let newVariant = {
                is_active_in_item: v.active,
                variant_image: [v.img_url],
                product_option_ids: v.option_ids,
            } as VariantIn
            return newVariant
        })

        const weekdays = {
            monday_active: availability.value.Maandag,
            tuesday_active: availability.value.Dinsdag,
            wednesday_active: availability.value.Woensdag,
            thursday_active: availability.value.Donderdag,
            friday_active: availability.value.Vrijdag,
            saturday_active: availability.value.Zaterdag,
            sunday_active: availability.value.Zondag,
        } as ProductWeekdayIn

        const body = {
            sales_product_name: product_name.value,
            sales_product_legal_name: product_legal_name.value,
            sales_product_code: product_code.value,
            sales_product_description: description.value,
            sales_product_image: img_urls.value,
            vat_id: selectedVat.value,
            price_excl_vat: Number.parseFloat(price_excl.value.replaceAll(',', '.')),
            cost_price: Number.parseFloat(cost_price.value.replaceAll(',', '.')),
            active_from_date:  activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            active_to_date:  activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            sales_product_group_ids: selectedGroups.value,
            allergens: selectedAllergens.value,
            variants: vars,
            weekday: weekdays
        } as SalesProductIn
        apiClient().itemManagement.createSalesProductItemsCreateSalesProductPost(body).then((r) => {
            activeStep.value += 1;
        })
    }

    const createComposedProduct = (activeStep: Signal<number>) => {
        const comps: CompositionIn[] = Array.from(compositions.value, (c) => {
            let newComposition = {
               composition_amount: c.amount,
               variant_ids: c.variants,
               name: c.composition_name
            } as CompositionIn
            return newComposition
        })

        const weekdays = {
            monday_active: availability.value.Maandag,
            tuesday_active: availability.value.Dinsdag,
            wednesday_active: availability.value.Woensdag,
            thursday_active: availability.value.Donderdag,
            friday_active: availability.value.Vrijdag,
            saturday_active: availability.value.Zaterdag,
            sunday_active: availability.value.Zondag,
        } as ProductWeekdayIn

        const body = {
            composed_product_name: product_name.value,
            composed_product_legal_name: product_legal_name.value,
            composed_product_code: product_code.value,
            composed_product_description: description.value,
            composed_product_image: img_urls.value,
            vat_id: selectedVat.value,
            price_excl_vat: Number.parseFloat(price_excl.value.replaceAll(',', '.')),
            cost_price: Number.parseFloat(cost_price.value.replaceAll(',', '.')),
            active_from_date:  activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            active_to_date:  activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
            sales_product_group_ids: selectedGroups.value,
            allergens: selectedAllergens.value,
            compositions: comps,
            weekday: weekdays
        } as ComposedProductIn
        apiClient().itemManagement.createComposedProductItemsCreateComposedProductPost(body).then((r) => {
            activeStep.value += 1;
        })
    }

    const finalSingleContent = <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', height: '100%'}}>
        <Typography variant='bodyLarge'>
            Het artikel is toegevoegd.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', mt: 'auto'}}>
            <ButtonFull text='Sluiten' onClick={() => {open.value = false; reset();}} width='100%'/>
            <ButtonOutlined text='Sluiten en nog een artikel toevoegen' onClick={() => {reset();}} width='100%'/>
        </Box>
    </Box>

    const singleSteps = [
        {
            label: 'Artikelnaam en -code',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <TextField required label="Artikelnaam / Fantasienaam" variant="outlined" value={product_name.value} onChange={(e) => {product_name.value = e.currentTarget.value}}/>
                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                    <Checkbox checked={is_bread.value} onChange={(e) => {is_bread.value = !is_bread.value}}/>
                    <Typography variant='bodyMedium'>
                        Dit artikel is een broodsoort.
                    </Typography>
                    <Tooltip title={'Sinds 1 juli 2020 is het Warenwetbesluit Meel en Brood van kracht. Sindsdien moet brood voldoen aan wetgeving die eisen stelt aan de samenstelling en benaming van brood en meelproducten. Het doel is om consumenten te beschermen en ervoor te zorgen dat de aanduidingen op brood en meelproducten correct en duidelijk zijn. Kijk voor de complete regelgeving in het dossier van het NBC: https://www.nbc.nl/warenwetbesluit.'}>
                        <InfoOutlinedIcon />
                    </Tooltip>
                </Box>
                <Collapse in={is_bread.value} sx={{width: '100%'}}>
                    <TextField sx={{width: '100%'}} required={is_bread.value} label="Wettelijke naam" variant="outlined" value={product_legal_name.value} onChange={(e) => {product_legal_name.value = e.currentTarget.value}}/>
                </Collapse>
                <TextField required label="Artikelcode" variant="outlined" value={product_code.value} onChange={(e) => {product_code.value = e.currentTarget.value}}/>
            </Box>
        },
        {
            label: 'Eigenschappen',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
               <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Artikel eigenschappen</Typography>
               <Typography variant='bodyLarge'>Deze eigenschappen komen terug op de artikelpagina van de geselecteerde webshops.</Typography>
               <TextField
                label='Omschrijving'
                value={description.value}
                onChange={(e) => {description.value = e.currentTarget.value}}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
                />
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={selectedAllergens.value}
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return (
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Allergenen   
                                </Typography> 
                            )
                        }
            
                        return selectedAllergens.value.join(', ');
                    }}
                    onChange={(e) => {selectedAllergens.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '150px', width: '100%', maxHeight: '50px', borderRadius: '4px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Allergenen   
                        </Typography> 
                    </MenuItem>
                    {
                        allergens.value.map((allergen) => 
                            <MenuItem value={allergen.allergen_name} key={allergen.allergen_name} >
                                <Checkbox checked={selectedAllergens.value.indexOf(allergen.allergen_name) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {allergen.allergen_name}    
                                </Typography> 
                            </MenuItem>
                        )
                    }
                </Select>
                <Typography variant='bodyLarge'>U bent zelf verantwoordelijk voor de juistheid en volledigheid van de allergenen en dieet informatie bij het product.</Typography>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Media</Typography>
                <Upload
                    customRequest={(e) => {uploadLogo(e)}}
                    listType="picture-card"
                    fileList={fileList.value.length > 0 ? fileList.value : undefined}
                    key={fileList.value.length}
                >
                    <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>
                </Upload>
            </Box>
        },
        {
            label: 'Groepen',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                 <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Artikelgroepen</Typography>
                 <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    required
                    value={selectedGroups.value}
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return (
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Artikelgroep   
                                </Typography> 
                            )
                        }
            
                        return groups.value.filter(group => selected.includes(group.id)).map(g => g.sales_product_group_name).join(', ');
                    }}
                    onChange={(e) => {selectedGroups.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '150px', width: '100%', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Artikelgroep   
                        </Typography> 
                    </MenuItem>
                    {
                        groups.value.map((group) => 
                            <MenuItem value={group.id} key={group.id} >
                                <Checkbox checked={selectedGroups.value.indexOf(group.id) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {group.sales_product_group_name}    
                                </Typography> 
                            </MenuItem>
                        )
                    }
                </Select>
            </Box>
        },
        {
            label: 'Prijs',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Basisprijs</Typography>
                <Typography variant='bodyLarge'>Stel hieronder de basisprijs van het artikel in. Dit is de stuksprijs zonder opties.</Typography>
                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (incl. btw)" variant="outlined" value={price_incl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_incl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_excl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_excl.value = (Number.parseFloat(price_incl.value.replaceAll(',', '.')) / (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_incl.value = e.currentTarget.value; }}/>
                    <Typography variant="labelLarge" sx={{fontWeight: '700'}}>
                        of
                    </Typography>
                    <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (excl. btw)" variant="outlined" value={price_excl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_excl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_incl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_incl.value = (Number.parseFloat(price_excl.value.replaceAll(',', '.')) * (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_excl.value = e.currentTarget.value; }}/>
                </Box>
                <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label="Kostprijs (incl. btw)" variant="outlined" value={cost_price.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); cost_price.value = temp.toFixed(2).toString().replace('.', ',')}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) cost_price.value = e.currentTarget.value;}}/>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>BTW-tarief bij dit artikel</Typography>
                <RadioGroup
                    value={selectedVat.value}
                    name="vat-picker"
                    onChange={(e) => {selectedVat.value = e.currentTarget.value}}
                >
                    {vats.value.map((vat) =>
                            <FormControlLabel value={vat.id} control={<Radio required={selectedVat.value === ''} />} label={vat.vat_category + ' - ' + vat.vat_description} />
                    )}
                </RadioGroup>
            </Box>
        },
        {
            label: 'Opties en varianten',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                 <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                    <Checkbox checked={has_options.value} onChange={(e) => {has_options.value = !has_options.value}}/>
                    <Typography variant='bodyMedium'>
                        Dit artikel heeft opties
                    </Typography>
                </Box>
                <Collapse in={has_options.value} >
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Optiegroepen</Typography>
                        <Typography variant='bodyLarge'>Kies eerst de optiegroepen.</Typography>
                        <Autocomplete
                            multiple
                            id="option-groups-autocomplete"
                            filterSelectedOptions
                            options={option_groups.value.sort((a, b) => a.category_name.localeCompare(b.category_name))}
                            getOptionLabel={(option) => option.category_name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedOptionGroups.value as ProductOptionCategoryNaamOut[]}
                            onChange={(e, val) => {changeOptionGroups(val)}}
                            renderTags={(val, props) => {
                               return val.map((option, index) => <Chip {...props({index})} label={option.category_name} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>) 
                            }}
                            renderOption={(props, option, { selected }) => {
                                const { id, ...optionProps } = props;
                                return (
                                <li key={id} {...optionProps} style={{gap: '8px'}}>
                                    {option.category_name}
                                </li>
                                );
                            }}
                            style={{ width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label="Optiegroepen" placeholder='Zoek op naam' />
                            )}
                        />
                        <Collapse in={selectedOptionGroups.value.length > 0} >
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Opties</Typography>
                                <Typography variant='bodyLarge'>Verwijder eventueel opties die niet van toepassing zijn op dit artikel.</Typography>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                {
                                    selectedOptionGroups.value.map((group, groupIndex) => 
                                        <Paper key={group.id} variant='outlined' sx={{display: 'flex', gap: '8px', alignItems: 'center', p: '16px', borderRadius: '8px', border: '1px solid #D5D8DC', width: '100%'}}>
                                            <Typography variant='bodyLarge' sx={{mr: 'auto'}}>{group.category_name}</Typography>
                                            {
                                                group.product_options && group.product_options.map((option, optionIndex) => 
                                                    option.image_type_docx ?
                                                    <Chip label={option.product_option_name} onDelete={() => {if (group.product_options!.filter((po, i) => i !== optionIndex).every(po => po.image_type_docx === false)) {selectedOptionGroups.value = selectedOptionGroups.value.filter((_, i) => i !== groupIndex )} else  {selectedOptionGroups.value = selectedOptionGroups.value.map((o, i) => i === groupIndex ? {...o, product_options: group.product_options?.map((op, i) => i === optionIndex ? {...op, image_type_docx: false} : op)} : {...o})} variants.value = []; createVariants()}} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>
                                                    :
                                                    <Chip label={option.product_option_name} deleteIcon={<ControlPointOutlinedIcon />} onDelete={() => {selectedOptionGroups.value = selectedOptionGroups.value.map((o, i) => i === groupIndex ? {...o, product_options: group.product_options?.map((op, i) => i === optionIndex ? {...op, image_type_docx: true} : op)} : o); variants.value = []; createVariants()}} sx={{backgroundColor: '#c4c4c415', color: '#c4c4c4', border: 'solid 1px #c4c4c4', '& .MuiChip-deleteIcon': {color: '#c4c4c4', "&:hover": {color: '#c4c4c4BB'}}}}/>
                                                )   
                                            }
                                        </Paper>
                                    )
                                    
                                }
                                </Box>
                                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Varianten</Typography>
                                <Typography variant='bodyLarge'>Deactiveer varianten die niet van toepassing zijn voor dit artikel.</Typography>
                                <VariantsToProduct variants={variants} />
                            </Box>
                        </Collapse>
                    </Box>
                </Collapse>
            </Box>
        },
        {
            label: 'Beschikbaarheid',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Beschikbaarheid levering/afhalen</Typography>
                <Typography variant='bodyLarge'>Op deze dagen is het artikel beschikbaar voor levering/afhalen.</Typography>
                <Box>
                    <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                        <Checkbox checked={Object.values(availability.value).every(Boolean)} onChange={(e, checked) => {if (Object.values(availability.value).every((v) => v === true)) {availability.value = {
                            Maandag: false,
                            Dinsdag: false,
                            Woensdag: false,
                            Donderdag: false,
                            Vrijdag: false,
                            Zaterdag: false,
                            Zondag: false
                        }} else {
                            availability.value = {
                                Maandag: true,
                                Dinsdag: true,
                                Woensdag: true,
                                Donderdag: true,
                                Vrijdag: true,
                                Zaterdag: true,
                                Zondag: true
                            }
                        }}}/>
                        <Typography variant='bodyMedium'>
                            Selecteer alles
                        </Typography>
                    </Box>
                    {
                        Object.keys(availability.value).map((day: string, i) => 
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={availability.value[day]} onChange={(e, checked) => {availability.value = {...availability.value, [day]: !availability.value[day]}}}/>
                                <Typography variant='bodyMedium'>
                                    {day}
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Periode actief</Typography>
                <Typography variant='bodyLarge'>Geef hier aan wanneer een artikel alleen een bepaalde periode van het jaar beschikbaar is.</Typography>
                <Box sx={{display: 'flex', gap: '16px'}}>
                    <ConfigProvider
                        theme={{
                            components: {
                            DatePicker: {
                                activeBorderColor: '#28555e',
                                cellRangeBorderColor: '#28555e',
                                hoverBorderColor: '#28555e',
                                colorPrimary: '#28555e',
                                colorBorder: '#c4c4c4',
                                borderRadius: 4,
                                colorTextPlaceholder: '#000',
                                activeShadow: 'none',
                                presetsWidth: 360,
                                zIndexPopup: 2000,
                                zIndexPopupBase: 2000,
                                zIndexBase: 2000
                            },
                            },
                        }}
                    >
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief van' onChange={(e) => {activeFrom.value = e}} value={activeFrom.value} format={'DD MMMM'} locale={locale} />
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief tot' onChange={(e) => {activeTill.value = e}} value={activeTill.value} format={'DD MMMM'} locale={locale} />
                    </ConfigProvider>
                </Box>
            </Box>
        }
    ]

    const compoundSteps = [
        {
            label: 'Artikelnaam en -code',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <TextField required label="Artikelnaam / Fantasienaam" variant="outlined" value={product_name.value} onChange={(e) => {product_name.value = e.currentTarget.value}}/>
                <TextField required label="Artikelcode" variant="outlined" value={product_code.value} onChange={(e) => {product_code.value = e.currentTarget.value}}/>
            </Box>
        },
        {
            label: 'Eigenschappen',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Artikel eigenschappen</Typography>
               <Typography variant='bodyLarge'>Deze eigenschappen komen terug op de artikelpagina van de geselecteerde webshops.</Typography>
               <TextField
                label='Omschrijving'
                value={description.value}
                onChange={(e) => {description.value = e.currentTarget.value}}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
                />
                <Typography variant='bodyLarge'>Allergenen worden automatisch toegevoegd op basis van artikel samenstelling. </Typography>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Media</Typography>
                <Upload
                    customRequest={(e) => {uploadLogo(e)}}
                    listType="picture-card"
                    fileList={fileList.value.length > 0 ? fileList.value : undefined}
                    key={fileList.value.length}
                >
                    <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>
                </Upload>
            </Box>
        },
        {
            label: 'Groepen',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                 <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Artikelgroepen</Typography>
                 <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    required
                    value={selectedGroups.value}
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return (
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Artikelgroep   
                                </Typography> 
                            )
                        }
            
                        return groups.value.filter(group => selected.includes(group.id)).map(g => g.sales_product_group_name).join(', ');
                    }}
                    onChange={(e) => {selectedGroups.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '150px', width: '100%', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Artikelgroep   
                        </Typography> 
                    </MenuItem>
                    {
                        groups.value.map((group) => 
                            <MenuItem value={group.id} key={group.id} >
                                <Checkbox checked={selectedGroups.value.indexOf(group.id) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {group.sales_product_group_name}    
                                </Typography> 
                            </MenuItem>
                        )
                    }
                </Select>
            </Box>
        },
        {
            label: 'Artikelen in samenstelling',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Artikelen</Typography>
                <Typography variant='bodyLarge'>Welke artikelen wil je toevoegen aan het samengestelde artikel?</Typography>
                <VariantsToComposedProduct compositions={compositions} />
            </Box>
        },
        {
            label: 'Prijs',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge'>Stel hieronder de prijs van het samengestelde artikel in.</Typography>
                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (incl. btw)" variant="outlined" value={price_incl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_incl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_excl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_excl.value = (Number.parseFloat(price_incl.value.replaceAll(',', '.')) / (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_incl.value = e.currentTarget.value; }}/>
                    <Typography variant="labelLarge" sx={{fontWeight: '700'}}>
                        of
                    </Typography>
                    <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (excl. btw)" variant="outlined" value={price_excl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_excl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_incl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_incl.value = (Number.parseFloat(price_excl.value.replaceAll(',', '.')) * (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_excl.value = e.currentTarget.value; }}/>
                </Box>
                <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label="Kostprijs (incl. btw)" variant="outlined" value={cost_price.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); cost_price.value = temp.toFixed(2).toString().replace('.', ',')}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) cost_price.value = e.currentTarget.value;}}/>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>BTW-tarief bij dit artikel</Typography>
                <RadioGroup
                    value={selectedVat.value}
                    name="vat-picker"
                    onChange={(e) => {selectedVat.value = e.currentTarget.value}}
                >
                    {vats.value.map((vat) =>
                            <FormControlLabel value={vat.id} control={<Radio required={selectedVat.value === ''} />} label={vat.vat_category + ' - ' + vat.vat_description} />
                    )}
                </RadioGroup>
            </Box>
        },
        {
            label: 'Beschikbaarheid',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                 <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Beschikbaarheid levering/afhalen</Typography>
                <Typography variant='bodyLarge'>Op deze dagen is het artikel beschikbaar voor levering/afhalen.</Typography>
                <Box>
                    <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                        <Checkbox checked={Object.values(availability.value).every(Boolean)} onChange={(e, checked) => {if (Object.values(availability.value).every((v) => v === true)) {availability.value = {
                            Maandag: false,
                            Dinsdag: false,
                            Woensdag: false,
                            Donderdag: false,
                            Vrijdag: false,
                            Zaterdag: false,
                            Zondag: false
                        }} else {
                            availability.value = {
                                Maandag: true,
                                Dinsdag: true,
                                Woensdag: true,
                                Donderdag: true,
                                Vrijdag: true,
                                Zaterdag: true,
                                Zondag: true
                            }
                        }}}/>
                        <Typography variant='bodyMedium'>
                            Selecteer alles
                        </Typography>
                    </Box>
                    {
                        Object.keys(availability.value).map((day: string, i) => 
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={availability.value[day]} onChange={(e, checked) => {availability.value = {...availability.value, [day]: !availability.value[day]}}}/>
                                <Typography variant='bodyMedium'>
                                    {day}
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Periode actief</Typography>
                <Typography variant='bodyLarge'>Geef hier aan wanneer een artikel alleen een bepaalde periode van het jaar beschikbaar is.</Typography>
                <Box sx={{display: 'flex', gap: '16px'}}>
                    <ConfigProvider
                        theme={{
                            components: {
                            DatePicker: {
                                activeBorderColor: '#28555e',
                                cellRangeBorderColor: '#28555e',
                                hoverBorderColor: '#28555e',
                                colorPrimary: '#28555e',
                                colorBorder: '#c4c4c4',
                                borderRadius: 4,
                                colorTextPlaceholder: '#000',
                                activeShadow: 'none',
                                presetsWidth: 360,
                                zIndexPopup: 2000,
                                zIndexPopupBase: 2000,
                                zIndexBase: 2000
                            },
                            },
                        }}
                    >
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief van' onChange={(e) => {activeFrom.value = e}} value={activeFrom.value} format={'DD MMMM'} locale={locale} />
                        <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief tot' onChange={(e) => {activeTill.value = e}} value={activeTill.value} format={'DD MMMM'} locale={locale} />
                    </ConfigProvider>
                </Box>
            </Box>
        }
    ]

    return (
        toStepper.value ? 
            selectedProductType.value === 'single' ? 
                <VerticalStepper open={open} title={'Artikel toevoegen (los artikel)'} steps={singleSteps} finalContent={finalSingleContent} func={createSingleProduct} resetFunc={() => {reset()}}/>
            :
                <VerticalStepper open={open} title={'Artikel toevoegen (samengesteld artikel)'} steps={compoundSteps} finalContent={finalSingleContent} func={createComposedProduct} resetFunc={() => {reset()}}/>
        :
            <ArticleTypeSelect openStepper={toStepper} open={open} selectedProductType={selectedProductType}/>
    );
  };

export default ProductAdd;