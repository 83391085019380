import { Box, Checkbox, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import Split from "./layout/Split";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import ButtonOutlined from "./buttons/ButtonOutlined";
import DeviantTimeAdd from "./modals/DeviantTimeAdd";

interface openingTime {
    id: string
    name: string
    date: Date
    openFrom: Dayjs
    openTill: Dayjs
    active: boolean
}

interface OpeningTimesProps {
    normal: Signal<openingTime[]>
    deviant: Signal<openingTime[]>
}

const OpeningTimes: React.FC<OpeningTimesProps> = ({normal, deviant}) => {
    useSignals();
    const open: Signal<boolean> = useSignal(false);

    const setTime = (id: string, fromTime: boolean, time: Dayjs | null, isNormal: boolean) => {
        // default empty values to zero time 
        if (!time) {
            time = dayjs().set('hour', 0).set('minute', 0)
        }

        if (isNormal) {
            normal.value = normal.value.map(n => {
                if (n.id === id) {
                    if (fromTime) {
                        return {...n, openFrom: time as Dayjs }
                    } else {
                        return {...n, openTill: time as Dayjs }
                    }
                }
                return n
            })
        } else {
            deviant.value = deviant.value.map(n => {
                if (n.id === id) {
                    if (fromTime) {
                        return {...n, openFrom: time as Dayjs }
                    } else {
                        return {...n, openTill: time as Dayjs }
                    }
                }
                return n
            })
        }
    }

    const activateTime = (id: string, active: boolean, isNormal: boolean) => {
        if (isNormal) {
            normal.value = normal.value.map(n => {
                if (n.id === id) {
                   return {...n, active: active}
                }
                return n
            })
        } else {
            deviant.value = deviant.value.map(n => {
                if (n.id === id) {
                   return {...n, active: active}
                }
                return n
            })
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <DeviantTimeAdd open={open} deviantTimes={deviant}/>
            <Typography variant='titleMedium' sx={{fontWeight: '700', mt: '8px'}}>
                Normale openingstijden
            </Typography>
            {
                normal.value.map(time => 
                    <Split 
                        key={time.id}
                        left={
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={time.active} onChange={(e) => {activateTime(time.id, e.currentTarget.checked, true)}}/>
                                <Typography variant='bodyMedium'>
                                    {time.name}
                                </Typography>
                            </Box>
                        }
                        right={
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <LocalizationProvider key={time.id} dateAdapter={AdapterDayjs}>
                                    <TimePicker disabled={!time.active} onChange={(t: Dayjs | null) => {setTime(time.id, true, t, true)}} value={time.openFrom} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open van" />
                                    <TimePicker disabled={!time.active} onChange={(t: Dayjs | null) => {setTime(time.id, false, t, true)}} value={time.openTill} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open tot" />
                                </LocalizationProvider>
                            </Box>
                        }
                    />
                )
            }
            <Typography variant='titleMedium' sx={{fontWeight: '700', mt: '8px'}}>
                Afwijkende openingstijden
            </Typography>
            {
                deviant.value.map(time => 
                    <Split 
                        key={time.id}
                        left={
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={time.active} onChange={(e) => {activateTime(time.id, e.currentTarget.checked, false)}}/>
                                <Typography variant='bodyMedium'>
                                    {time.name}
                                </Typography>
                            </Box>
                        }
                        right={
                            <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <LocalizationProvider key={time.id} dateAdapter={AdapterDayjs}>
                                    <TimePicker disabled={!time.active} onChange={(t: Dayjs | null) => {setTime(time.id, true, t, false)}} value={time.openFrom} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open van" />
                                    <TimePicker disabled={!time.active} onChange={(t: Dayjs | null) => {setTime(time.id, false, t, false)}} value={time.openTill} ampm={false} views={['hours', 'minutes']} format="HH:mm" label="Open tot" />
                                </LocalizationProvider>
                            </Box>
                        }
                    />
                )
            }
            <ButtonOutlined text='Afwijkende openingstijden instellen' onClick={() => {open.value = true}} width='100%'/>
        </Box>
    );
  };

export default OpeningTimes;