/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post } from '../models/Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post';
import type { Body_drive_thru_payment_webhook_status_update_v1_drive_thru_payment_webhook_post } from '../models/Body_drive_thru_payment_webhook_status_update_v1_drive_thru_payment_webhook_post';
import type { Body_get_shop_info_by_webshop_url_drive_thru_shop_info__location_id__post } from '../models/Body_get_shop_info_by_webshop_url_drive_thru_shop_info__location_id__post';
import type { Body_get_shop_info_by_webshop_url_v1_drive_thru_shop_info__location_id__post } from '../models/Body_get_shop_info_by_webshop_url_v1_drive_thru_shop_info__location_id__post';
import type { Body_get_shop_locations_by_webshop_url_drive_thru_shop_locations_post } from '../models/Body_get_shop_locations_by_webshop_url_drive_thru_shop_locations_post';
import type { Body_get_shop_locations_by_webshop_url_v1_drive_thru_shop_locations_post } from '../models/Body_get_shop_locations_by_webshop_url_v1_drive_thru_shop_locations_post';
import type { Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview__location_id__post } from '../models/Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview__location_id__post';
import type { Body_get_shop_preview_by_webshop_url_v1_drive_thru_shop_preview__location_id__post } from '../models/Body_get_shop_preview_by_webshop_url_v1_drive_thru_shop_preview__location_id__post';
import type { DriveThruEventDayOutList } from '../models/DriveThruEventDayOutList';
import type { DriveThruEventLocationVehicleOutList } from '../models/DriveThruEventLocationVehicleOutList';
import type { DriveThruLocalOrderIn } from '../models/DriveThruLocalOrderIn';
import type { DriveThruOrderComposedProductOutList } from '../models/DriveThruOrderComposedProductOutList';
import type { DriveThruOrderEdit } from '../models/DriveThruOrderEdit';
import type { DriveThruOrderFactIn } from '../models/DriveThruOrderFactIn';
import type { DriveThruOrderFactOut } from '../models/DriveThruOrderFactOut';
import type { DriveThruOrderFactOutList } from '../models/DriveThruOrderFactOutList';
import type { DriveThruOrderIn } from '../models/DriveThruOrderIn';
import type { DriveThruOrderOut } from '../models/DriveThruOrderOut';
import type { DriveThruOrderOutList } from '../models/DriveThruOrderOutList';
import type { DriveThruOrderProductIn } from '../models/DriveThruOrderProductIn';
import type { DriveThruOrderProductOut } from '../models/DriveThruOrderProductOut';
import type { DriveThruOrderProductOutList } from '../models/DriveThruOrderProductOutList';
import type { DriveThruPublicOrderComposedProductIn } from '../models/DriveThruPublicOrderComposedProductIn';
import type { DriveThruPublicOrderProductIn } from '../models/DriveThruPublicOrderProductIn';
import type { DriveThruTimeslotOutList } from '../models/DriveThruTimeslotOutList';
import type { PaymentOut } from '../models/PaymentOut';
import type { RevenueOverviewOut } from '../models/RevenueOverviewOut';
import type { ShopLocationNameOutList } from '../models/ShopLocationNameOutList';
import type { ShopOut } from '../models/ShopOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DriveThruService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Event Days By Event Location Id
     * Get all drive thru event days by event location id
     * @param eventLocationId
     * @returns DriveThruEventDayOutList Successful Response
     * @throws ApiError
     */
    public getAllEventDaysByEventLocationIdDriveThruEventLocationsEventLocationIdEventDaysGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventDayOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}/event_days',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Timeslots
     * Get all drive thru timeslots.
     * @returns DriveThruTimeslotOutList Successful Response
     * @throws ApiError
     */
    public getAllTimeslotsDriveThruTimeslotsGet(): CancelablePromise<DriveThruTimeslotOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/timeslots',
        });
    }

    /**
     * Get All Event Location Vehicles By Event Location Id
     * Get all drive thru event location vehicles for event location.
     * @param eventLocationId
     * @returns DriveThruEventLocationVehicleOutList Successful Response
     * @throws ApiError
     */
    public getAllEventLocationVehiclesByEventLocationIdDriveThruEventLocationsEventLocationIdEventLocationVehiclesGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventLocationVehicleOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/event_locations/{event_location_id}/event_location_vehicles',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Orders
     * Get all drive thru orders.
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getAllOrdersDriveThruOrdersGet(): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders',
        });
    }

    /**
     * Create Order
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderDriveThruOrdersPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order By Id
     * Get drive thru order by id.
     * @param orderId
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public getOrderByIdDriveThruOrdersOrderIdGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Order
     * Edit drive thru order on specific fields.
     * @param orderNumber
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public editOrderDriveThruOrdersOrderNumberPatch(
        orderNumber: string,
        requestBody: DriveThruOrderEdit,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/drive_thru/orders/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Public
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderPublicDriveThruCreateOrderPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/create_order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Local
     * Create new drive thru order in the scanapp.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderLocalDriveThruCreateOrderLocalPost(
        requestBody: DriveThruLocalOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/create_order_local',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Products By Order Id
     * Get drive thru order products by order id.
     * @param orderId
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public getOrderProductsByOrderIdDriveThruOrdersOrderIdOrderProductsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}/order_products',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Facts By Order Id
     * Get drive thru order facts by order id.
     * @param orderId
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getOrderFactsByOrderIdDriveThruOrdersOrderIdOrderfactsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/orders/{order_id}/orderfacts',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Order Facts
     * Get all drive thru order facts.
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getAllOrderFactsDriveThruOrderFactsGet(): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_facts',
        });
    }

    /**
     * Create Order Fact
     * Create new drive thru order fact.
     * @param requestBody
     * @param dryRun
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public createOrderFactDriveThruOrderFactsPost(
        requestBody: DriveThruOrderFactIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/order_facts',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Fact By Id
     * Get drive thru order fact by id.
     * @param orderFactId
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public getOrderFactByIdDriveThruOrderFactsOrderFactIdGet(
        orderFactId: string,
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_facts/{order_fact_id}',
            path: {
                'order_fact_id': orderFactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Distribution Info By Id
     * Get distribution information of drive thru orders by distribution point
     * @param eventLocationId
     * @param distributionPoint
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getDistributionInfoByIdDriveThruEventLocationIdDistributionDistributionPointGet(
        eventLocationId: string,
        distributionPoint: number,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/{event_location_id}/distribution/{distribution_point}',
            path: {
                'event_location_id': eventLocationId,
                'distribution_point': distributionPoint,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Locations By Webshop Url
     * Get all locations.
     * @param formData
     * @returns ShopLocationNameOutList Successful Response
     * @throws ApiError
     */
    public getShopLocationsByWebshopUrlDriveThruShopLocationsPost(
        formData: Body_get_shop_locations_by_webshop_url_drive_thru_shop_locations_post,
    ): CancelablePromise<ShopLocationNameOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/shop_locations',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Info By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param locationId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopInfoByWebshopUrlDriveThruShopInfoLocationIdPost(
        locationId: string,
        formData: Body_get_shop_info_by_webshop_url_drive_thru_shop_info__location_id__post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/shop_info/{location_id}',
            path: {
                'location_id': locationId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Preview By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param locationId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopPreviewByWebshopUrlDriveThruShopPreviewLocationIdPost(
        locationId: string,
        formData: Body_get_shop_preview_by_webshop_url_drive_thru_shop_preview__location_id__post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/shop_preview/{location_id}',
            path: {
                'location_id': locationId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Product To Order
     * Add product to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public addProductToOrderDriveThruAddProductToOrderOrderIdPost(
        orderId: string,
        requestBody: DriveThruOrderProductIn,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/add_product_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Products To Order
     * Add products to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public addProductsToOrderDriveThruAddProductsToOrderOrderIdPost(
        orderId: string,
        requestBody: Array<DriveThruPublicOrderProductIn>,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/add_products_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Composed Products To Order
     * Add composed products to order. Consolidation happens on pre existing variant compositions
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderComposedProductOutList Successful Response
     * @throws ApiError
     */
    public addComposedProductsToOrderDriveThruAddComposedProductsToOrderOrderIdPost(
        orderId: string,
        requestBody: Array<DriveThruPublicOrderComposedProductIn>,
    ): CancelablePromise<DriveThruOrderComposedProductOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/add_composed_products_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payment By Order Id
     * Create payment by order id
     * @param orderId
     * @param dryRun
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public createPaymentByOrderIdDriveThruPaymentsPublicOrderIdGet(
        orderId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/payments_public/{order_id}',
            path: {
                'order_id': orderId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Order By Order Id
     * Refund order by order id
     * @param orderId
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public refundOrderByOrderIdDriveThruRefundOrderOrderIdPost(
        orderId: string,
        dryRun: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/refund_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Drive Thru Payment Webhook Status Update
     * Endpoint specifically for passing the payment provider a
     * webhook url for them to call on status changes.
     * This triggers the status retrieval process in our backend.
     * This endpoint also includes follow up logic for this specific module.
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public driveThruPaymentWebhookStatusUpdateDriveThruPaymentWebhookPost(
        formData: Body_drive_thru_payment_webhook_status_update_drive_thru_payment_webhook_post,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/drive_thru/payment_webhook',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Orders
     * Get count of paid orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountOrdersDriveThruCountPaidOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/count_paid_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Completed Orders
     * Get count of completed orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountCompletedOrdersDriveThruCountCompletedOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/count_completed_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Status
     * Get order status by order id
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOrderStatusDriveThruOrderStatusOrderIdGet(
        orderId: string,
    ): CancelablePromise<(DriveThruOrderFactOut | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/order_status/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Orders
     * Search uncompleted orders by input
     * @param searchInput
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public searchOrdersDriveThruSearchOrdersSearchInputGet(
        searchInput: string,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/search_orders/{search_input}',
            path: {
                'search_input': searchInput,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Revenue Overview
     * Get revenue overview by event location
     * @param eventLocationId
     * @returns RevenueOverviewOut Successful Response
     * @throws ApiError
     */
    public getRevenueOverviewDriveThruRevenueOverviewEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<RevenueOverviewOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/drive_thru/revenue_overview/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Event Days By Event Location Id
     * Get all drive thru event days by event location id
     * @param eventLocationId
     * @returns DriveThruEventDayOutList Successful Response
     * @throws ApiError
     */
    public getAllEventDaysByEventLocationIdV1DriveThruEventLocationsEventLocationIdEventDaysGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventDayOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/event_locations/{event_location_id}/event_days',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Timeslots
     * Get all drive thru timeslots.
     * @returns DriveThruTimeslotOutList Successful Response
     * @throws ApiError
     */
    public getAllTimeslotsV1DriveThruTimeslotsGet(): CancelablePromise<DriveThruTimeslotOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/timeslots',
        });
    }

    /**
     * Get All Event Location Vehicles By Event Location Id
     * Get all drive thru event location vehicles for event location.
     * @param eventLocationId
     * @returns DriveThruEventLocationVehicleOutList Successful Response
     * @throws ApiError
     */
    public getAllEventLocationVehiclesByEventLocationIdV1DriveThruEventLocationsEventLocationIdEventLocationVehiclesGet(
        eventLocationId: string,
    ): CancelablePromise<DriveThruEventLocationVehicleOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/event_locations/{event_location_id}/event_location_vehicles',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Orders
     * Get all drive thru orders.
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getAllOrdersV1DriveThruOrdersGet(): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/orders',
        });
    }

    /**
     * Create Order
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderV1DriveThruOrdersPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/orders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order By Id
     * Get drive thru order by id.
     * @param orderId
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public getOrderByIdV1DriveThruOrdersOrderIdGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/orders/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Order
     * Edit drive thru order on specific fields.
     * @param orderNumber
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public editOrderV1DriveThruOrdersOrderNumberPatch(
        orderNumber: string,
        requestBody: DriveThruOrderEdit,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/drive_thru/orders/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Public
     * Create new drive thru order.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderPublicV1DriveThruCreateOrderPost(
        requestBody: DriveThruOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/create_order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Order Local
     * Create new drive thru order in the scanapp.
     * @param requestBody
     * @returns DriveThruOrderOut Successful Response
     * @throws ApiError
     */
    public createOrderLocalV1DriveThruCreateOrderLocalPost(
        requestBody: DriveThruLocalOrderIn,
    ): CancelablePromise<DriveThruOrderOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/create_order_local',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Products By Order Id
     * Get drive thru order products by order id.
     * @param orderId
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public getOrderProductsByOrderIdV1DriveThruOrdersOrderIdOrderProductsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/orders/{order_id}/order_products',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Facts By Order Id
     * Get drive thru order facts by order id.
     * @param orderId
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getOrderFactsByOrderIdV1DriveThruOrdersOrderIdOrderfactsGet(
        orderId: string,
    ): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/orders/{order_id}/orderfacts',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Order Facts
     * Get all drive thru order facts.
     * @returns DriveThruOrderFactOutList Successful Response
     * @throws ApiError
     */
    public getAllOrderFactsV1DriveThruOrderFactsGet(): CancelablePromise<DriveThruOrderFactOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/order_facts',
        });
    }

    /**
     * Create Order Fact
     * Create new drive thru order fact.
     * @param requestBody
     * @param dryRun
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public createOrderFactV1DriveThruOrderFactsPost(
        requestBody: DriveThruOrderFactIn,
        dryRun?: (boolean | null),
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/order_facts',
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Fact By Id
     * Get drive thru order fact by id.
     * @param orderFactId
     * @returns DriveThruOrderFactOut Successful Response
     * @throws ApiError
     */
    public getOrderFactByIdV1DriveThruOrderFactsOrderFactIdGet(
        orderFactId: string,
    ): CancelablePromise<DriveThruOrderFactOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/order_facts/{order_fact_id}',
            path: {
                'order_fact_id': orderFactId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Distribution Info By Id
     * Get distribution information of drive thru orders by distribution point
     * @param eventLocationId
     * @param distributionPoint
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public getDistributionInfoByIdV1DriveThruEventLocationIdDistributionDistributionPointGet(
        eventLocationId: string,
        distributionPoint: number,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/{event_location_id}/distribution/{distribution_point}',
            path: {
                'event_location_id': eventLocationId,
                'distribution_point': distributionPoint,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Locations By Webshop Url
     * Get all locations.
     * @param formData
     * @returns ShopLocationNameOutList Successful Response
     * @throws ApiError
     */
    public getShopLocationsByWebshopUrlV1DriveThruShopLocationsPost(
        formData: Body_get_shop_locations_by_webshop_url_v1_drive_thru_shop_locations_post,
    ): CancelablePromise<ShopLocationNameOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/shop_locations',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Info By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param locationId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopInfoByWebshopUrlV1DriveThruShopInfoLocationIdPost(
        locationId: string,
        formData: Body_get_shop_info_by_webshop_url_v1_drive_thru_shop_info__location_id__post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/shop_info/{location_id}',
            path: {
                'location_id': locationId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Preview By Webshop Url
     * Get shop info, incl location, timeslot and vehicle slot info
     * @param locationId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public getShopPreviewByWebshopUrlV1DriveThruShopPreviewLocationIdPost(
        locationId: string,
        formData: Body_get_shop_preview_by_webshop_url_v1_drive_thru_shop_preview__location_id__post,
    ): CancelablePromise<(ShopOut | null)> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/shop_preview/{location_id}',
            path: {
                'location_id': locationId,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Product To Order
     * Add product to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOut Successful Response
     * @throws ApiError
     */
    public addProductToOrderV1DriveThruAddProductToOrderOrderIdPost(
        orderId: string,
        requestBody: DriveThruOrderProductIn,
    ): CancelablePromise<DriveThruOrderProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/add_product_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Products To Order
     * Add products to order. Consolidation happens on pre existing variants
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderProductOutList Successful Response
     * @throws ApiError
     */
    public addProductsToOrderV1DriveThruAddProductsToOrderOrderIdPost(
        orderId: string,
        requestBody: Array<DriveThruPublicOrderProductIn>,
    ): CancelablePromise<DriveThruOrderProductOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/add_products_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Composed Products To Order
     * Add composed products to order. Consolidation happens on pre existing variant compositions
     * @param orderId
     * @param requestBody
     * @returns DriveThruOrderComposedProductOutList Successful Response
     * @throws ApiError
     */
    public addComposedProductsToOrderV1DriveThruAddComposedProductsToOrderOrderIdPost(
        orderId: string,
        requestBody: Array<DriveThruPublicOrderComposedProductIn>,
    ): CancelablePromise<DriveThruOrderComposedProductOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/add_composed_products_to_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Payment By Order Id
     * Create payment by order id
     * @param orderId
     * @param dryRun
     * @returns PaymentOut Successful Response
     * @throws ApiError
     */
    public createPaymentByOrderIdV1DriveThruPaymentsPublicOrderIdGet(
        orderId: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<PaymentOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/payments_public/{order_id}',
            path: {
                'order_id': orderId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Order By Order Id
     * Refund order by order id
     * @param orderId
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public refundOrderByOrderIdV1DriveThruRefundOrderOrderIdPost(
        orderId: string,
        dryRun: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/refund_order/{order_id}',
            path: {
                'order_id': orderId,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Drive Thru Payment Webhook Status Update
     * Endpoint specifically for passing the payment provider a
     * webhook url for them to call on status changes.
     * This triggers the status retrieval process in our backend.
     * This endpoint also includes follow up logic for this specific module.
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public driveThruPaymentWebhookStatusUpdateV1DriveThruPaymentWebhookPost(
        formData: Body_drive_thru_payment_webhook_status_update_v1_drive_thru_payment_webhook_post,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/drive_thru/payment_webhook',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Orders
     * Get count of paid orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountOrdersV1DriveThruCountPaidOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/count_paid_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Count Completed Orders
     * Get count of completed orders for event location
     * @param eventLocationId
     * @returns number Successful Response
     * @throws ApiError
     */
    public getCountCompletedOrdersV1DriveThruCountCompletedOrdersEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/count_completed_orders/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Status
     * Get order status by order id
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getOrderStatusV1DriveThruOrderStatusOrderIdGet(
        orderId: string,
    ): CancelablePromise<(DriveThruOrderFactOut | null)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/order_status/{order_id}',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Orders
     * Search uncompleted orders by input
     * @param searchInput
     * @returns DriveThruOrderOutList Successful Response
     * @throws ApiError
     */
    public searchOrdersV1DriveThruSearchOrdersSearchInputGet(
        searchInput: string,
    ): CancelablePromise<DriveThruOrderOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/search_orders/{search_input}',
            path: {
                'search_input': searchInput,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Revenue Overview
     * Get revenue overview by event location
     * @param eventLocationId
     * @returns RevenueOverviewOut Successful Response
     * @throws ApiError
     */
    public getRevenueOverviewV1DriveThruRevenueOverviewEventLocationIdGet(
        eventLocationId: string,
    ): CancelablePromise<RevenueOverviewOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/drive_thru/revenue_overview/{event_location_id}',
            path: {
                'event_location_id': eventLocationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
