import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useState } from "react";

type props = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    severity: AlertColor,
    text: string,
    length?: number
}
export default function Alerter({open, setOpen, severity, text, length = 3000} : props) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={length} onClose={handleClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
               {text}
            </Alert>
        </Snackbar>
    )
}