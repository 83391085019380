import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../Theme';
// Import mui material icons
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import ModeOfTravelOutlinedIcon from '@mui/icons-material/ModeOfTravelOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Avatar, Collapse, GlobalStyles, Menu, MenuItem } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAuthenticator } from '@aws-amplify/ui-react';


const OnlineOptions = [
  {
    "text": "Orders",
    "icon": <ReceiptOutlinedIcon />,
    "path": "/orders"
  },
  {
    "text": "Webshops",
  "icon": <DevicesOutlinedIcon />,
  "path": "/webshops/Drive-thru"
  }
]

const OfficeOptions = [
  {
    "text": "Artikelen",
    "icon": <BreakfastDiningOutlinedIcon />,
    "path": "/artikelen/overzicht"
  },
  // {
  //   "text": "Promoties",
  // "icon": <CelebrationOutlinedIcon />,
  // "path": "/promoties"
  // },
  // {
  //   "text": "Klanten",
  //   "icon": <PeopleAltOutlinedIcon />,
  //   "path": "/klanten"
  // },
  // {
  //   "text": "Financieel",
  //   "icon": <EuroOutlinedIcon />,
  //   "path": "/financieel"
  // },
  {
    "text": "Personeel",
    "icon": <BadgeOutlinedIcon />,
    "path": "/personeel/medewerkers"
  },
  {
    "text": "Bedrijfsgegevens",
    "icon": <DesktopWindowsOutlinedIcon />,
    "path": "/bedrijf/gegevens"
  },
  // {
  //   "text": "Wetgeving & Accreditatie",
  //   "icon": <GavelOutlinedIcon />,
  //   "path": "/wetgeving-accreditatie"
  // },
  // {
  //   "text": "Externe koppelingen",
  //   "icon": <CloudSyncOutlinedIcon />,
  //   "path": "/koppelingen"
  // }
]

const ProductionOptions = [
  {
    "text": "Voorraad",
    "icon": <InventoryOutlinedIcon />,
    "path": "/voorraad"
  },
  {
    "text": "Recepten",
  "icon": <MenuBookOutlinedIcon />,
  "path": "/recepten"
  },
  {
    "text": "Productie",
  "icon": <PrecisionManufacturingOutlinedIcon />,
  "path": "/productie"
  },
  {
    "text": "Distributie",
  "icon": <SchemaOutlinedIcon />,
  "path": "/distributie"
  },
  {
    "text": "Logistiek",
  "icon": <ModeOfTravelOutlinedIcon />,
  "path": "/logistiek"
  }
]

const StoreOptions = [
  {
    "text": "Filialen",
    "icon": <StorefrontOutlinedIcon />,
    "path": "/filialen"
  },
  {
    "text": "Kassa's",
  "icon": <PointOfSaleOutlinedIcon />,
  "path": "/kassa"
  },
  {
    "text": "Prijskaarten",
  "icon": <SellOutlinedIcon />,
  "path": "/prijskaarten"
  }
]

const drawerWidth = 360;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface Props {
  children?: React.ReactNode
}

export default function NavigationDrawer({children, ...props} : Props) {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  
  const [open, setOpen] = React.useState(false);
  const [onlineOpen, setOnlineOpen] = React.useState(true);
  const [kantoorOpen, setKantoorOpen] = React.useState(true);
  const [bakkerijOpen, setBakkerijOpen] = React.useState(true);
  const [winkelOpen, setWinkelOpen] = React.useState(true);
  const navigate = useNavigate();
  
  const { pathname } = useLocation();
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOnline = () => {
    setOnlineOpen(!onlineOpen);
  }
  const handleKantoor = () => {
    setKantoorOpen(!kantoorOpen);
  }
  const handleBakkerij = () => {
    setBakkerijOpen(!bakkerijOpen);
  }
  const handleWinkel = () => {
    setWinkelOpen(!winkelOpen);
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const menuOpen = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex'}}>
      <GlobalStyles styles={{ html: { backgroundColor: '#f2f4f4' } }} />
      <AppBar position="fixed" open={open} sx={{backgroundColor: '#fff', boxShadow: 'none', display: 'flex', justifyContent: 'start', ml: open ? '360px' : '65px', width: open ? 'calc(100% - 360px)' : 'calc(100% - 65px)'}}>
        <Toolbar>
          
          <Typography onClick={() => {navigate('/')}} component="h1" variant="headlineLarge" sx={{ display: open ? 'none' : 'inline', color: '#000', mr: '32px'}}>
            Loaf IT
          </Typography>
          {/* <TextField
          InputProps={{
            startAdornment: (
              <IconButton color='inherit' sx={{ mr: 1 }}>
                <SearchOutlinedIcon />
              </IconButton>
            ),
            endAdornment: (
              <Box component="div" sx={{ display: 'flex' }}>
                <IconButton color='inherit'>
                  <TuneOutlinedIcon />
                </IconButton>
              </Box>

            ),
          }}
          id="search-bar"
          onInput={(e) => {
            console.log("Zoek de zeep");
          } }
          variant="outlined"
          placeholder="Zoek..."
          size="small"
          sx={{
            color: '#E1E3E3',
            "& .MuiOutlinedInput-root": {
              borderRadius: "50px",
              borderWidth: '0px',
              backgroundColor: 'E1E3E3'
            },
            width:'521px'
          }} /> */}
          <IconButton sx={{color: '#000', ml: 'auto'}}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
          <IconButton  sx={{color: '#000', mx: '16px'}}>
            <SettingsOutlinedIcon/>
          </IconButton>
          <IconButton 
            sx={{width: '40px', height: '40px'}}
            onClick={openMenu}
            aria-controls={menuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
          >
            <Avatar sx={{ bgcolor: 'primary.main', width: '100%', height: '100%'}}>{user.signInDetails?.loginId![0].toUpperCase()}</Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={closeMenu}
            onClick={closeMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={closeMenu}>
              <Avatar /> Mijn account
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                signOut()
              }}>
              <ListItemIcon>
                <LogoutOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Log uit
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer onMouseEnter={() => {setOpen(true)}} onMouseLeave={() => setOpen(false)} variant="permanent" open={open} sx={{"& .MuiDrawer-paper": { borderWidth: 0, backgroundColor: 'secondary.main' }}}>
        <DrawerHeader sx={{display: open ? 'flex' : 'none' , justifyContent: 'space-between', position: 'sticky'}}>
          <Typography onClick={() => {navigate('/')}} component="h1" variant="headlineLarge" sx={{ visibility: open ? 'visible' : 'hidden', color: '#fff'}}>
            Loaf IT
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon sx={{ color: '#fff'}}/>
          </IconButton>
        </DrawerHeader>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mx: 'auto',
              mt: '12px',
              ...(open && { display: 'none' }),
              color: '#fff',
            }}
          >
            <MenuIcon />
          </IconButton>
        <Box>
          <ListItemButton 
            onClick={handleOnline} 
            sx={{
              maxHeight: '56px',
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
              <ListItemText primary='Online' sx={{ opacity: open ? 1 : 0, color: '#fff'}}/>
              {onlineOpen ? <ExpandLess  sx={{ color: '#fff'}}/> : <ExpandMore sx={{ color: '#fff'}}/>}
          </ListItemButton>
          <Collapse in={onlineOpen} timeout='auto' unmountOnExit >
            <List>
              {OnlineOptions.map(({text, icon, path}, index) => (
                <ListItem selected={path === pathname} key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => navigate(typeof path !== 'undefined' ? path : "/home")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#fff'
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, color: '#fff' }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton 
            onClick={handleKantoor} 
            sx={{
              maxHeight: '56px',
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
              <ListItemText primary='Kantoor' sx={{ opacity: open ? 1 : 0, color: '#fff' }}/>
              {kantoorOpen ? <ExpandLess sx={{ color: '#fff'}}/> : <ExpandMore sx={{ color: '#fff'}}/>}
          </ListItemButton>
          <Collapse in={kantoorOpen} timeout='auto' unmountOnExit>
            <List>
              {OfficeOptions.map(({text, icon, path}, index) => (
                <ListItem selected={path === pathname} key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => navigate(typeof path !== 'undefined' ? path : "/home")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#fff'
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0, color: '#fff' }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
          {/* <Divider />
          <ListItemButton 
            onClick={handleBakkerij} 
            sx={{
              maxHeight: '56px',
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
              <ListItemText primary='Bakkerij' sx={{ opacity: open ? 1 : 0 }}/>
              {bakkerijOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={bakkerijOpen} timeout='auto' unmountOnExit>
            <List >
              {ProductionOptions.map(({text, icon, path}, index) => (
                <ListItem selected={path === pathname} key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => navigate(typeof path !== 'undefined' ? path : "/home")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
          <Divider />
          <ListItemButton 
            onClick={handleWinkel} 
            sx={{
              maxHeight: '56px',
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
              <ListItemText primary='Winkel' sx={{ opacity: open ? 1 : 0 }}/>
              {winkelOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={winkelOpen} timeout='auto' unmountOnExit >
            <List>
              {StoreOptions.map(({text, icon, path}, index) => (
                <ListItem selected={path === pathname} key={text} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => navigate(typeof path !== 'undefined' ? path : "/home")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse> */}
        </Box>
      </Drawer>
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, backgroundColor: '#f2f4f4', borderRadius: '12px', mt: '80px', height: '90vh'}}>
        {children}
      </Box>
    </Box> 
    </ThemeProvider>
  )}