/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RolesEnum } from './RolesEnum';

export type Body_get_groups_v1_policies_groups_post = {
    status?: Body_get_groups_v1_policies_groups_post.status;
    functies?: (Array<string> | null);
    toegangsrechten?: (Array<RolesEnum> | null);
};

export namespace Body_get_groups_v1_policies_groups_post {

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}

