import { Autocomplete, Backdrop, Box, Chip, Collapse, Fade, FormControlLabel, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { batch, Signal } from "@preact/signals-react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import ButtonFull from "../buttons/ButtonFull";
import ButtonText from "../buttons/ButtonText";
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import { VariantNaamOut } from "../../assets";

interface composition {
    option_names: string[]
    variants: string[];
    composition_name: string
    amount: number
}

interface CompositionAddProps {
    open: Signal<boolean>
    compositions: Signal<composition[]>
}


const CompositionAdd: React.FC<CompositionAddProps> = ({open, compositions}) => {
    useSignals();

    const selectedType: Signal<string> = useSignal('');
    const composition_name: Signal<string> = useSignal('');
    const amount: Signal<string> = useSignal('1');
    const variants: Signal<VariantNaamOut[]> = useSignal([]);
    const selectedVariants: Signal<VariantNaamOut[]> = useSignal([]);

    const reset = () => {
        batch(() => {
            selectedType.value = '';
            composition_name.value = '';
            amount.value = '1';
            selectedVariants.value = [];
        })    
    }

    const addComposition = () => {
        if (selectedType.value === 'single') {
            compositions.value = [...compositions.value, {
                    option_names: selectedVariants.value[0].product_options ? selectedVariants.value[0].product_options.map(p => p.product_option_name) : '',
                    composition_name: selectedVariants.value[0].variant_name,
                    variants: [selectedVariants.value[0].id],
                    amount: +amount.value
                } as composition
            ]
        } else {
            compositions.value = [...compositions.value, {
                    option_names: selectedVariants.value.map(s => s.product_options ? s.variant_name + ' - ' + s.product_options.map(o => o.product_option_name).join(' | ') : s.variant_name),
                    composition_name: composition_name.value,
                    variants: selectedVariants.value.map(s => s.id),
                    amount: +amount.value
                } as composition
            ]
        }
        open.value = false;
        reset();
    }

    useEffect(() => {
        apiClient().itemManagement.getAllVariantsItemsAllVariantsGet().then((r) => {
            variants.value = r.items;
        })
    }, [])
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto',
        alignItems: 'center'
      };


    return (
        <Modal
            aria-labelledby="article-composition-modal"
            aria-describedby="Modal to add article to composition"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false; reset()}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineMedium' sx={{mx: 'auto'}}>
                            Artikel toevoegen
                        </Typography>
                        <form id='composition' onSubmit={(e) => {e.preventDefault(); e.stopPropagation(); addComposition()}} style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        <RadioGroup
                            value={selectedType.value}
                            name="variant-type-picker"
                            onChange={(e) => {selectedType.value = e.currentTarget.value}}
                        >
                            <FormControlLabel value={'single'} control={<Radio required={selectedType.value === ''} />} label={'Klant ontvangt een specifiek artikel'} />
                            <FormControlLabel value={'multiple'} control={<Radio required={selectedType.value === ''} />} label={'Klant mag kiezen tussen 2 of meer artikelen'} />
                        </RadioGroup>
                        <Collapse in={selectedType.value !== ''}>
                            
                            {
                                selectedType.value === 'single' ?
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                    <Typography variant='bodyLarge'>Welk artikel ontvangt de klant in de samenstelling?</Typography>
                                    <Autocomplete
                                        disablePortal
                                        options={variants.value}
                                        value={selectedVariants.value[0] ? selectedVariants.value[0] : null}
                                        onChange={(e, val) => {val ? selectedVariants.value = [val] : selectedVariants.value = []}}
                                        getOptionLabel={(option) => option.variant_name + ' - ' + option.product_options?.map(o => o.product_option_name).join(' | ')}
                                        renderInput={(params) => <TextField {...params} label="Artikel" />}
                                    />
                                    <Typography variant='bodyLarge'>Hoeveel van deze artikelen ontvangt de klant?</Typography>
                                    <TextField required label="Aantal" variant="outlined" value={amount.value} onChange={(e) => {if (e.currentTarget.value.match(/^\d*$/)) amount.value = e.currentTarget.value}}/>
                                </Box>
                                :
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                    <Typography variant='bodyLarge'>Uit welke artikelen mag de klant kiezen?</Typography>
                                    <Autocomplete
                                        multiple
                                        id="option-groups-autocomplete"
                                        filterSelectedOptions
                                        options={variants.value}
                                        getOptionLabel={(option) => option.variant_name + ' - ' + option.product_options?.map(o => o.product_option_name).join(' | ')}
                                        value={selectedVariants.value}
                                        onChange={(e, val) => {selectedVariants.value = val}}
                                        renderTags={(val, props) => {
                                        return val.map((option, index) => <Chip {...props({index})} label={option.variant_name + ' - ' + option.product_options?.map(o => o.product_option_name).join(' | ')} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>) 
                                        }}
                                        renderOption={(props, option, { selected }) => {
                                            const { id, ...optionProps } = props;
                                            return (
                                            <li key={id} {...optionProps} style={{gap: '8px'}}>
                                                {option.variant_name + ' - ' + option.product_options?.map(o => o.product_option_name).join(' | ')}
                                            </li>
                                            );
                                        }}
                                        style={{ width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Artikelen" />
                                        )}
                                    />
                                    <Typography variant='bodyLarge'>Hoeveel artikelen mag de klant kiezen uit deze lijst met artikelen?</Typography>
                                    <TextField required label="Aantal" variant="outlined" value={amount.value} onChange={(e) => {if (e.currentTarget.value.match(/^\d*$/)) amount.value = e.currentTarget.value}}/>
                                    <Typography variant='bodyLarge'>Geef de keuzecategorie een naam</Typography>
                                    <TextField required label="Naam van de keuze categorie" variant="outlined" value={composition_name.value} onChange={(e) => {composition_name.value = e.currentTarget.value}}/>
                                </Box>
                            }
                        </Collapse>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center'}}>
                            <ButtonFull form='composition' type='submit' text='Toevoegen aan samenstelling' onClick={() => {}}/>
                            <ButtonText text='Annuleren' onClick={() => {open.value = false; reset()}} margin='0'/>
                        </Box>
                        </form>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default CompositionAdd;