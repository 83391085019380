import { Box, Paper, Switch, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import ButtonOutlined from "../buttons/ButtonOutlined";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import CompositionAdd from "./CompositionAdd";

interface VariantsToComposedProductProps {
  compositions: Signal<composition[]>
}

interface composition {
  option_names: string[]
  composition_name: string
  variants: string[];
  amount: number
}

const VariantsToComposedProduct: React.FC<VariantsToComposedProductProps> = ({compositions}) => {
    useSignals();
    const open: Signal<boolean> = useSignal(false);
  
    useEffect(() => {

    }, [])

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <CompositionAdd open={open} compositions={compositions}/>
          <Box sx={{display: 'flex', px: '16px', gap: '36px'}}>
            <Typography variant='bodyMedium' sx={{color: 'grey'}}>Variant</Typography>
            <Typography variant='bodyMedium' sx={{color: 'grey', ml: 'auto'}}>Aantal</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
            {
              compositions.value.map((composition, i) => 
                <Paper key={i} variant='outlined' sx={{display: 'flex', gap: '16px', p: '16px', alignItems: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>{composition.composition_name}</Typography>
                    <Typography noWrap sx={{maxWidth: '300px'}} variant='labelMedium'>{composition.option_names.join(', ')}</Typography>
                  </Box>
                  <TextField sx={{minWidth: '70px', width: '70px', ml: 'auto'}} required label="Aantal" variant="outlined" value={composition.amount} onChange={(e) => {if (e.currentTarget.value.match(/^\d*$/)) compositions.value = compositions.value.map((c, ii) => i === ii ? {...c, amount: +e.currentTarget.value} : {...c})}}/>
                </Paper>
              )
            }
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', ml: 'auto', gap: '16px'}}>
            <ButtonOutlined Icon={AddOutlinedIcon} text="Artikel toevoegen aan samenstelling"  onClick={() => {open.value = true;}}/>
          </Box>
        </Box>
    );
  };

export default VariantsToComposedProduct; 