/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllergenOut } from '../models/AllergenOut';
import type { AllergenOutList } from '../models/AllergenOutList';
import type { ArtikelGroupDetailOut } from '../models/ArtikelGroupDetailOut';
import type { ArtikelNaamOutList } from '../models/ArtikelNaamOutList';
import type { Body_get_product_option_categories_filtered_items_product_option_categories_post } from '../models/Body_get_product_option_categories_filtered_items_product_option_categories_post';
import type { Body_get_product_option_categories_filtered_v1_items_product_option_categories_post } from '../models/Body_get_product_option_categories_filtered_v1_items_product_option_categories_post';
import type { Body_get_products_filtered_items_products_post } from '../models/Body_get_products_filtered_items_products_post';
import type { Body_get_products_filtered_v1_items_products_post } from '../models/Body_get_products_filtered_v1_items_products_post';
import type { Body_get_sales_product_groups_filtered_items_sales_product_groups_post } from '../models/Body_get_sales_product_groups_filtered_items_sales_product_groups_post';
import type { Body_get_sales_product_groups_filtered_v1_items_sales_product_groups_post } from '../models/Body_get_sales_product_groups_filtered_v1_items_sales_product_groups_post';
import type { Body_upload_file_to_s3_items_upload_file_post } from '../models/Body_upload_file_to_s3_items_upload_file_post';
import type { Body_upload_file_to_s3_v1_items_upload_file_post } from '../models/Body_upload_file_to_s3_v1_items_upload_file_post';
import type { ComposedProductEdit } from '../models/ComposedProductEdit';
import type { ComposedProductIn } from '../models/ComposedProductIn';
import type { ComposedProductOut } from '../models/ComposedProductOut';
import type { DietaryFeatureOut } from '../models/DietaryFeatureOut';
import type { DietaryFeatureOutList } from '../models/DietaryFeatureOutList';
import type { Page_ArtikelGroupOverviewOut_ } from '../models/Page_ArtikelGroupOverviewOut_';
import type { Page_ArtikelOverviewOut_ } from '../models/Page_ArtikelOverviewOut_';
import type { Page_ProductOptionCategoryOverviewOut_ } from '../models/Page_ProductOptionCategoryOverviewOut_';
import type { ProductOptionCategoryEdit } from '../models/ProductOptionCategoryEdit';
import type { ProductOptionCategoryIn } from '../models/ProductOptionCategoryIn';
import type { ProductOptionCategoryNaamOutList } from '../models/ProductOptionCategoryNaamOutList';
import type { ProductOptionCategoryOut } from '../models/ProductOptionCategoryOut';
import type { ProductOptionCategoryReorder } from '../models/ProductOptionCategoryReorder';
import type { ProductOptionNaamOutList } from '../models/ProductOptionNaamOutList';
import type { SalesProductEdit } from '../models/SalesProductEdit';
import type { SalesProductGroupEdit } from '../models/SalesProductGroupEdit';
import type { SalesProductGroupIn } from '../models/SalesProductGroupIn';
import type { SalesProductGroupNaamOutList } from '../models/SalesProductGroupNaamOutList';
import type { SalesProductGroupOut } from '../models/SalesProductGroupOut';
import type { SalesProductIn } from '../models/SalesProductIn';
import type { SalesProductOut } from '../models/SalesProductOut';
import type { VariantActiveOut } from '../models/VariantActiveOut';
import type { VariantNaamOutList } from '../models/VariantNaamOutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ItemManagementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Unique Group Code
     * Check if group code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueGroupCodeItemsUniqueGroupCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_group_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Group Name
     * Check if group name is unique
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueGroupNameItemsUniqueGroupNameNameGet(
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_group_name/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Code
     * Check if product code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductCodeItemsUniqueProductCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_product_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Option Category Name
     * Check if product name is unique
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductOptionCategoryNameItemsUniqueProductOptionCategoryNameNameGet(
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_product_option_category_name/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Option Subcode
     * Check if product subcode is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductOptionSubcodeItemsUniqueProductOptionSubcodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/unique_product_option_subcode/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products
     * Get all Salesproducts and Composed products
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsItemsProductsGet(): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/products',
        });
    }

    /**
     * Get Products Filtered
     * Get all Salesproducts and Composed products filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductsFilteredItemsProductsPost(
        search?: (string | null),
        sortingField?: 'Artikelnaam' | 'Variant' | 'Prijs' | 'Artikelcode' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_products_filtered_items_products_post,
    ): CancelablePromise<Page_ArtikelOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/products',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product By Id
     * Get sales product by id.
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public getSalesProductByIdItemsSalesProductsSalesProductIdGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product
     * Update a sales product by id.
     * @param salesProductId
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public editSalesProductItemsSalesProductsSalesProductIdPatch(
        salesProductId: string,
        requestBody: SalesProductEdit,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product
     * Delete a sales product by id.
     * @param salesProductId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductItemsSalesProductsSalesProductIdDelete(
        salesProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product
     * Create new sales product.
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public createSalesProductItemsCreateSalesProductPost(
        requestBody: SalesProductIn,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_sales_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product
     * Restore a sales product by id that was deleted before
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductItemsSalesProductsSalesProductIdRestoreGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_products/{sales_product_id}/restore',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Composed Product
     * Create new composed product.
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public createComposedProductItemsCreateComposedProductPost(
        requestBody: ComposedProductIn,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_composed_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Composed Product By Id
     * Get composed product by id.
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public getComposedProductByIdItemsComposedProductsComposedProductIdGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Composed Product
     * Update a composed product by id.
     * @param composedProductId
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public editComposedProductItemsComposedProductsComposedProductIdPatch(
        composedProductId: string,
        requestBody: ComposedProductEdit,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Composed Product
     * Delete a composed product by id.
     * @param composedProductId
     * @returns void
     * @throws ApiError
     */
    public deleteComposedProductItemsComposedProductsComposedProductIdDelete(
        composedProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Composed Product
     * Restore a composed product by id that was deleted before
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public restoreComposedProductItemsComposedProductsComposedProductIdRestoreGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/composed_products/{composed_product_id}/restore',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Sales Product Groups
     * Get all sales product groups.
     * @returns SalesProductGroupNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllSalesProductGroupsItemsSalesProductGroupsGet(): CancelablePromise<SalesProductGroupNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups',
        });
    }

    /**
     * Get Sales Product Groups Filtered
     * Get sales product groups.
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelGroupOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupsFilteredItemsSalesProductGroupsPost(
        search?: (string | null),
        sortingField?: 'Groepnaam' | 'Groepcode' | 'Periode_actief' | 'Subgroep_van' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_sales_product_groups_filtered_items_sales_product_groups_post,
    ): CancelablePromise<Page_ArtikelGroupOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/sales_product_groups',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product Group By Id
     * Get sales product group by id.
     * @param salesProductGroupId
     * @returns ArtikelGroupDetailOut Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupByIdItemsSalesProductGroupsSalesProductGroupIdGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelGroupDetailOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product Group
     * Update a sales product group by id.
     * @param salesProductGroupId
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public editSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdPatch(
        salesProductGroupId: string,
        requestBody: SalesProductGroupEdit,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product Group
     * Delete a sales product group by id.
     * @param salesProductGroupId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdDelete(
        salesProductGroupId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products By Sales Product Group Id
     * Get all sales products in a specific group.
     * @param salesProductGroupId
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsBySalesProductGroupIdItemsSalesProductGroupsSalesProductGroupIdAllProductsGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}/all_products',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product Group
     * Create new sales product group.
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public createSalesProductGroupItemsCreateSalesProductGroupPost(
        requestBody: SalesProductGroupIn,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_sales_product_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product Group
     * Restore a sales product group by id that was deleted before
     * @param salesProductGroupId
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdRestoreGet(
        salesProductGroupId: string,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/sales_product_groups/{sales_product_group_id}/restore',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Product Options
     * Get all product options.
     * @returns ProductOptionNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionsItemsProductOptionsGet(): CancelablePromise<ProductOptionNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_options',
        });
    }

    /**
     * Get All Product Option Categories
     * Get all product option categories.
     * @returns ProductOptionCategoryNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionCategoriesItemsProductOptionCategoriesGet(): CancelablePromise<ProductOptionCategoryNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories',
        });
    }

    /**
     * Get Product Option Categories Filtered
     * Get product option categories filtered.
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoriesFilteredItemsProductOptionCategoriesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_product_option_categories_filtered_items_product_option_categories_post,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/product_option_categories',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reorder Product Option Categories
     * Reorder product option categories and return full list.
     * @param requestBody
     * @param page Page number
     * @param size Page size
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public reorderProductOptionCategoriesItemsReorderProductOptionCategoriesPost(
        requestBody: Array<ProductOptionCategoryReorder>,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/reorder_product_option_categories',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Product Option Category By Id
     * Get product option category by id.
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Product Option Category
     * Update a product option category by id.
     * @param productOptionCategoryId
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public editProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdPatch(
        productOptionCategoryId: string,
        requestBody: ProductOptionCategoryEdit,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Product Option Category
     * Delete a product option category by id.
     * @param productOptionCategoryId
     * @returns void
     * @throws ApiError
     */
    public deleteProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdDelete(
        productOptionCategoryId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product Option Category
     * Create new product option category.
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public createProductOptionCategoryItemsCreateProductOptionCategoryPost(
        requestBody: ProductOptionCategoryIn,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/create_product_option_category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Product Option Category
     * Restore a product option category by id that was deleted before
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public restoreProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdRestoreGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/product_option_categories/{product_option_category_id}/restore',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Variants
     * Get all variant names with product option names
     * @returns VariantNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllVariantsItemsAllVariantsGet(): CancelablePromise<VariantNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/all_variants',
        });
    }

    /**
     * Refresh Variant Active Price
     * Refresh default prices for a variant active
     * @param variantActiveId
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public refreshVariantActivePriceItemsVariantActivesVariantActiveIdRefreshDefaultPricesGet(
        variantActiveId: string,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/variant_actives/{variant_active_id}/refresh_default_prices',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload File To S3
     * Upload a file to a (private or public) S3 bucket
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadFileToS3ItemsUploadFilePost(
        formData: Body_upload_file_to_s3_items_upload_file_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/items/upload_file',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Allergens
     * Get all allergens.
     * @returns AllergenOutList Successful Response
     * @throws ApiError
     */
    public getAllAllergensItemsAllergensGet(): CancelablePromise<AllergenOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/allergens',
        });
    }

    /**
     * Get Allergen By Id
     * Get allergen by id.
     * @param allergenId
     * @returns AllergenOut Successful Response
     * @throws ApiError
     */
    public getAllergenByIdItemsAllergensAllergenIdGet(
        allergenId: string,
    ): CancelablePromise<AllergenOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/allergens/{allergen_id}',
            path: {
                'allergen_id': allergenId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Dietaryfeatures
     * Get all dietaryfeatures.
     * @returns DietaryFeatureOutList Successful Response
     * @throws ApiError
     */
    public getAllDietaryfeaturesItemsDietaryfeaturesGet(): CancelablePromise<DietaryFeatureOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/dietaryfeatures',
        });
    }

    /**
     * Get Dietaryfeature By Id
     * Get dietaryfeature by id.
     * @param dietId
     * @returns DietaryFeatureOut Successful Response
     * @throws ApiError
     */
    public getDietaryfeatureByIdItemsDietaryfeaturesDietIdGet(
        dietId: string,
    ): CancelablePromise<DietaryFeatureOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/dietaryfeatures/{diet_id}',
            path: {
                'diet_id': dietId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Group Code
     * Check if group code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueGroupCodeV1ItemsUniqueGroupCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/unique_group_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Group Name
     * Check if group name is unique
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueGroupNameV1ItemsUniqueGroupNameNameGet(
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/unique_group_name/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Code
     * Check if product code is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductCodeV1ItemsUniqueProductCodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/unique_product_code/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Option Category Name
     * Check if product name is unique
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductOptionCategoryNameV1ItemsUniqueProductOptionCategoryNameNameGet(
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/unique_product_option_category_name/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unique Product Option Subcode
     * Check if product subcode is unique
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public uniqueProductOptionSubcodeV1ItemsUniqueProductOptionSubcodeCodeGet(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/unique_product_option_subcode/{code}',
            path: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products
     * Get all Salesproducts and Composed products
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsV1ItemsProductsGet(): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/products',
        });
    }

    /**
     * Get Products Filtered
     * Get all Salesproducts and Composed products filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductsFilteredV1ItemsProductsPost(
        search?: (string | null),
        sortingField?: 'Artikelnaam' | 'Variant' | 'Prijs' | 'Artikelcode' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_products_filtered_v1_items_products_post,
    ): CancelablePromise<Page_ArtikelOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/products',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product By Id
     * Get sales product by id.
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public getSalesProductByIdV1ItemsSalesProductsSalesProductIdGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product
     * Update a sales product by id.
     * @param salesProductId
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public editSalesProductV1ItemsSalesProductsSalesProductIdPatch(
        salesProductId: string,
        requestBody: SalesProductEdit,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product
     * Delete a sales product by id.
     * @param salesProductId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductV1ItemsSalesProductsSalesProductIdDelete(
        salesProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/items/sales_products/{sales_product_id}',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product
     * Create new sales product.
     * @param requestBody
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public createSalesProductV1ItemsCreateSalesProductPost(
        requestBody: SalesProductIn,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/create_sales_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product
     * Restore a sales product by id that was deleted before
     * @param salesProductId
     * @returns SalesProductOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductV1ItemsSalesProductsSalesProductIdRestoreGet(
        salesProductId: string,
    ): CancelablePromise<SalesProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_products/{sales_product_id}/restore',
            path: {
                'sales_product_id': salesProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Composed Product
     * Create new composed product.
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public createComposedProductV1ItemsCreateComposedProductPost(
        requestBody: ComposedProductIn,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/create_composed_product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Composed Product By Id
     * Get composed product by id.
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public getComposedProductByIdV1ItemsComposedProductsComposedProductIdGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Composed Product
     * Update a composed product by id.
     * @param composedProductId
     * @param requestBody
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public editComposedProductV1ItemsComposedProductsComposedProductIdPatch(
        composedProductId: string,
        requestBody: ComposedProductEdit,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Composed Product
     * Delete a composed product by id.
     * @param composedProductId
     * @returns void
     * @throws ApiError
     */
    public deleteComposedProductV1ItemsComposedProductsComposedProductIdDelete(
        composedProductId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/items/composed_products/{composed_product_id}',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Composed Product
     * Restore a composed product by id that was deleted before
     * @param composedProductId
     * @returns ComposedProductOut Successful Response
     * @throws ApiError
     */
    public restoreComposedProductV1ItemsComposedProductsComposedProductIdRestoreGet(
        composedProductId: string,
    ): CancelablePromise<ComposedProductOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/composed_products/{composed_product_id}/restore',
            path: {
                'composed_product_id': composedProductId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Sales Product Groups
     * Get all sales product groups.
     * @returns SalesProductGroupNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllSalesProductGroupsV1ItemsSalesProductGroupsGet(): CancelablePromise<SalesProductGroupNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_product_groups',
        });
    }

    /**
     * Get Sales Product Groups Filtered
     * Get sales product groups.
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ArtikelGroupOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupsFilteredV1ItemsSalesProductGroupsPost(
        search?: (string | null),
        sortingField?: 'Groepnaam' | 'Groepcode' | 'Periode_actief' | 'Subgroep_van' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_sales_product_groups_filtered_v1_items_sales_product_groups_post,
    ): CancelablePromise<Page_ArtikelGroupOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/sales_product_groups',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sales Product Group By Id
     * Get sales product group by id.
     * @param salesProductGroupId
     * @returns ArtikelGroupDetailOut Successful Response
     * @throws ApiError
     */
    public getSalesProductGroupByIdV1ItemsSalesProductGroupsSalesProductGroupIdGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelGroupDetailOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Sales Product Group
     * Update a sales product group by id.
     * @param salesProductGroupId
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public editSalesProductGroupV1ItemsSalesProductGroupsSalesProductGroupIdPatch(
        salesProductGroupId: string,
        requestBody: SalesProductGroupEdit,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sales Product Group
     * Delete a sales product group by id.
     * @param salesProductGroupId
     * @returns void
     * @throws ApiError
     */
    public deleteSalesProductGroupV1ItemsSalesProductGroupsSalesProductGroupIdDelete(
        salesProductGroupId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/items/sales_product_groups/{sales_product_group_id}',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Products By Sales Product Group Id
     * Get all sales products in a specific group.
     * @param salesProductGroupId
     * @returns ArtikelNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductsBySalesProductGroupIdV1ItemsSalesProductGroupsSalesProductGroupIdAllProductsGet(
        salesProductGroupId: string,
    ): CancelablePromise<ArtikelNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_product_groups/{sales_product_group_id}/all_products',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Sales Product Group
     * Create new sales product group.
     * @param requestBody
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public createSalesProductGroupV1ItemsCreateSalesProductGroupPost(
        requestBody: SalesProductGroupIn,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/create_sales_product_group',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Sales Product Group
     * Restore a sales product group by id that was deleted before
     * @param salesProductGroupId
     * @returns SalesProductGroupOut Successful Response
     * @throws ApiError
     */
    public restoreSalesProductGroupV1ItemsSalesProductGroupsSalesProductGroupIdRestoreGet(
        salesProductGroupId: string,
    ): CancelablePromise<SalesProductGroupOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/sales_product_groups/{sales_product_group_id}/restore',
            path: {
                'sales_product_group_id': salesProductGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Product Options
     * Get all product options.
     * @returns ProductOptionNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionsV1ItemsProductOptionsGet(): CancelablePromise<ProductOptionNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/product_options',
        });
    }

    /**
     * Get All Product Option Categories
     * Get all product option categories.
     * @returns ProductOptionCategoryNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllProductOptionCategoriesV1ItemsProductOptionCategoriesGet(): CancelablePromise<ProductOptionCategoryNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/product_option_categories',
        });
    }

    /**
     * Get Product Option Categories Filtered
     * Get product option categories filtered.
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoriesFilteredV1ItemsProductOptionCategoriesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_product_option_categories_filtered_v1_items_product_option_categories_post,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/product_option_categories',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reorder Product Option Categories
     * Reorder product option categories and return full list.
     * @param requestBody
     * @param page Page number
     * @param size Page size
     * @returns Page_ProductOptionCategoryOverviewOut_ Successful Response
     * @throws ApiError
     */
    public reorderProductOptionCategoriesV1ItemsReorderProductOptionCategoriesPost(
        requestBody: Array<ProductOptionCategoryReorder>,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_ProductOptionCategoryOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/reorder_product_option_categories',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Product Option Category By Id
     * Get product option category by id.
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public getProductOptionCategoryByIdV1ItemsProductOptionCategoriesProductOptionCategoryIdGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Product Option Category
     * Update a product option category by id.
     * @param productOptionCategoryId
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public editProductOptionCategoryV1ItemsProductOptionCategoriesProductOptionCategoryIdPatch(
        productOptionCategoryId: string,
        requestBody: ProductOptionCategoryEdit,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Product Option Category
     * Delete a product option category by id.
     * @param productOptionCategoryId
     * @returns void
     * @throws ApiError
     */
    public deleteProductOptionCategoryV1ItemsProductOptionCategoriesProductOptionCategoryIdDelete(
        productOptionCategoryId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/items/product_option_categories/{product_option_category_id}',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product Option Category
     * Create new product option category.
     * @param requestBody
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public createProductOptionCategoryV1ItemsCreateProductOptionCategoryPost(
        requestBody: ProductOptionCategoryIn,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/create_product_option_category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Product Option Category
     * Restore a product option category by id that was deleted before
     * @param productOptionCategoryId
     * @returns ProductOptionCategoryOut Successful Response
     * @throws ApiError
     */
    public restoreProductOptionCategoryV1ItemsProductOptionCategoriesProductOptionCategoryIdRestoreGet(
        productOptionCategoryId: string,
    ): CancelablePromise<ProductOptionCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/product_option_categories/{product_option_category_id}/restore',
            path: {
                'product_option_category_id': productOptionCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Variants
     * Get all variant names with product option names
     * @returns VariantNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllVariantsV1ItemsAllVariantsGet(): CancelablePromise<VariantNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/all_variants',
        });
    }

    /**
     * Refresh Variant Active Price
     * Refresh default prices for a variant active
     * @param variantActiveId
     * @returns VariantActiveOut Successful Response
     * @throws ApiError
     */
    public refreshVariantActivePriceV1ItemsVariantActivesVariantActiveIdRefreshDefaultPricesGet(
        variantActiveId: string,
    ): CancelablePromise<VariantActiveOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/variant_actives/{variant_active_id}/refresh_default_prices',
            path: {
                'variant_active_id': variantActiveId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload File To S3
     * Upload a file to a (private or public) S3 bucket
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadFileToS3V1ItemsUploadFilePost(
        formData: Body_upload_file_to_s3_v1_items_upload_file_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/items/upload_file',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Allergens
     * Get all allergens.
     * @returns AllergenOutList Successful Response
     * @throws ApiError
     */
    public getAllAllergensV1ItemsAllergensGet(): CancelablePromise<AllergenOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/allergens',
        });
    }

    /**
     * Get Allergen By Id
     * Get allergen by id.
     * @param allergenId
     * @returns AllergenOut Successful Response
     * @throws ApiError
     */
    public getAllergenByIdV1ItemsAllergensAllergenIdGet(
        allergenId: string,
    ): CancelablePromise<AllergenOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/allergens/{allergen_id}',
            path: {
                'allergen_id': allergenId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Dietaryfeatures
     * Get all dietaryfeatures.
     * @returns DietaryFeatureOutList Successful Response
     * @throws ApiError
     */
    public getAllDietaryfeaturesV1ItemsDietaryfeaturesGet(): CancelablePromise<DietaryFeatureOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/dietaryfeatures',
        });
    }

    /**
     * Get Dietaryfeature By Id
     * Get dietaryfeature by id.
     * @param dietId
     * @returns DietaryFeatureOut Successful Response
     * @throws ApiError
     */
    public getDietaryfeatureByIdV1ItemsDietaryfeaturesDietIdGet(
        dietId: string,
    ): CancelablePromise<DietaryFeatureOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/items/dietaryfeatures/{diet_id}',
            path: {
                'diet_id': dietId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
