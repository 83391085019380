import { Autocomplete, Box, Checkbox, createFilterOptions, Divider, IconButton, Paper, TextField, Typography } from "@mui/material";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import DeleteIcon from '@mui/icons-material/Delete';
import { ArtikelNaamOut } from "../../assets";

interface ProductsToGroupProps {
  selectedProducts: Signal<ArtikelNaamOut[]>
}

const ProductsToGroup: React.FC<ProductsToGroupProps> = ({selectedProducts}) => {
  useSignals();
  const selected: Signal<string[]> = useSignal([]);
  const products: Signal<ArtikelNaamOut[]> = useSignal([]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selected.value = selectedProducts.value.map(p => p.id);
      } else {
        selected.value = [];
      }
      
    }

    useEffect(() => {
      apiClient().itemManagement.getAllProductsItemsProductsGet().then((r) => {
        products.value = r.items
      })
    }, [])

    const filterOptions = createFilterOptions({
      stringify: (option: any) => option.naam + option.artikelcode,
    });

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
          <Autocomplete
              multiple
              filterOptions={filterOptions}
              id="products-autocomplete"
              options={products.value}
              getOptionLabel={(option) => option.naam}
              onChange={(e, val) => {selectedProducts.value = val}}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => {
                  const { id, ...optionProps } = props;
                  return (
                  <li key={id} {...optionProps} style={{gap: '8px'}}>
                      <img src={option.image_url} alt="" />
                      {option.naam}

                  </li>
                  );
              }}
              style={{ width: '100%'}}
              renderInput={(params) => (
                  <TextField {...params} label="Zoek in artikelen" placeholder="Zoek op artikelnaam of artikelcode" />
              )}
          />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', px: '8px'}}>
                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                  <Checkbox
                      indeterminate={selected.value.length > 0 && selected.value.length < selectedProducts.value.length}
                      checked={selected.value.length > 0 && selected.value.length === selectedProducts.value.length}
                      onChange={(e) => {handleSelectAllClick(e)}}
                      color="secondary"
                  />
                  <Typography variant="labelLarge" sx={{color: 'grey'}}>
                    {selected.value.length > 0 ? `${selected.value.length}/${selectedProducts.value.length} artikelen geselecteerd` : ''}
                  </Typography>
                </Box>
                <IconButton onClick={() => {selectedProducts.value = selectedProducts.value.filter(product => !selected.value.includes(product.id)); selected.value = []}}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              {
                selectedProducts.value.length === 0 ?
                <Typography variant='titleMedium' sx={{mx: 'auto'}}>Selecteer een artikel</Typography> 
                :
                <Paper variant='outlined' sx={{display: 'flex', flexDirection: 'column', borderRadius: '16px'}}>
             
                {
                  selectedProducts.value.map((product) =>
                  <>
                    <Box key={product.id} sx={{display: 'flex', height: '80px', width: '100%', px: '8px', alignItems: 'center', gap: '8px'}}>
                      <Checkbox 
                        color="secondary"
                        checked={selected.value.includes(product.id)}
                        onChange={() => {selected.value.includes(product.id) ?  selected.value = selected.value.filter(p => p !== product.id) : selected.value = [...selected.value, product.id] }}
                        sx={{width: '40px', height: '40px' }}
                      />
                      <img src={product.image_url ? product.image_url : ''} alt='' style={{width: '50px', height: '50px'}}/>
                      <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>
                          {product.naam}
                        </Typography>
                      </Box>
                      <IconButton sx={{width: '40px', height: '40px', ml: 'auto' }} onClick={() => {selectedProducts.value = selectedProducts.value.filter(p => product.id !== p.id); selected.value = selected.value.filter(p => product.id !== p)}}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </>
                  )
                }
                
              </Paper>
              }
          </Box>
          <Typography variant='bodyLarge'>
            De te presenteren artikel opties & varianten zijn aan te passen per webshop. Dit doe je bij het aanmaken of bewerken van de webshop.
          </Typography>
        </Box>
    );
  };

export default ProductsToGroup; 