import { Box, Button, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OrderHistoryTab from "../../components/orders/OrderHistoryTab";
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { OrderClientDetailOut, OrderItemDetailOut, OrderStatusDetailOut, ShopCategories } from "../../assets";
import { Signal } from "@preact/signals-react";
import OrderCancel from "../../components/orders/OrderCancel";
import OrderMail from "../../components/orders/OrderMail";
import EmailOutlined from "@mui/icons-material/EmailOutlined";

export default function OrderDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const order_number = String(useParams().order_number);
    const webshop = String(location.state.webshop);

    useSignals();
    // Define signals
    const statusList: Signal<OrderStatusDetailOut[]> = useSignal([]);
    const clientDetails: Signal<OrderClientDetailOut | null> = useSignal(null);
    const openCancel: Signal<boolean> = useSignal(false);
    const openMail: Signal<boolean> = useSignal(false);
    const editing: Signal<boolean> = useSignal(false);
    const name: Signal<string> = useSignal('');
    const mail: Signal<string> = useSignal('');
    const business: Signal<string> = useSignal('');
    const items: Signal<OrderItemDetailOut[]> = useSignal([]);
    const itemsTotal: Signal<number> = useSignal(0);

    // Initial data load
    useEffect(() => {
        apiClient().shops.getOrderFactsByOrderNumberShopsOrderStatusOrderNumberGet(order_number).then((r) => {
            statusList.value = r.items;
        })
        apiClient().shops.getOrderClientByOrderNumberShopsOrderClientOrderNumberGet(order_number, 
            webshop as ShopCategories
        ).then((r) => {
            name.value = r.name;
            mail.value = r.email;
            business.value = r.business? r.business : '';
            clientDetails.value = r;
        })
        apiClient().shops.getOrderItemsByOrderNumberShopsOrderItemsOrderNumberGet(order_number).then((r) => {
            items.value = r.items;
            itemsTotal.value = r.items.reduce((sum, current) => sum + (current.price_excl_vat + current.vat_price) * current.amount, 0);
        })
    }, [])


    const saveInformation = () => {
        apiClient().driveThru.editOrderDriveThruOrdersOrderNumberPatch(order_number, {
            order_client_name: name.value,
            order_client_email: mail.value,
            order_client_business: business.value
        }).then((r) => {
            apiClient().shops.getOrderClientByOrderNumberShopsOrderClientOrderNumberGet(order_number, 
                webshop as ShopCategories
            ).then((r) => {
                clientDetails.value = r;
            })
        })
       
        editing.value = false;
    }

    return (
        <ThemeProvider theme={theme}>
            <OrderCancel 
                open={openCancel}
                orders={
                    [{orderNumber: order_number, customerName: clientDetails.value?.name ? clientDetails.value?.name : '', webshop: webshop}]
                }
            />
            <OrderMail 
                open={openMail}
                orders={
                    [{orderNumber: order_number, customerEmail: clientDetails.value?.email ? clientDetails.value?.email : '', webshop: webshop}]
            }
            />
            {/* Header */}
            <Box sx={{display: 'flex', gap: '16px', my: '8px', pr: '16px'}}>
                <IconButton onClick={() => {navigate(-1)}}>
                    <ArrowBackOutlinedIcon sx={{color: '#000'}}/>
                </IconButton>
                <Typography variant='headlineLarge'>
                    Order #{order_number}
                </Typography>

                <Typography onClick={() => {openCancel.value = true}} sx={{cursor: 'pointer',alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto'}} variant="labelLarge" >
                    Annuleer order
                </Typography> 
                {/* <Button sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '280px',
                    '&:hover': {
                    backgroundColor: 'secondary.light',
                    color: '#fff',
                },}}>
                    <QuestionAnswerOutlinedIcon />
                    <Typography variant="labelLarge" sx={{ml: '4px'}}>
                        Bericht klant
                    </Typography>
                </Button>  */}
                <Button onClick={() => {openMail.value = true}} sx={{backgroundColor: 'secondary.main', borderRadius: '20px', color: '#fff', width: 'fit-content',
                    '&:hover': {
                    backgroundColor: 'secondary.light',
                    color: '#fff',
                },}}>
                    <EmailOutlined sx={{mr: '4px'}}/>
                    <Typography variant="labelLarge">
                        Bevestiging opnieuw versturen
                    </Typography>
                </Button>
            </Box>

            {/* Body */}
            <Box sx={{display: 'flex', gap: '32px', pr: '16px'}}>
                {/* Order details */}
                <Box sx={{display: 'flex', flexDirection: 'column', width: '70%'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '24px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography variant="titleLarge" sx={{fontWeight: '700'}}>
                                            Order items
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="bodyLarge" sx={{fontWeight: '700'}}>
                                            Aantal
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="bodyLarge" sx={{fontWeight: '700'}}>
                                            Prijs
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="bodyLarge" sx={{fontWeight: '700'}}>
                                            Totaalprijs
                                        </Typography>
                                    </TableCell>
                                
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {items.value.map((item) => (
                                    <TableRow
                                    key={item.variant.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell width={'5%'} component="th" scope="row">
                                            <img src={item.variant.variant_image ? item.variant.variant_image[0] : undefined} alt={`Afbeelding van ${item.variant.sales_product?.sales_product_name}`} style={{
                                                width: '50px', height: '50px', maxWidth: '50px', maxHeight: '50px', marginLeft: 'auto', marginRight: 'auto'
                                            }}/>
                                        </TableCell>
                                        <TableCell width={'40%'}>
                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography variant="bodyLarge">
                                                    {item.variant.sales_product?.sales_product_name}
                                                </Typography>
                                                <Typography variant="bodyMedium">
                                                    {item.variant.product_options?.map(option => {return option.product_option_name})}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="bodyLarge">
                                                x{item.amount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="bodyLarge">
                                                {(item.price_excl_vat + item.vat_price).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="bodyLarge">
                                                {((item.price_excl_vat + item.vat_price) * item.amount).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Divider />
                       <Box sx={{display: 'flex', gap: '16px'}}>
                            <Box sx={{display: 'flex', width: '60%', flexDirection: 'column', gap: '8px'}}>
                                <Typography variant='labelLarge'>
                                    Notitie bij bestelling
                                </Typography>
                                <Typography variant='bodyLarge'>
                                    -
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', width: '40%', flexDirection: 'column', gap: '16px'}}>
                                <Box sx={{display: 'flex', gap: '4px'}}>
                                    <Typography variant='labelLarge'>
                                        Subtotaal
                                    </Typography>
                                    <EuroSymbolOutlinedIcon sx={{ml: 'auto'}} />
                                    <Typography variant='bodyLarge'>
                                        {(itemsTotal.value).toFixed(2)}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '4px'}}>
                                    <Typography variant='labelLarge'>
                                        Bezorgkosten
                                    </Typography>
                                    <EuroSymbolOutlinedIcon sx={{ml: 'auto'}} />
                                    <Typography variant='bodyLarge'>
                                        0.00
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: '4px'}}>
                                    <Typography variant='labelLarge'>
                                    BTW
                                    </Typography>
                                    <EuroSymbolOutlinedIcon sx={{ml: 'auto'}} />
                                    <Typography variant='bodyLarge'>
                                        {((itemsTotal.value * 1.09) - itemsTotal.value).toFixed(2)}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box sx={{display: 'flex', gap: '4px'}}>
                                    <Typography variant='labelLarge'>
                                        Totaal
                                    </Typography>
                                    <EuroSymbolOutlinedIcon sx={{ml: 'auto'}} />
                                    <Typography variant='bodyLarge'>
                                        {(itemsTotal.value * 1.09).toFixed(2)}
                                    </Typography>
                                </Box>
                                
                            </Box>
                       </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '24px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant='titleLarge'>
                                Klant details
                            </Typography>
                            {
                                editing.value ?
                                <Box sx={{display: 'flex', gap: '8px'}}>
                                    <Typography onClick={() => {editing.value = false}} sx={{cursor: 'pointer',alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto'}} variant="labelLarge" >
                                        Annuleer
                                    </Typography> 
                                    <Button onClick={saveInformation} sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '120px',
                                    '&:hover': {
                                    backgroundColor: 'secondary.light',
                                    color: '#fff',
                                    },}}>
                                        <SaveOutlinedIcon />
                                        <Typography variant="labelLarge" sx={{ml: '4px'}}>
                                            Opslaan
                                        </Typography>
                                    </Button> 
                                </Box> 
                                :
                                <Button onClick={() => editing.value = true} sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '200px',
                                    '&:hover': {
                                    backgroundColor: 'secondary.light',
                                    color: '#fff',
                                },}}>
                                    <EditOutlinedIcon />
                                    <Typography variant="labelLarge" sx={{ml: '4px'}}>
                                        Wijzig gegevens
                                    </Typography>
                                </Button> 
                            }
                            
                        </Box>
                        <Divider />
                        <Box sx={{display: 'flex', gap: '16px'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', gap: '8px'}}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                    <PersonOutlineOutlinedIcon />
                                    {
                                        editing.value ? 
                                            <TextField size="small" variant='outlined' value={name.value} sx={{width: '60%'}} onChange={(e) => {name.value = e.target.value}}/>
                                        : 
                                            <Typography variant='bodyLarge'>
                                                {clientDetails.value?.name ? clientDetails.value?.name : '-'}
                                            </Typography>
                                    }
                                </Box>            
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                    <EmailOutlinedIcon />
                                    {
                                        editing.value ? 
                                            <TextField size="small" variant='outlined' value={mail.value} sx={{width: '60%'}} onChange={(e) => {mail.value = e.target.value}}/>
                                        : 
                                            <Typography variant='bodyLarge'>
                                                {clientDetails.value?.email ? clientDetails.value?.email : '-'}
                                            </Typography>
                                    }
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                    <LocalPhoneOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        {clientDetails.value?.phone ? clientDetails.value?.phone : '-'}
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                    <BusinessOutlinedIcon />
                                    {
                                        editing.value ? 
                                            <TextField size="small" variant='outlined' value={business.value} sx={{width: '60%'}} onChange={(e) => {business.value = e.target.value}}/>
                                        : 
                                            <Typography variant='bodyLarge'>
                                                {clientDetails.value?.business ? clientDetails.value?.business : '-'}
                                            </Typography>
                                    }
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', gap: '8px'}}>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                    <PlaceOutlinedIcon />
                                    <Typography variant='bodyLarge'>
                                        {clientDetails.value?.street ? clientDetails.value?.street + ' ' + clientDetails.value?.number + (clientDetails.value?.number_suffix ? ' ' + clientDetails.value?.number_suffix : '')  : '-'}
                                    </Typography>
                                </Box>
                                <Typography variant='bodyLarge' sx={{ml: '28px'}}>
                                    {clientDetails.value?.zipcode ? clientDetails.value?.zipcode : '-'}
                                </Typography>
                                <Typography variant='bodyLarge' sx={{ml: '28px'}}>
                                    {clientDetails.value?.city ? clientDetails.value?.city : '-'}
                                </Typography>
                                <Box sx={{display: 'flex', gap: '16px', ml: '28px'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', width: '50%'}}>
                                        <Typography variant='labelLarge'>
                                            Appartement
                                        </Typography>
                                        {clientDetails.value?.apartment ? <CheckCircleOutlineOutlinedIcon /> : <CancelOutlinedIcon />}
                                    </Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', width: '50%'}}>
                                        <Typography variant='labelLarge'>
                                            Bedrijfsadres
                                        </Typography>
                                        {clientDetails.value?.apartment ? <CancelOutlinedIcon /> : <CheckCircleOutlineOutlinedIcon />}
                                    </Box>
                                </Box>
                            </Box>
                           
                        </Box>
                        <Typography variant='labelLarge'>
                            Opmerkingen bij klant
                        </Typography>
                        <Typography variant='bodyLarge'>
                            {clientDetails.value?.notes ? clientDetails.value?.notes : '-'}
                        </Typography>
                    </Box>
                </Box>
                {/* Order status */}
                <Box sx={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '24px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                        <Typography variant='titleLarge'>
                            Order historie
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                            <Typography variant='labelSmall' sx={{ml: 'auto'}}>
                                Ontvangst
                            </Typography>
                            <Divider />
                        </Box>
                        <OrderHistoryTab key={'Besteld'} title="Besteld" date={statusList.value.find(status => status.status === 'Besteld')?.fact_datetime!} Icon={ShoppingCartOutlinedIcon}/>
                        <OrderHistoryTab key={'Betaald'} title="Betaald" date={statusList.value.find(status => status.status === 'Betaald')?.fact_datetime!} Icon={EuroSymbolOutlinedIcon}/>
                        <OrderHistoryTab key={'Orderbevestiging'} title="Orderbevestiging" date={statusList.value.find(status => status.status === 'Orderbevestiging')?.fact_datetime!} Icon={EmailOutlinedIcon}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                            <Typography variant='labelSmall' sx={{ml: 'auto'}}>
                                Verwerking
                            </Typography>
                            <Divider />
                        </Box>
                        <OrderHistoryTab key={'In behandelin'} title="In behandeling" date={statusList.value.find(status => status.status === 'In behandeling')?.fact_datetime!} Icon={PendingOutlinedIcon}/>
                        <OrderHistoryTab key={'Gescand'} title="Gescand" date={statusList.value.find(status => status.status === 'Gescand')?.fact_datetime!} Icon={QrCodeScannerOutlinedIcon}/>
                        <OrderHistoryTab key={'Orderpicking'} title="Orderpicking" date={statusList.value.find(status => status.status === 'Orderpicking')?.fact_datetime!} Icon={BackHandOutlinedIcon}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                            <Typography variant='labelSmall' sx={{ml: 'auto'}}>
                                Afhaal- of Bezorgfase
                            </Typography>
                            <Divider />
                        </Box>
                        <OrderHistoryTab key={'Geleverd'} title="Geleverd" date={statusList.value.find(status => status.status === 'Geleverd')?.fact_datetime!} Icon={DirectionsCarFilledOutlinedIcon}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                            <Typography variant='labelSmall' sx={{ml: 'auto'}}>
                                Afsluiting
                            </Typography>
                            <Divider />
                        </Box>
                        <OrderHistoryTab key={'Afgesloten'} title="Afgesloten" date={statusList.value.find(status => status.status === 'Afgesloten')?.fact_datetime!} Icon={CheckOutlinedIcon}/>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}