/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Preflight Handler
     * @param restOfPath
     * @returns any Successful Response
     * @throws ApiError
     */
    public preflightHandlerRestOfPathOptions(
        restOfPath: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/{rest_of_path}',
            path: {
                'rest_of_path': restOfPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preflight Handler
     * @param restOfPath
     * @returns any Successful Response
     * @throws ApiError
     */
    public preflightHandlerV1RestOfPathOptions(
        restOfPath: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/v1/{rest_of_path}',
            path: {
                'rest_of_path': restOfPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public rootGet(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/',
        });
    }

    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    public rootV1Get(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/',
        });
    }

}
