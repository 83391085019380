import { Box, Grid, IconButton, SvgIconTypeMap, TextField, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Signal } from "@preact/signals-react";
import Card from "./Card";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ButtonFull from "../buttons/ButtonFull";
import { CancelablePromise } from "../../assets";
import { useRef } from "react";
import ReactDOM from "react-dom";


interface InformationEditProps {
    title: string,
    fields: {
        Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
        title: string
        value: Signal<string>
    }[]
    editEndpoint: (args: {}) => CancelablePromise<any>,
    requestBody: {[key: string]: any}
}

const InformationEdit: React.FC<InformationEditProps> = ({title, fields, editEndpoint, requestBody}) => {
    useSignals();

    const editing: Signal<boolean> = useSignal(false);

    const saveEdit = () => {
        fields.forEach((field) => {
            field.value.value = (document.getElementById(field.title) as HTMLInputElement).value
        })

        Object.keys(requestBody).forEach(function(key){ requestBody[key] = requestBody[key].value });
        console.log(requestBody)
        editEndpoint(
            requestBody
        ).then((r) => {
            editing.value = false
        })
    }

    return (
        <Card 
            title={title}
            button={
                editing.value ? 
                    <>
                    <ButtonFull text='Wijzigingen opslaan' onClick={saveEdit}/>
                     <IconButton onClick={() => {editing.value = false}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    </>
                :
                <IconButton onClick={() => {editing.value = true}}>
                    <EditOutlinedIcon />
                </IconButton>
            }
            children={
                <>
                    <Grid container spacing={1}>
                        {
                            fields.map((field) =>
                                <Grid item container key={field.title} spacing={2}>
                                    <Grid item sx={{ display: 'flex', width: '300px'}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            {<field.Icon />}
                                            <Typography sx={{ml: '8px'}} variant='labelLarge'>
                                                {field.title + ':'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sx={{ display: 'flex', width: '400px'}}>
                                        {
                                            editing.value ? 
                                                <TextField id={field.title} sx={{width: '400px'}} size='small' defaultValue={field.value.value} />
                                            :
                                                <Typography variant='bodyLarge'>
                                                    {field.value.value}
                                                </Typography>
                                        }
                                    </Grid>
                                    <Grid item sm></Grid>
                                </Grid>
                                
                            )
                        }
                        
                    </Grid>
                </>
            }
        />
    );
  };

export default InformationEdit;