/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShopCategories } from './ShopCategories';

export type Body_get_customers_filtered_v1_users_customers_post = {
    status?: Body_get_customers_filtered_v1_users_customers_post.status;
    webshops?: (Array<ShopCategories> | null);
    is_third_party?: boolean;
};

export namespace Body_get_customers_filtered_v1_users_customers_post {

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}

