/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Allergens } from './Allergens';
import type { Diets } from './Diets';
import type { ShopCategories } from './ShopCategories';
import type { Weekdays } from './Weekdays';

export type Body_get_sales_product_groups_filtered_items_sales_product_groups_post = {
    group_ids?: Array<string>;
    webshops?: Array<ShopCategories>;
    bezorgdagen?: Array<Weekdays>;
    status?: Body_get_sales_product_groups_filtered_items_sales_product_groups_post.status;
    option_ids?: Array<string>;
    allergens?: Array<Allergens>;
    diets?: Array<Diets>;
};

export namespace Body_get_sales_product_groups_filtered_items_sales_product_groups_post {

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}

