import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import VerticalStepper from "../modals/stepper/VerticalStepper";
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import ButtonFull from "../buttons/ButtonFull";
import ButtonOutlined from "../buttons/ButtonOutlined";
import OptionsToOptiongroup from "./OptionsToOptiongroup";
import { apiClient } from "../../functions/Login";
import { ProductOptionCategoryIn, ProductOptionIn, ProductOptionTypes } from "../../assets";

interface ProductOptionAddProps {
    open: Signal<boolean>
}

interface option {
    name: string
    subcode: string
    option_type: ProductOptionTypes | ''
    is_inclusive: boolean
    has_quantity: boolean
    quantity_factor: string
    price_factor: string
    price_bonus: string
    char_count: string
    per_char_bonus: string
    total_char_bonus: string
    min_amount: string
    max_amount: string
    per_amount_bonus: string
    total_amount_bonus: string
    files: ('jpg' | 'png' | 'pdf' | 'doc')[]
    total_file_bonus: string
    has_allergens: boolean
    allergens: string[]
}

const ProductOptionAdd: React.FC<ProductOptionAddProps> = ({open}) => {
    useSignals();

    const option_name: Signal<string> = useSignal('');
    const moreOptions: Signal<boolean> = useSignal(false);
    const minOptions: Signal<number> = useSignal(1);
    const maxOptions: Signal<number> = useSignal(1);
    const options: Signal<option[]> = useSignal([{
        'name': '',
        'subcode': '',
        'option_type': '',
        'has_quantity': false,
        'is_inclusive': false,
        'quantity_factor': '1,00',
        'price_factor': '1,00',
        'price_bonus': '0,00',
        'char_count': '0',
        'per_char_bonus': '0,00',
        'total_char_bonus': '0,00',
        'min_amount': '0',
        'max_amount': '0',
        'per_amount_bonus': '0,00',
        'total_amount_bonus': '0,00',
        'files': [],
        'total_file_bonus': '0,00',
        'has_allergens': false,
        'allergens': []
    }])

    const reset = () => {
        batch(() => {
           option_name.value = '';
           moreOptions.value = false;
           minOptions.value = 1;
           maxOptions.value = 1;
           options.value = [{
                'name': '',
                'subcode': '',
                'option_type': '',
                'has_quantity': false,
                'is_inclusive': false,
                'quantity_factor': '1,00',
                'price_factor': '1,00',
                'price_bonus': '0,00',
                'char_count': '0',
                'per_char_bonus': '0,00',
                'total_char_bonus': '0,00',
                'min_amount': '0',
                'max_amount': '0',
                'per_amount_bonus': '0,00',
                'total_amount_bonus': '0,00',
                'files': [],
                'total_file_bonus': '0,00',
                'has_allergens': false,
                'allergens': []
            }]
        })
    }

    useEffect(() => {
      
    }, [])

    const createOptions = () => {
        const list = [] as Array<ProductOptionIn>

        options.value.map((option) => {
            let option_body = {
                product_option_name: option.name,
                product_subcode: option.subcode,
                surcharge_incl_vat: option.is_inclusive,
                allergens: option.allergens,
                surcharge_multiplies: false,
                product_option_type: option.option_type
            } as ProductOptionIn

            if (option.option_type === ProductOptionTypes.OPTIESELECTOR) {
                if (option.has_quantity) {
                    option_body = {...option_body, 
                        price_factor: Number.parseFloat(option.price_factor),
                        quantity_factor: Number.parseFloat(option.quantity_factor)
                    }
                }
                option_body = {...option_body, surcharge_price: Number.parseFloat(option.price_bonus)}
            } else if (option.option_type === ProductOptionTypes.VRIJ_TEKSTVELD) {
                option_body = {...option_body,
                    multiply_surcharge_min: 0,
                    multiply_surcharge_max: +option.char_count,
                }
                if (option.per_char_bonus === '0,00') {
                    option_body = {...option_body,
                        surcharge_price: Number.parseFloat(option.total_char_bonus)
                    }
                } else {
                    option_body = {...option_body,
                        surcharge_multiplies: true,
                        surcharge_price: Number.parseFloat(option.per_char_bonus),
                    }
                }
            } else if (option.option_type === ProductOptionTypes.VRIJ_NUMERIEK_VELD) {
                option_body = {...option_body,
                    multiply_surcharge_min: +option.min_amount,
                    multiply_surcharge_max: +option.max_amount,
                }
                if (option.per_char_bonus === '0,00') {
                    option_body = {...option_body,
                        surcharge_price: Number.parseFloat(option.total_amount_bonus)
                    }
                } else {
                    option_body = {...option_body,
                        surcharge_multiplies: true,
                        surcharge_price: Number.parseFloat(option.per_amount_bonus),
                    }
                }
            } else {
                option_body = {...option_body,
                    image_type_docx: option.files.includes('doc') ? true : false,
                    image_type_jpeg: option.files.includes('jpg') ? true : false,
                    image_type_pdf: option.files.includes('pdf') ? true : false,
                    image_type_png: option.files.includes('png') ? true : false,
                }
            }
            list.push(option_body);
            return option
        })

        return list
    }

    const createOptionGroup = (activeStep: Signal<number>) => {
        const body = {
            category_name: option_name.value,
            multi_select_type: moreOptions.value, 
            select_options_min: minOptions.value,
            select_options_max: maxOptions.value,
            product_options: createOptions()
        } as ProductOptionCategoryIn

        apiClient().itemManagement.createProductOptionCategoryItemsCreateProductOptionCategoryPost(body).then((r) => {
            activeStep.value += 1;
        })
    }

    const finalContent = <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', height: '100%'}}>
        <Typography variant='bodyLarge'>
            De optiegroep {option_name} is toegevoegd.
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', mt: 'auto'}}>
            <ButtonFull text='Sluiten' onClick={() => {open.value = false; reset()}} width='100%'/>
            <ButtonOutlined text='Sluiten en nog een optiegroep toevoegen' onClick={() => {reset()}} width='100%'/>
        </Box>
    </Box>

    const steps = [
        {
            label: 'Optiegroep',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant='bodyLarge' >Hoe wil je de optiegroep noemen?</Typography>
                <TextField required label="Naam optiegroep" placeholder='B.v. Grootte of Tekst op taart' variant="outlined" value={option_name.value} onChange={(e) => {option_name.value = e.currentTarget.value}}/>
                <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>Aantal te kiezen opties</Typography>
                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                  <Checkbox
                      onChange={(e) => {moreOptions.value = !moreOptions.value}}
                      color="secondary"
                  />
                  <Typography variant="labelLarge" >
                    De klant mag meer dan één optie kiezen binnen deze optiegroep
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                <TextField disabled={!moreOptions.value} required={moreOptions.value} label="Minimaal aantal opties" variant="outlined" value={minOptions.value} onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/)) minOptions.value = +e.currentTarget.value}}/>
                  <Typography variant="labelLarge" sx={{fontWeight: '700'}}>
                    tot
                  </Typography>
                  <TextField disabled={!moreOptions.value} required={moreOptions.value} label="Maximaal aantal opties" variant="outlined" value={maxOptions.value} onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/)) maxOptions.value = +e.currentTarget.value}}/>
                </Box>
            </Box>
        },
        {
            label: 'Opties',
            content: 
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', overflowY: 'auto'}}>
                <OptionsToOptiongroup options={options}/>
            </Box>
        }
    ]
    return (
        <VerticalStepper open={open} title={'Optiegroep toevoegen'} steps={steps} finalContent={finalContent} func={createOptionGroup} resetFunc={reset}/>
    );
  };

export default ProductOptionAdd;