/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_check_webshop_url_shops_shops_webshop_url_post } from '../models/Body_check_webshop_url_shops_shops_webshop_url_post';
import type { Body_check_webshop_url_v1_shops_shops_webshop_url_post } from '../models/Body_check_webshop_url_v1_shops_shops_webshop_url_post';
import type { Body_get_all_shop_items_shops_order_details_items_post } from '../models/Body_get_all_shop_items_shops_order_details_items_post';
import type { Body_get_all_shop_items_v1_shops_order_details_items_post } from '../models/Body_get_all_shop_items_v1_shops_order_details_items_post';
import type { Body_get_all_shop_orders_shops_order_details_orders_post } from '../models/Body_get_all_shop_orders_shops_order_details_orders_post';
import type { Body_get_all_shop_orders_v1_shops_order_details_orders_post } from '../models/Body_get_all_shop_orders_v1_shops_order_details_orders_post';
import type { Body_get_all_shops_filtered_shops_shops_filtered_post } from '../models/Body_get_all_shops_filtered_shops_shops_filtered_post';
import type { Body_get_all_shops_filtered_v1_shops_shops_filtered_post } from '../models/Body_get_all_shops_filtered_v1_shops_shops_filtered_post';
import type { Body_get_order_overview_metrics_shops_order_overview_metrics_post } from '../models/Body_get_order_overview_metrics_shops_order_overview_metrics_post';
import type { Body_get_order_overview_metrics_v1_shops_order_overview_metrics_post } from '../models/Body_get_order_overview_metrics_v1_shops_order_overview_metrics_post';
import type { Body_get_order_overview_timeline_shops_order_overview_timeline_post } from '../models/Body_get_order_overview_timeline_shops_order_overview_timeline_post';
import type { Body_get_order_overview_timeline_v1_shops_order_overview_timeline_post } from '../models/Body_get_order_overview_timeline_v1_shops_order_overview_timeline_post';
import type { Body_refund_shop_order_shops_order_refund_post } from '../models/Body_refund_shop_order_shops_order_refund_post';
import type { Body_refund_shop_order_v1_shops_order_refund_post } from '../models/Body_refund_shop_order_v1_shops_order_refund_post';
import type { DriveThruShopIn } from '../models/DriveThruShopIn';
import type { OrderClientDetailOut } from '../models/OrderClientDetailOut';
import type { OrderItemDetailOutList } from '../models/OrderItemDetailOutList';
import type { OrderOverviewMetricOut } from '../models/OrderOverviewMetricOut';
import type { OrderOverviewTimelineOutList } from '../models/OrderOverviewTimelineOutList';
import type { OrderStatusDetailOutList } from '../models/OrderStatusDetailOutList';
import type { Page_ItemListDetailOut_ } from '../models/Page_ItemListDetailOut_';
import type { Page_OrderListDetailOut_ } from '../models/Page_OrderListDetailOut_';
import type { ShopCategories } from '../models/ShopCategories';
import type { ShopOut } from '../models/ShopOut';
import type { ShopOutCroppedList } from '../models/ShopOutCroppedList';
import type { ShopOverviewOutList } from '../models/ShopOverviewOutList';
import type { ShopSubCategoryEdit } from '../models/ShopSubCategoryEdit';
import type { ShopSubCategoryIn } from '../models/ShopSubCategoryIn';
import type { ShopSubCategoryOut } from '../models/ShopSubCategoryOut';
import type { ShopSubCategoryOutList } from '../models/ShopSubCategoryOutList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ShopsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get All Shops
     * Get all shops
     * @returns ShopOutCroppedList Successful Response
     * @throws ApiError
     */
    public getAllShopsShopsShopsGet(): CancelablePromise<ShopOutCroppedList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shops',
        });
    }

    /**
     * Get Shops By Category
     * Get all shops by category
     * @param category
     * @returns ShopOverviewOutList Successful Response
     * @throws ApiError
     */
    public getShopsByCategoryShopsShopsCategoryCategoryGet(
        category: ShopCategories,
    ): CancelablePromise<ShopOverviewOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shops/category/{category}',
            path: {
                'category': category,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shops Filtered
     * Get all shops by filter values
     * @param formData
     * @returns ShopOutCroppedList Successful Response
     * @throws ApiError
     */
    public getAllShopsFilteredShopsShopsFilteredPost(
        formData?: Body_get_all_shops_filtered_shops_shops_filtered_post,
    ): CancelablePromise<ShopOutCroppedList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/shops/filtered',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop By Id
     * Get shop by id
     * @param shopId
     * @returns ShopOut Successful Response
     * @throws ApiError
     */
    public getShopByIdShopsShopsShopIdGet(
        shopId: string,
    ): CancelablePromise<ShopOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shops/{shop_id}',
            path: {
                'shop_id': shopId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shop By Id
     * Delete a shop by id.
     * @param shopId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteShopByIdShopsShopsShopIdDelete(
        shopId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/shops/shops/{shop_id}',
            path: {
                'shop_id': shopId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Drive Thru Shop
     * Create drive thru shop
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createDriveThruShopShopsCreateDriveThruShopPost(
        requestBody: DriveThruShopIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/create_drive_thru_shop',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Shop By Id
     * Restore a shop by id.
     * @param shopId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreShopByIdShopsShopsShopIdRestoreGet(
        shopId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shops/{shop_id}/restore',
            path: {
                'shop_id': shopId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Webshop Url
     * Check if webshop url is valid. Response is None when url is not found otherwise 409 error is raised
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public checkWebshopUrlShopsShopsWebshopUrlPost(
        formData: Body_check_webshop_url_shops_shops_webshop_url_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/shops/webshop_url',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Sub Categories
     * Get all shop categories
     * @returns ShopSubCategoryOutList Successful Response
     * @throws ApiError
     */
    public getAllShopSubCategoriesShopsShopSubCategoriesGet(): CancelablePromise<ShopSubCategoryOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shop_sub_categories',
        });
    }

    /**
     * Create Shop Sub Category
     * Create new shop category
     * @param requestBody
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public createShopSubCategoryShopsShopSubCategoriesPost(
        requestBody: ShopSubCategoryIn,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/shop_sub_categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Sub Category By Id
     * Get shop category by id
     * @param shopSubCategoryId
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public getShopSubCategoryByIdShopsShopSubCategoriesShopSubCategoryIdGet(
        shopSubCategoryId: string,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shop Sub Category By Id
     * Edit shop category by id
     * @param shopSubCategoryId
     * @param requestBody
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public editShopSubCategoryByIdShopsShopSubCategoriesShopSubCategoryIdPatch(
        shopSubCategoryId: string,
        requestBody: ShopSubCategoryEdit,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shop Sub Category By Id
     * Delete a shop category by id.
     * @param shopSubCategoryId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteShopSubCategoryByIdShopsShopSubCategoriesShopSubCategoryIdDelete(
        shopSubCategoryId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Shop Sub Category By Id
     * Restore a shop category by id.
     * @param shopSubCategoryId
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public restoreShopSubCategoryByIdShopsShopSubCategoriesShopSubCategoryIdRestoreGet(
        shopSubCategoryId: string,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/shop_sub_categories/{shop_sub_category_id}/restore',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Overview Metrics
     * Get order overview by filter values
     * @param search
     * @param requestBody
     * @returns OrderOverviewMetricOut Successful Response
     * @throws ApiError
     */
    public getOrderOverviewMetricsShopsOrderOverviewMetricsPost(
        search?: (string | null),
        requestBody?: Body_get_order_overview_metrics_shops_order_overview_metrics_post,
    ): CancelablePromise<OrderOverviewMetricOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/order_overview_metrics',
            query: {
                'search': search,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Overview Timeline
     * Get order counts over time by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param shopType
     * @param aggregateLevel
     * @param requestBody
     * @returns OrderOverviewTimelineOutList Successful Response
     * @throws ApiError
     */
    public getOrderOverviewTimelineShopsOrderOverviewTimelinePost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        shopType?: (ShopCategories | null),
        aggregateLevel: 'year' | 'month' | 'week' | 'day' | 'hour' = 'month',
        requestBody?: Body_get_order_overview_timeline_shops_order_overview_timeline_post,
    ): CancelablePromise<OrderOverviewTimelineOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/order_overview_timeline',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'shop_type': shopType,
                'aggregate_level': aggregateLevel,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Orders
     * Get all orders across shops by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param customerId
     * @param shopType
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_OrderListDetailOut_ Successful Response
     * @throws ApiError
     */
    public getAllShopOrdersShopsOrderDetailsOrdersPost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        customerId?: (string | null),
        shopType?: (ShopCategories | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_shop_orders_shops_order_details_orders_post,
    ): CancelablePromise<Page_OrderListDetailOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/order_details/orders',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'customer_id': customerId,
                'shop_type': shopType,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Items
     * Get items across shops by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param shopType
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ItemListDetailOut_ Successful Response
     * @throws ApiError
     */
    public getAllShopItemsShopsOrderDetailsItemsPost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        shopType?: (ShopCategories | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_shop_items_shops_order_details_items_post,
    ): CancelablePromise<Page_ItemListDetailOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/order_details/items',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'shop_type': shopType,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Items By Order Number
     * Get order items by order number
     * @param orderNumber
     * @returns OrderItemDetailOutList Successful Response
     * @throws ApiError
     */
    public getOrderItemsByOrderNumberShopsOrderItemsOrderNumberGet(
        orderNumber: string,
    ): CancelablePromise<OrderItemDetailOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/order_items/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Client By Order Number
     * Get order client details by order number
     * @param orderNumber
     * @param shopType
     * @returns OrderClientDetailOut Successful Response
     * @throws ApiError
     */
    public getOrderClientByOrderNumberShopsOrderClientOrderNumberGet(
        orderNumber: string,
        shopType: ShopCategories,
    ): CancelablePromise<OrderClientDetailOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/order_client/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            query: {
                'shop_type': shopType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Facts By Order Number
     * Get order facts by order number
     * @param orderNumber
     * @returns OrderStatusDetailOutList Successful Response
     * @throws ApiError
     */
    public getOrderFactsByOrderNumberShopsOrderStatusOrderNumberGet(
        orderNumber: string,
    ): CancelablePromise<OrderStatusDetailOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/shops/order_status/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Shop Order
     * Refund drive thru order by order number.
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public refundShopOrderShopsOrderRefundPost(
        formData: Body_refund_shop_order_shops_order_refund_post,
        dryRun: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/shops/order_refund',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shops
     * Get all shops
     * @returns ShopOutCroppedList Successful Response
     * @throws ApiError
     */
    public getAllShopsV1ShopsShopsGet(): CancelablePromise<ShopOutCroppedList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shops',
        });
    }

    /**
     * Get Shops By Category
     * Get all shops by category
     * @param category
     * @returns ShopOverviewOutList Successful Response
     * @throws ApiError
     */
    public getShopsByCategoryV1ShopsShopsCategoryCategoryGet(
        category: ShopCategories,
    ): CancelablePromise<ShopOverviewOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shops/category/{category}',
            path: {
                'category': category,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shops Filtered
     * Get all shops by filter values
     * @param formData
     * @returns ShopOutCroppedList Successful Response
     * @throws ApiError
     */
    public getAllShopsFilteredV1ShopsShopsFilteredPost(
        formData?: Body_get_all_shops_filtered_v1_shops_shops_filtered_post,
    ): CancelablePromise<ShopOutCroppedList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/shops/filtered',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop By Id
     * Get shop by id
     * @param shopId
     * @returns ShopOut Successful Response
     * @throws ApiError
     */
    public getShopByIdV1ShopsShopsShopIdGet(
        shopId: string,
    ): CancelablePromise<ShopOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shops/{shop_id}',
            path: {
                'shop_id': shopId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shop By Id
     * Delete a shop by id.
     * @param shopId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteShopByIdV1ShopsShopsShopIdDelete(
        shopId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/shops/shops/{shop_id}',
            path: {
                'shop_id': shopId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Drive Thru Shop
     * Create drive thru shop
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public createDriveThruShopV1ShopsCreateDriveThruShopPost(
        requestBody: DriveThruShopIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/create_drive_thru_shop',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Shop By Id
     * Restore a shop by id.
     * @param shopId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreShopByIdV1ShopsShopsShopIdRestoreGet(
        shopId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shops/{shop_id}/restore',
            path: {
                'shop_id': shopId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Webshop Url
     * Check if webshop url is valid. Response is None when url is not found otherwise 409 error is raised
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public checkWebshopUrlV1ShopsShopsWebshopUrlPost(
        formData: Body_check_webshop_url_v1_shops_shops_webshop_url_post,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/shops/webshop_url',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Sub Categories
     * Get all shop categories
     * @returns ShopSubCategoryOutList Successful Response
     * @throws ApiError
     */
    public getAllShopSubCategoriesV1ShopsShopSubCategoriesGet(): CancelablePromise<ShopSubCategoryOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shop_sub_categories',
        });
    }

    /**
     * Create Shop Sub Category
     * Create new shop category
     * @param requestBody
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public createShopSubCategoryV1ShopsShopSubCategoriesPost(
        requestBody: ShopSubCategoryIn,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/shop_sub_categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shop Sub Category By Id
     * Get shop category by id
     * @param shopSubCategoryId
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public getShopSubCategoryByIdV1ShopsShopSubCategoriesShopSubCategoryIdGet(
        shopSubCategoryId: string,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Shop Sub Category By Id
     * Edit shop category by id
     * @param shopSubCategoryId
     * @param requestBody
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public editShopSubCategoryByIdV1ShopsShopSubCategoriesShopSubCategoryIdPatch(
        shopSubCategoryId: string,
        requestBody: ShopSubCategoryEdit,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shop Sub Category By Id
     * Delete a shop category by id.
     * @param shopSubCategoryId
     * @param hard
     * @returns void
     * @throws ApiError
     */
    public deleteShopSubCategoryByIdV1ShopsShopSubCategoriesShopSubCategoryIdDelete(
        shopSubCategoryId: string,
        hard: boolean = false,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/shops/shop_sub_categories/{shop_sub_category_id}',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            query: {
                'hard': hard,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Shop Sub Category By Id
     * Restore a shop category by id.
     * @param shopSubCategoryId
     * @returns ShopSubCategoryOut Successful Response
     * @throws ApiError
     */
    public restoreShopSubCategoryByIdV1ShopsShopSubCategoriesShopSubCategoryIdRestoreGet(
        shopSubCategoryId: string,
    ): CancelablePromise<ShopSubCategoryOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/shop_sub_categories/{shop_sub_category_id}/restore',
            path: {
                'shop_sub_category_id': shopSubCategoryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Overview Metrics
     * Get order overview by filter values
     * @param search
     * @param requestBody
     * @returns OrderOverviewMetricOut Successful Response
     * @throws ApiError
     */
    public getOrderOverviewMetricsV1ShopsOrderOverviewMetricsPost(
        search?: (string | null),
        requestBody?: Body_get_order_overview_metrics_v1_shops_order_overview_metrics_post,
    ): CancelablePromise<OrderOverviewMetricOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/order_overview_metrics',
            query: {
                'search': search,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Overview Timeline
     * Get order counts over time by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param shopType
     * @param aggregateLevel
     * @param requestBody
     * @returns OrderOverviewTimelineOutList Successful Response
     * @throws ApiError
     */
    public getOrderOverviewTimelineV1ShopsOrderOverviewTimelinePost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        shopType?: (ShopCategories | null),
        aggregateLevel: 'year' | 'month' | 'week' | 'day' | 'hour' = 'month',
        requestBody?: Body_get_order_overview_timeline_v1_shops_order_overview_timeline_post,
    ): CancelablePromise<OrderOverviewTimelineOutList> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/order_overview_timeline',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'shop_type': shopType,
                'aggregate_level': aggregateLevel,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Orders
     * Get all orders across shops by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param customerId
     * @param shopType
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_OrderListDetailOut_ Successful Response
     * @throws ApiError
     */
    public getAllShopOrdersV1ShopsOrderDetailsOrdersPost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        customerId?: (string | null),
        shopType?: (ShopCategories | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_shop_orders_v1_shops_order_details_orders_post,
    ): CancelablePromise<Page_OrderListDetailOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/order_details/orders',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'customer_id': customerId,
                'shop_type': shopType,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Shop Items
     * Get items across shops by filter values
     * @param orderDateTimeFrom
     * @param orderDateTimeTo
     * @param deliveryDateTimeFrom
     * @param deliveryDateTimeTo
     * @param shopType
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ItemListDetailOut_ Successful Response
     * @throws ApiError
     */
    public getAllShopItemsV1ShopsOrderDetailsItemsPost(
        orderDateTimeFrom?: (string | null),
        orderDateTimeTo?: (string | null),
        deliveryDateTimeFrom?: (string | null),
        deliveryDateTimeTo?: (string | null),
        shopType?: (ShopCategories | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_all_shop_items_v1_shops_order_details_items_post,
    ): CancelablePromise<Page_ItemListDetailOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/order_details/items',
            query: {
                'order_date_time_from': orderDateTimeFrom,
                'order_date_time_to': orderDateTimeTo,
                'delivery_date_time_from': deliveryDateTimeFrom,
                'delivery_date_time_to': deliveryDateTimeTo,
                'shop_type': shopType,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Items By Order Number
     * Get order items by order number
     * @param orderNumber
     * @returns OrderItemDetailOutList Successful Response
     * @throws ApiError
     */
    public getOrderItemsByOrderNumberV1ShopsOrderItemsOrderNumberGet(
        orderNumber: string,
    ): CancelablePromise<OrderItemDetailOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/order_items/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Client By Order Number
     * Get order client details by order number
     * @param orderNumber
     * @param shopType
     * @returns OrderClientDetailOut Successful Response
     * @throws ApiError
     */
    public getOrderClientByOrderNumberV1ShopsOrderClientOrderNumberGet(
        orderNumber: string,
        shopType: ShopCategories,
    ): CancelablePromise<OrderClientDetailOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/order_client/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            query: {
                'shop_type': shopType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Facts By Order Number
     * Get order facts by order number
     * @param orderNumber
     * @returns OrderStatusDetailOutList Successful Response
     * @throws ApiError
     */
    public getOrderFactsByOrderNumberV1ShopsOrderStatusOrderNumberGet(
        orderNumber: string,
    ): CancelablePromise<OrderStatusDetailOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/shops/order_status/{order_number}',
            path: {
                'order_number': orderNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refund Shop Order
     * Refund drive thru order by order number.
     * @param formData
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public refundShopOrderV1ShopsOrderRefundPost(
        formData: Body_refund_shop_order_v1_shops_order_refund_post,
        dryRun: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/shops/order_refund',
            query: {
                'dry_run': dryRun,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
