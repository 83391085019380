import { Autocomplete, Backdrop, Box, Checkbox, Divider, Fade, Grid, IconButton, MenuItem, Modal, Paper, Select, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { batch, Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ButtonFull from "../buttons/ButtonFull";
import ButtonText from "../buttons/ButtonText";
import { AddressIn, Body_signup_employee_invite_auth_signup_employee_invite_post, EmployeeIn, GroupBasicOut, GroupIn, MedewerkerNaamOut, ToegangsrechtenOverzichtOut, UserIn } from "../../assets";
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface GroupAddProps {
    open: Signal<boolean>
}

const GroupAdd: React.FC<GroupAddProps> = ({open}) => {
    useSignals();

    const activeStep: Signal<number> = useSignal(0)
    const completedStep: Signal<number> = useSignal(0);

    const group_name: Signal<string> = useSignal('');
    const description: Signal<string> = useSignal('');

    const selectedRoles: Signal<string[]> = useSignal([]);
    const roles: Signal<ToegangsrechtenOverzichtOut[]> = useSignal([]);
    const employees: Signal<MedewerkerNaamOut[]> = useSignal([]);
    const selectedEmployees: Signal<MedewerkerNaamOut[]> = useSignal([]);


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    useEffect(() => {
        apiClient().userManagement.getAllEmployeesUsersEmployeeNamesGet().then((r) => {
            employees.value = r.items
        })
        apiClient().policies.getAllRolesPoliciesRolesAllGet().then((r) => {
            roles.value = r.items
            let list = []
            for (const item of r.items) {
                for (let i = 0; i < item.toegang.length; i++) {
                    if (item.toegang[i]) {
                        list.push(item.ids[i])
                    }
                }
            }
            selectedRoles.value = list
        })
    }, [])
    
    const changeRoles = (id: string) => {
        if (selectedRoles.value.indexOf(id) > -1) {
            selectedRoles.value = selectedRoles.value.filter(x => x !== id);
        } else {
            selectedRoles.value = selectedRoles.value.concat(id)
        }
    }

    const createGroup = () => {
       apiClient().policies.createGroupPoliciesCreateGroupPost({name: group_name.value, description: description.value}).then((r) => {
            const id = r.id;

            apiClient().policies.addPoliciesToRolePoliciesRolesRoleIdAddPoliciesPost(id, selectedRoles.value);
            apiClient().policies.addEmployeesToGroupPoliciesGroupsGroupIdEmployeesPost(id, selectedEmployees.value.map(employee => employee.id));

            open.value = false;
            reset();
       })
    }

    const reset = () => {
        batch(() => {
            activeStep.value = 0;
            group_name.value = '';
            description.value = '';
            selectedRoles.value = [];
            selectedEmployees.value = [];
            completedStep.value = 0;
        })
    }

    const nextStep = () => {
        activeStep.value += 1;
        console.log(activeStep.value)
        if (activeStep.value > completedStep.value) {
            completedStep.value = activeStep.value
        }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto'
      };

    const steps = [
        'Functie naam',
        'Toegangsrechten',
        'Medewerkers',
    ]
    return (
        <Modal
            aria-labelledby="add-group-modal"
            aria-describedby="Modal to add new group"
            open={open.value}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false; reset()}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', px: '64px', pb: '64px'}}>
                        <Typography variant='headlineLarge' sx={{mx: 'auto'}}>
                            Functie toevoegen
                        </Typography>
                        <Stepper activeStep={activeStep.value} alternativeLabel>
                            {steps.map((label, index) => {
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step completed={(completedStep.value > index) ? true : false} onClick={(e) => {if (completedStep.value > index) activeStep.value = index}} sx={{cursor: (completedStep.value > index) ? 'pointer' : 'auto'}} key={label} >
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        {
                            activeStep.value === 0 ?
                                <form id='form' onSubmit={(e) => {e.preventDefault(); nextStep()}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '100%', alignItems:'center'}}>
                                        <Typography variant='bodyLarge' sx={{mx: 'auto'}}>
                                            Welke functie wil je aanmaken?
                                        </Typography>
                                        <TextField required value={group_name.value} onChange={(e) => {group_name.value = e.currentTarget.value}} sx={{width: '400px', mx: 'auto'}} label='Functienaam' />
                                        <TextField value={description.value} onChange={(e) => {description.value = e.currentTarget.value}} sx={{width: '400px', mx: 'auto'}} label='Beschrijving' />
                                       
                                        <Box sx={{mx: 'auto', width: '400px', display: 'flex', justifyContent: 'end'}}>
                                            <ButtonFull width='150px' text='Volgende' onClick={() => {}} type='submit'/>
                                        </Box>
                                </form>
                            : activeStep.value === 1 ?
                                <form id='form' onSubmit={(e) => {e.preventDefault(); activeStep.value += 1}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '100%', alignItems:'center'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto', textAlign: 'center' }}>
                                        Welke toegangsrechten hebben medewerkers met deze functie?
                                    </Typography>
                                    {/* Rights overview table */}
                                    <Grid container sx={{pl: '56px'}}>
                                        <Grid item sm></Grid>
                                        <Grid sm={2} item sx={{justifyContent: 'center', display: 'flex'}}>
                                            <Typography variant='labelLarge' sx={{color: 'grey'}}>
                                                Mag bekijken
                                            </Typography>
                                        </Grid>
                                        <Grid sm={2} item sx={{justifyContent: 'center', display: 'flex'}}>
                                            <Typography variant='labelLarge' sx={{color: 'grey'}}>
                                                Mag beheren
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Paper variant='outlined' sx={{display: 'flex', flexDirection: 'column', maxHeight: '30vh', overflowY: 'auto', width: '100%'}}>
                                    <Grid container direction={'column'}>
                                        {
                                            roles.value.map((role, index) => (
                                                <>
                                                <Grid item container key={index + '-titel'} sx={{pt: '16px', pl: '16px'}}>
                                                    <Grid sm item sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                                        <Typography variant='labelLarge'>
                                                            {role.Recht}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={2} item sx={{justifyItems: 'center', display: 'flex'}}>
                                                        <Checkbox key={selectedRoles.value.length} onChange={() => {changeRoles(role.ids[role.subs.indexOf('Bekijken')]) }} checked={selectedRoles.value.indexOf(role.ids[role.subs.indexOf('Bekijken')]) > -1 } size="small" sx={{mx: 'auto'}}/>
                                                    </Grid>
                                                    <Grid sm={2} item sx={{justifyItems: 'center', display: 'flex'}}>
                                                        <Checkbox key={selectedRoles.value.length} onChange={() => {changeRoles(role.ids[role.subs.indexOf('Beheren')]) }} checked={selectedRoles.value.indexOf(role.ids[role.subs.indexOf('Beheren')]) > -1 } size="small" sx={{mx: 'auto'}}/>
                                                    </Grid>
                                                </Grid>

                                                {
                                                index !== roles.value.length - 1 &&
                                                <Divider sx={{mt: '12px'}}/>
                                                }
                                                
                                                </>
                                            ))
                                        }
                                    </Grid>
                                    </Paper>
                                    <Box sx={{mx: 'auto', width: '100%', display: 'flex', justifyContent: 'end', gap: '16px'}}>
                                        <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}}/>
                                        <ButtonFull width='150px' text='Volgende' onClick={nextStep}/>
                                    </Box>
                                </form>
                            : activeStep.value === 2 ? 
                                <form onSubmit={(e) => {e.preventDefault(); activeStep.value += 1}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '19px', width: '80%', alignItems:'center', margin: 'auto'}}>
                                    <Typography variant='bodyLarge' sx={{mx: 'auto', textAlign: 'center'}}>
                                        Welke medewerkers wil je aan deze functie koppelen?
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        id="employee-autocomplete"
                                        options={employees.value}
                                        limitTags={2}
                                        value={selectedEmployees.value}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.Naam}
                                        onChange={(e, val) => {selectedEmployees.value = val}}
                                        renderOption={(props, option, { selected }) => {
                                            const { id, ...optionProps } = props;
                                            return (
                                            <li key={id} {...optionProps}>
                                                <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                />
                                                {option.Naam}
                                            </li>
                                            );
                                        }}
                                        style={{ width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Medewerkers" placeholder="Zoek op naam" />
                                        )}
                                    />
                                    <Box sx={{mx: 'auto', width: '100%', display: 'flex', justifyContent: 'end', gap: '16px'}}>
                                        <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}}/>
                                        <ButtonFull width='150px' text='Volgende' onClick={nextStep}/>
                                    </Box>
                                </form>
                            :
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '19px', width: '80%', alignItems:'center', m: 'auto'}}>
                                <Typography variant='bodyLarge' sx={{mx: 'auto', textAlign: 'center'}}>
                                    Wil je de functie {group_name.value} toevoegen en {selectedEmployees.value.length} {selectedEmployees.value.length === 1 ? 'medewerker' : 'medewerkers'} koppelen?
                                </Typography>
                                <Box sx={{mx: 'auto', width: '100%', display: 'flex', alignItems: 'center', gap: '16px', flexDirection: 'column'}}>
                                    <ButtonFull text='Functie toevoegen' onClick={createGroup} width='100%'/>
                                    <ButtonText text='Vorige' onClick={() => {activeStep.value -= 1}} margin="0"/>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default GroupAdd;