import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Autocomplete, Box,  Checkbox, Chip, Collapse, FormControlLabel,  InputAdornment, MenuItem,  Paper,  Radio,  RadioGroup,  Select,  Switch, TextField, Tooltip, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import Split from '../../components/layout/Split';
import ButtonBack from '../../components/buttons/ButtonBack';
import Card from '../../components/layout/Card';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useEffect } from 'react';
import { apiClient } from '../../functions/Login';
import Confirm from '../../components/modals/Confirm';
import dayjs, { Dayjs } from 'dayjs';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { AllergenOut, ComposedProductEdit, CompositionIn, ProductOptionCategoryNaamOut, ProductOptionCategoryOut, ProductOptionOut, ProductWeekdayIn, SalesProductEdit, SalesProductGroupNaamOut, VariantIn, VatOut } from '../../assets';
import { ConfigProvider, DatePicker, Upload, UploadFile } from 'antd';
import locale from 'antd/es/date-picker/locale/nl_NL';
import { UploadRequestError, UploadRequestOption } from "rc-upload/lib/interface";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VariantsToProduct from '../../components/products/VariantsToProduct';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import VariantsToComposedProduct from '../../components/products/VariantsToComposedProduct';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

interface variant {
    id?: string
    img_url: string
    option_names: (string | string[])[]
    option_ids: string[]
    price: number
    active: boolean
  }

interface composition {
    id?: string
    option_names: string[]
    composition_name: string
    variants: string[];
    amount: number
}

export default function ArticlesDetails() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const product_id = String(useParams().product_id);

    useSignals();
    // Define signal
    const openDeleteModal: Signal<boolean> = useSignal(false);
    const is_active: Signal<boolean> = useSignal(true);
    const product_name: Signal<string> = useSignal('');
    const product_legal_name: Signal<string> = useSignal('');
    const product_code: Signal<string> = useSignal('');
    const is_bread: Signal<boolean> = useSignal(false);

    const description: Signal<string> = useSignal('');
    const allergens: Signal<AllergenOut[]> = useSignal([]);
    const selectedAllergens: Signal<string[]> = useSignal([]);
    const img_urls: Signal<string[]> = useSignal([]);

    const groups: Signal<SalesProductGroupNaamOut[]> = useSignal([]);
    const selectedGroups: Signal<string[]> = useSignal([]);

    const price_incl: Signal<string> = useSignal('0,00');
    const price_excl: Signal<string> = useSignal('0,00');
    const cost_price: Signal<string> = useSignal('0,00');
    const vats: Signal<VatOut[]> = useSignal([]);
    const selectedVat: Signal<string> = useSignal('');

    const has_options: Signal<boolean> = useSignal(false);
    const option_groups: Signal<ProductOptionCategoryNaamOut[]> = useSignal([]);
    const selectedOptionGroups: Signal<ProductOptionCategoryOut[]> = useSignal([]);
    const variants: Signal<variant[]> = useSignal([]);
    const compositions: Signal<composition[]> = useSignal([]);

    const availability: Signal<{ [key: string]: boolean }> = useSignal({
        Maandag: false,
        Dinsdag: false,
        Woensdag: false,
        Donderdag: false,
        Vrijdag: false,
        Zaterdag: false,
        Zondag: false
    })
    const activeFrom: Signal<Dayjs | null> = useSignal(null);
    const activeTill: Signal<Dayjs | null> = useSignal(null);

    const fileList: Signal<UploadFile[]> = useSignal([]);
    const uploadLogo = (options: UploadRequestOption<any>) => {
        const { onSuccess, onError, file} = options;

        apiClient().userManagement.uploadLogoToS3UsersUploadLogoImagePost({file: file as Blob}).then((r) => {
            if (onSuccess) onSuccess('OK');
            img_urls.value = [...img_urls.value, r];
        }).catch((error: UploadRequestError) => {
            if (onError) onError(error, 'Er is iets fout gegaan, probeer het opnieuw');
        })
    }
    
    const changeOptionGroups = (val: ProductOptionCategoryNaamOut[]) => {
        if (selectedOptionGroups.value.length > val.length) {
            selectedOptionGroups.value = selectedOptionGroups.value.filter(s => val.map(v => v.id).includes(s.id));

            // Rebuild variants
            variants.value = [];
            createVariants();
        } else {
            apiClient().itemManagement.getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(val[val.length -1].id).then((r) => {
                selectedOptionGroups.value = [...selectedOptionGroups.value, {...r, product_options: r.product_options?.map((po) => {return {...po, image_type_docx: true}})}]
                createVariants()
            })
        }
    }

    const changeVariants = (option_id: string) => {
        variants.value = variants.value.filter(v => !v.option_ids.includes(option_id));
    }

    const createVariants = () => {
        // create new variants with the existing ones and the option configurations in the new group
        if (variants.value.length === 0) {
            selectedOptionGroups.value.forEach((group, i) => {
                if (variants.value.length > 0) {
                    let new_variants: variant[] = []
                    variants.value.forEach((variant, ii) => {
                        group.product_options?.forEach((option, ii) => {           
                            if (option.product_option_name && variants.value.some((v) => v.option_names.includes(option.product_option_name!))) {
                                return;
                            }    
                            if(!option.image_type_docx) {
                                return;
                            }    
                            new_variants = [...new_variants, {
                                img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                                option_names: option.product_option_name ? [...variant.option_names, option.product_option_name] : [],
                                option_ids: [...variant.option_ids, option.id],
                                price: option.price_factor ? variant.price * option.price_factor : variant.price,
                                active: true
                            }];
                        })
                    })
                    variants.value = new_variants;
                } else {
                    group.product_options?.forEach((option, ii) => {         
                        if(!option.image_type_docx) {
                            return;
                        }  
                        variants.value = [...variants.value, {
                            img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                            option_names: option.product_option_name ? [option.product_option_name] : [],
                            option_ids: [option.id],
                            price: option.price_factor ? Number.parseFloat(price_incl.value.replaceAll(',', '.')) * option.price_factor : Number.parseFloat(price_incl.value.replaceAll(',', '.')),
                            active: true
                        }];
                    })
                }
            })
        } else {
            let new_variants: variant[] = []
            variants.value.forEach((variant, i) => {
                selectedOptionGroups.value.forEach((group, i) => {
                    group.product_options?.forEach((option, ii) => {  
                        if (option.product_option_name && variants.value.some((v) => v.option_names.includes(option.product_option_name!))) {
                            return;
                        }        
                        if(!option.image_type_docx) {
                            return;
                        }
                        new_variants = [...new_variants, {
                            img_url:  img_urls.value.length > 0 ?  img_urls.value[0] : '',
                            option_names: option.product_option_name ? [...variant.option_names, option.product_option_name] : [],
                            option_ids: [...variant.option_ids, option.id],
                            price: option.price_factor ? variant.price * option.price_factor : variant.price,
                            active: true
                        }];
                    })
                })
            })
            variants.value = new_variants;
        }
    }

    const checkNumeric = (input: string) => {
        const decimalRegex = /^\d+\,?\d{0,2}$/;
        
        if (input.match(decimalRegex) || input === '') {
            return true
        } 
        return false
    }

    const saveProduct = () => {
        if (state.type === 'single') {
            const vars: VariantIn[] = Array.from(variants.value, (v) => {
                let newVariant = {
                    ...(v.id && {id: v.id}),
                    is_active_in_item: v.active,
                    variant_image: [v.img_url],
                    product_option_ids: v.option_ids,
                    sales_product_id: product_id
                } as VariantIn
                return newVariant
            })
            
            const weekdays = {
                monday_active: availability.value.Maandag,
                tuesday_active: availability.value.Dinsdag,
                wednesday_active: availability.value.Woensdag,
                thursday_active: availability.value.Donderdag,
                friday_active: availability.value.Vrijdag,
                saturday_active: availability.value.Zaterdag,
                sunday_active: availability.value.Zondag,
            } as ProductWeekdayIn

            const body = {
                sales_product_name: product_name.value,
                sales_product_legal_name: product_legal_name.value,
                sales_product_code: product_code.value,
                sales_product_description: description.value,
                sales_product_image: img_urls.value,
                sales_product_group_ids: selectedGroups.value,
                price_excl_vat: Number.parseFloat(price_excl.value.replaceAll(',', '.')),
                cost_price: Number.parseFloat(cost_price.value.replaceAll(',', '.')),
                vat_id: selectedVat.value,
                is_active: is_active.value,
                active_from_date: activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                active_to_date: activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                allergens: selectedAllergens.value,
                variants: vars,
                weekday: weekdays
            } as SalesProductEdit
            
           apiClient().itemManagement.editSalesProductItemsSalesProductsSalesProductIdPatch(product_id, body).then((r) => {
                navigate('/artikelen/overzicht');
           })
        } else {
            const comps: CompositionIn[] = Array.from(compositions.value, (c) => {
                let newComposition = {
                    ...(c.id && {id: c.id}),
                    composition_amount: c.amount,
                    variant_ids: c.variants,
                    name: c.composition_name
                } as CompositionIn
                return newComposition
            })
            
            const weekdays = {
                monday_active: availability.value.Maandag,
                tuesday_active: availability.value.Dinsdag,
                wednesday_active: availability.value.Woensdag,
                thursday_active: availability.value.Donderdag,
                friday_active: availability.value.Vrijdag,
                saturday_active: availability.value.Zaterdag,
                sunday_active: availability.value.Zondag,
            } as ProductWeekdayIn

            const body = {
                composed_product_name: product_name.value,
                composed_product_legal_name: product_legal_name.value,
                composed_product_code: product_code.value,
                composed_product_description: description.value,
                composed_product_image: img_urls.value,
                composed_product_group_ids: selectedGroups.value,
                price_excl_vat: Number.parseFloat(price_excl.value.replaceAll(',', '.')),
                cost_price: Number.parseFloat(cost_price.value.replaceAll(',', '.')),
                vat_id: selectedVat.value,
                is_active: is_active.value,
                active_from_date: activeFrom.value ? activeFrom.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                active_to_date: activeTill.value ? activeTill.value.utc().format('YYYY-MM-DD HH:mm:ss') : null,
                allergens: selectedAllergens.value,
                compositions: comps,
                weekday: weekdays
            } as ComposedProductEdit

            apiClient().itemManagement.editComposedProductItemsComposedProductsComposedProductIdPatch(product_id, body).then((r) => {
                navigate('/artikelen/overzicht');
            })
        }
        
    }

    useEffect(() => {
        if (selectedVat.value !== '') {
            let vat = vats.peek().find(v => v.id === selectedVat.value)!.vat_percentage / 100;

            if (price_incl.peek() !== '0,00') {
                price_excl.value = (Number.parseFloat(price_incl.peek().replaceAll(',', '.')) / (1 + vat)).toFixed(2).toString().replaceAll('.', ',');
            } else if (price_excl.peek() !== '0,00') {
                price_incl.value = (Number.parseFloat(price_excl.peek().replaceAll(',', '.')) * (1 + vat)).toFixed(2).toString().replaceAll('.', ',');
            }
        }
    }, [selectedVat.value])

    useEffect(() => {
        apiClient().itemManagement.getAllAllergensItemsAllergensGet().then((r) => {
            allergens.value = r.items;
        })
        apiClient().itemManagement.getAllSalesProductGroupsItemsSalesProductGroupsGet().then((r) => {
            groups.value = r.items;
        })
        apiClient().admin.getAllVatsAdminVatsGet().then((r) => {
            vats.value = r.items;
        })
        apiClient().itemManagement.getAllProductOptionCategoriesItemsProductOptionCategoriesGet().then((r) => {
            option_groups.value = r.items;
        })

        if (state.type === 'single') {
            apiClient().itemManagement.getSalesProductByIdItemsSalesProductsSalesProductIdGet(product_id).then((r) => {
                is_active.value = r.is_active ? r.is_active : false;
                product_name.value = r.sales_product_name ? r.sales_product_name : '';
                product_legal_name.value = r.sales_product_legal_name;
                product_code.value = r.sales_product_code;
                description.value = r.sales_product_description ? r.sales_product_description : '';
                selectedAllergens.value = r.allergens ? r.allergens.map(a => a.allergen_name) : [];
                selectedVat.value = r.vat_id;
                img_urls.value = r.sales_product_image ? r.sales_product_image : [];
                price_excl.value = r.price_excl_vat.toFixed(2).toString().replaceAll('.', ',');
                price_incl.value = r.price_incl_vat_rounded.toFixed(2).toString().replaceAll('.', ',');
                cost_price.value = r.cost_price ? r.cost_price.toFixed(2).toString().replaceAll('.', ',') : '0,00';
                selectedGroups.value = r.sales_product_groups ? r.sales_product_groups.map(g => g.id) : [];
                if (r.variants && r.variants.length > 0) {has_options.value = true}
                    availability.value = {
                        Maandag: r.weekday.monday_active,
                        Dinsdag: r.weekday.tuesday_active,
                        Woensdag: r.weekday.wednesday_active,
                        Donderdag: r.weekday.thursday_active,
                        Vrijdag: r.weekday.friday_active,
                        Zaterdag: r.weekday.saturday_active,
                        Zondag: r.weekday.sunday_active
                    }
                activeFrom.value = r.active_from_date ? dayjs.utc(r.active_from_date) : null;
                activeTill.value = r.active_to_date ? dayjs.utc(r.active_to_date ) : null;
                r.variants && r.variants.forEach((variant) => {
                    if (variant.deleted_at) return;
                    variants.value = [...variants.value, {
                        id: variant.id,
                        img_url: variant.variant_image ? variant.variant_image[0] : '',
                        option_names: variant.product_options?.map(o => o.product_option_name),
                        option_ids: variant.product_options?.map(o => o.id),
                        price: 0, //TODO
                        active: variant.is_active_in_item,
                    } as variant]

                    variant.product_options && variant.product_options.forEach((option) => {
                        if (option.categories) {
                            let index = selectedOptionGroups.value.map(s => s.id).indexOf(option.categories.id);
                            if (index !== -1) {
                                if (selectedOptionGroups.value.map(s => s.product_options ? s.product_options : []).some(arr => arr.map(a => a.id).includes(option.id))) {
                                    return;
                                } else {
                                    selectedOptionGroups.value = selectedOptionGroups.value.map((s, i) => i === index ? {...s, product_options: [...s.product_options!, {...option, image_type_docx: true}] } : {...s})
                                } 
                            } else {
                                selectedOptionGroups.value = [...selectedOptionGroups.value, {
                                    ...option.categories,
                                    product_options: [{...option, image_type_docx: true}]
                                } as ProductOptionCategoryOut]
                            }
                            apiClient().itemManagement.getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(option.categories.id).then((r) => {
                                selectedOptionGroups.value = selectedOptionGroups.value.map((sog, i) => i === selectedOptionGroups.value.map(s => s.id).indexOf(option.categories!.id) ? {...sog, 
                                    product_options: sog.product_options ? r.product_options ? [...sog.product_options, ...r.product_options.filter(po => !sog.product_options!.map(spo => spo.id).includes(po.id)).map((po) => {return {...po, image_type_docx: false}})] : sog.product_options : []
                                } : sog)
                            })
                        }
                        
                    })
                })
                if (r.sales_product_image) {
                    r.sales_product_image.forEach((img, i) => {
                        fileList.value = [{
                            uid: i.toString(),
                            name: `image-${i}.png`,
                            status: 'done',
                            url: img
                        }]
                    })
                   
                }
            })
        } else {
            apiClient().itemManagement.getComposedProductByIdItemsComposedProductsComposedProductIdGet(product_id).then((r) => {
                is_active.value = r.is_active ? r.is_active : false;
                product_name.value = r.composed_product_name ? r.composed_product_name : '';
                product_legal_name.value = r.composed_product_legal_name;
                product_code.value = r.composed_product_code;
                description.value = r.composed_product_description ? r.composed_product_description : '';
                selectedVat.value = r.vat_id;
                price_excl.value = r.price_excl_vat.toFixed(2).toString().replaceAll('.', ',');
                price_incl.value = r.price_incl_vat_rounded.toFixed(2).toString().replaceAll('.', ',');
                cost_price.value = r.cost_price ? r.cost_price.toFixed(2).toString().replaceAll('.', ',') : '0,00';
                selectedGroups.value = r.sales_product_groups ? r.sales_product_groups.map(g => g.id) : [];
                if (r.composed_product_image) {
                    r.composed_product_image.forEach((img, i) => {
                        fileList.value = [{
                            uid: i.toString(),
                            name: `image-${i}.png`,
                            status: 'done',
                            url: img
                        }]
                    })
                   
                }
                availability.value = {
                    Maandag: r.weekday.monday_active,
                    Dinsdag: r.weekday.tuesday_active,
                    Woensdag: r.weekday.wednesday_active,
                    Donderdag: r.weekday.thursday_active,
                    Vrijdag: r.weekday.friday_active,
                    Zaterdag: r.weekday.saturday_active,
                    Zondag: r.weekday.sunday_active
                }
                activeFrom.value = r.weekday.start_datetime ? dayjs(r.weekday.start_datetime) : null;
                activeTill.value = r.weekday.end_datetime ? dayjs(r.weekday.end_datetime) : null;
                r.compositions?.forEach((comp) => {
                    let set = new Set<string>();
                    comp.variants && comp.variants.forEach(v => v.product_options?.forEach(o => o.product_option_name && set.add(o.product_option_name)));

                    compositions.value = [...compositions.value, {
                        id: comp.id,
                        option_names: Array.from(set.values()),
                        composition_name: comp.name,
                        variants: comp.variants ? comp.variants.map(v => v.id) : [],
                        amount: comp.composition_amount
                    } as composition]
                })
            })
        }
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().itemManagement.deleteSalesProductGroupItemsSalesProductGroupsSalesProductGroupIdDelete(product_id).then(() => {navigate('/artikelen/groepen')})}}
            information_text='Door op bevestigen te drukken wordt dit artikel permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {product_name.value}
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Artikel verwijderen'/>
                <ButtonFull type='submit' form='product' onClick={() => {}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveProduct()}} id='product' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Split 
                    left={
                        <>
                            <Card 
                                maxHeight='100%'
                                title='Artikelnaam' 
                                children={
                                    <>
                                        <TextField required label="Artikelnaam / Fantasienaam" variant="outlined" value={product_name.value} onChange={(e) => {product_name.value = e.currentTarget.value}}/>
                                        {
                                                state.type === 'single' &&
                                            <>
                                                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                                    <Checkbox checked={is_bread.value} onChange={(e) => {is_bread.value = !is_bread.value}}/>
                                                    <Typography variant='bodyMedium'>
                                                        Dit artikel is een broodsoort.
                                                    </Typography>
                                                    <Tooltip title={'Geef dit gwn aan, boeie'}>
                                                        <InfoOutlinedIcon />
                                                    </Tooltip>
                                                </Box>
                                                <TextField required label="Wettelijke naam" variant="outlined" value={product_legal_name.value} onChange={(e) => {product_legal_name.value = e.currentTarget.value}}/>
                                            </>
                                        }
                                        
                                        <TextField required label="Artikelcode" variant="outlined" value={product_code.value} onChange={(e) => {product_code.value = e.currentTarget.value}}/>
                                    </>
                                }
                            />
                            <Card 
                                maxHeight='100%'
                                title='Artikel eigenschappen' 
                                children={
                                    <>
                                        <Typography variant='bodyLarge'>Deze eigenschappen komen terug op de artikelpagina van de geselecteerde webshops.</Typography>
                                        <TextField
                                            label='Omschrijving'
                                            value={description.value}
                                            onChange={(e) => {description.value = e.currentTarget.value}}
                                            multiline
                                            rows={4}
                                            inputProps={{ maxLength: 255 }}
                                            />
                                            {
                                                state.type === 'single' ?
                                                    <>
                                                        <Select
                                                            IconComponent={ExpandMoreOutlinedIcon}
                                                            displayEmpty
                                                            multiple
                                                            value={selectedAllergens.value}
                                                            MenuProps={{ style: {maxHeight: '300px'}}}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return (
                                                                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                                            Allergenen   
                                                                        </Typography> 
                                                                    )
                                                                }
                                                    
                                                                return selectedAllergens.value.join(', ');
                                                            }}
                                                            onChange={(e) => {selectedAllergens.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                                                            sx={{minWidth: '150px', width: '100%', maxHeight: '50px', borderRadius: '4px'}}
                                                        >
                                                            <MenuItem disabled value='' key=''>
                                                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                                    Allergenen   
                                                                </Typography> 
                                                            </MenuItem>
                                                            {
                                                                allergens.value.map((allergen) => 
                                                                    <MenuItem value={allergen.allergen_name} key={allergen.allergen_name} >
                                                                        <Checkbox checked={selectedAllergens.value.indexOf(allergen.allergen_name) > -1} />
                                                                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                                            {allergen.allergen_name}    
                                                                        </Typography> 
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                        <Typography variant='bodyLarge'>U bent zelf verantwoordelijk voor de juistheid en volledigheid van de allergenen en dieet informatie bij het product.</Typography>
                                                    </>
                                                :
                                                    <>
                                                        <Typography variant='bodyLarge'>Allergenen en dieeteigenschappen worden automatisch toegevoegd op basis van artikel samenstelling. </Typography>
                                                    </>

                                            }
                                            
                                    </>
                                }
                            />
                             <Card 
                                title='Media' 
                                children={
                                    <>
                                        <Upload
                                            customRequest={(e) => {uploadLogo(e)}}
                                            listType="picture-card"
                                            fileList={fileList.value.length > 0 ? fileList.value : undefined}
                                            key={fileList.value.length}
                                        >
                                            {fileList.value.length > 0 ? null : <ButtonOutlined clickable={false} Icon={FileUploadOutlinedIcon} text='uploaden' onClick={() => {}}/>}
                                        </Upload>
                                    </>
                                }
                            />
                        </>
                    }
                    right={
                        <>
                            <Card 
                                title='Status' 
                                children={
                                    <>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Typography variant='bodyLarge'>
                                                De groep is {is_active.value ? 'actief' : 'inactief'}, gebruik de knop rechts om deze te {is_active.value ? 'deactiveren' : 'activeren'}.
                                            </Typography>
                                            <Switch onChange={() => {is_active.value = !is_active.value}} checked={is_active.value}/>
                                        </Box>
                                        <Typography variant='labelLarge' sx={{fontWeight: '700'}}>
                                            Periode actief
                                        </Typography>
                                        <Box sx={{display: 'flex', gap: '16px'}}>
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                    DatePicker: {
                                                        activeBorderColor: '#28555e',
                                                        cellRangeBorderColor: '#28555e',
                                                        hoverBorderColor: '#28555e',
                                                        colorPrimary: '#28555e',
                                                        colorBorder: '#c4c4c4',
                                                        borderRadius: 4,
                                                        colorTextPlaceholder: '#000',
                                                        activeShadow: 'none',
                                                        presetsWidth: 360,
                                                        zIndexPopup: 2000,
                                                        zIndexPopupBase: 2000,
                                                        zIndexBase: 2000
                                                    },
                                                    },
                                                }}
                                            >
                                                <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief van' onChange={(e) => {activeFrom.value = e}} value={activeFrom.value} format={'DD MMMM'} locale={locale} />
                                                <DatePicker required={(activeFrom.value != null || activeTill.value != null)} style={{width: '100%', height: '56px'}} placeholder='Actief tot' onChange={(e) => {activeTill.value = e}} value={activeTill.value} format={'DD MMMM'} locale={locale} />
                                            </ConfigProvider>
                                        </Box>
                                       
                                        
                                    </>
                                }
                            />
                            <Card 
                                title='Artikelgroepen' 
                                children={
                                    <Select
                                        IconComponent={ExpandMoreOutlinedIcon}
                                        displayEmpty
                                        multiple
                                        required
                                        value={selectedGroups.value}
                                        MenuProps={{ style: {maxHeight: '300px'}}}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                        Artikelgroep   
                                                    </Typography> 
                                                )
                                            }
                                
                                            return groups.value.filter(group => selected.includes(group.id)).map(g => g.sales_product_group_name).join(', ');
                                        }}
                                        onChange={(e) => {selectedGroups.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                                        sx={{minWidth: '150px', width: '100%', maxHeight: '50px', borderRadius: '12px'}}
                                    >
                                        <MenuItem disabled value='' key=''>
                                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                Artikelgroep   
                                            </Typography> 
                                        </MenuItem>
                                        {
                                            groups.value.map((group) => 
                                                <MenuItem value={group.id} key={group.id} >
                                                    <Checkbox checked={selectedGroups.value.indexOf(group.id) > -1} />
                                                    <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                                        {group.sales_product_group_name}    
                                                    </Typography> 
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                }
                            />
                             <Card 
                                title='Beschikbaarheid' 
                                children={
                                    <>
                                    <Typography variant='bodyLarge'>Op deze dagen is het artikel beschikbaar voor levering/afhalen.</Typography>
                                    <Box>
                                        <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                            <Checkbox indeterminate={Object.values(availability.value).some(Boolean) && !Object.values(availability.value).every(Boolean)} checked={Object.values(availability.value).every(Boolean)} onChange={(e, checked) => {if (Object.values(availability.value).every((v) => v === true)) {availability.value = {
                                                Maandag: false,
                                                Dinsdag: false,
                                                Woensdag: false,
                                                Donderdag: false,
                                                Vrijdag: false,
                                                Zaterdag: false,
                                                Zondag: false
                                            }} else {
                                                availability.value = {
                                                    Maandag: true,
                                                    Dinsdag: true,
                                                    Woensdag: true,
                                                    Donderdag: true,
                                                    Vrijdag: true,
                                                    Zaterdag: true,
                                                    Zondag: true
                                                }
                                            }}}/>
                                            <Typography variant='bodyMedium'>
                                                Selecteer alles
                                            </Typography>
                                        </Box>
                                        {
                                            Object.keys(availability.value).map((day: string, i) => 
                                                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                                    <Checkbox checked={availability.value[day]} onChange={(e, checked) => {availability.value = {...availability.value, [day]: !availability.value[day]}}}/>
                                                    <Typography variant='bodyMedium'>
                                                        {day}
                                                    </Typography>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                    </>
                                }
                            />
                        </>
                    }
                />
                <Card 
                    title='Prijs' 
                    children={
                      <>
                        <Typography variant='bodyLarge'>Stel hieronder de basisprijs van het artikel in. Dit is de stuksprijs zonder opties.</Typography>
                        <Box sx={{display: 'flex', gap: '32px'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (incl. btw)" variant="outlined" value={price_incl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_incl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_excl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_excl.value = (Number.parseFloat(price_incl.value.replaceAll(',', '.')) / (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_incl.value = e.currentTarget.value; }}/>
                                    <Typography variant="labelLarge" sx={{fontWeight: '700'}}>
                                        of
                                    </Typography>
                                    <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required={price_incl.value === '0,00' && price_excl.value === '0,00'} label="Prijs (excl. btw)" variant="outlined" value={price_excl.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0 : Number.parseFloat(e.currentTarget.value.replace(',', '.')); price_excl.value = temp.toFixed(2).toString().replace('.', ','); if (selectedVat.value === '') price_incl.value = '0,00'; else {let vat = vats.value.find(v => v.id === selectedVat.value)!.vat_percentage / 100;  price_incl.value = (Number.parseFloat(price_excl.value.replaceAll(',', '.')) * (1 + vat)).toFixed(2).toString().replaceAll('.', ','); }}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) price_excl.value = e.currentTarget.value; }}/>
                                </Box>
                                <TextField InputProps={{startAdornment: (<InputAdornment position="start"><EuroSymbolOutlinedIcon /></InputAdornment>)}} required label="Kostprijs (incl. btw)" variant="outlined" value={cost_price.value} onBlur={(e) => {let temp = e.currentTarget.value === '' ? 0  : Number.parseFloat(e.currentTarget.value.replace(',', '.')); cost_price.value = temp.toFixed(2).toString().replace('.', ',')}} onChange={(e) => {if (checkNumeric(e.currentTarget.value)) cost_price.value = e.currentTarget.value;}}/>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Typography variant='titleMedium' sx={{fontWeight: '700'}}>BTW-tarief bij dit artikel</Typography>
                                <RadioGroup
                                    value={selectedVat.value}
                                    name="vat-picker"
                                    onChange={(e) => {selectedVat.value = e.currentTarget.value}}
                                >
                                    {vats.value.map((vat) =>
                                            <FormControlLabel value={vat.id} control={<Radio required={selectedVat.value === ''} />} label={vat.vat_category + ' - ' + vat.vat_description} />
                                    )}
                                </RadioGroup>
                            </Box>
                        </Box>
                      </>
                    }
                />
                {
                    state.type === 'single' ? 
<Card 
                    title='Artikel opties & varianten' 
                    children={
                        <>
                             <Box sx={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                                <Checkbox checked={has_options.value} onChange={(e) => {has_options.value = !has_options.value}}/>
                                <Typography variant='bodyMedium'>
                                    Dit artikel heeft opties
                                </Typography>
                            </Box>
                            <Collapse in={has_options.value} >
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                    <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Optiegroepen</Typography>
                                    <Typography variant='bodyLarge'>Kies eerst de optiegroepen.</Typography>
                                    <Autocomplete
                                        multiple
                                        id="option-groups-autocomplete"
                                        filterSelectedOptions
                                        options={option_groups.value.sort((a, b) => a.category_name.localeCompare(b.category_name))}
                                        getOptionLabel={(option) => option.category_name}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={selectedOptionGroups.value as ProductOptionCategoryNaamOut[]}
                                        onChange={(e, val) => {changeOptionGroups(val)}}
                                        renderTags={(val, props) => {
                                        return val.map((option, index) => <Chip {...props({index})} label={option.category_name} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>) 
                                        }}
                                        renderOption={(props, option, { selected }) => {
                                            const { id, ...optionProps } = props;
                                            return (
                                            <li key={id} {...optionProps} style={{gap: '8px'}}>
                                                {option.category_name}
                                            </li>
                                            );
                                        }}
                                        style={{ width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Optiegroepen" placeholder='Zoek op naam' />
                                        )}
                                    />
                                    <Collapse in={selectedOptionGroups.value.length > 0} >
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Opties</Typography>
                                            <Typography variant='bodyLarge'>Verwijder eventueel opties die niet van toepassing zijn op dit artikel.</Typography>
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                            {
                                                selectedOptionGroups.value.map((group, groupIndex) => 
                                                    <Paper key={group.id} variant='outlined' sx={{display: 'flex', gap: '8px', alignItems: 'center', p: '16px', borderRadius: '8px', border: '1px solid #D5D8DC', width: '100%'}}>
                                                        <Typography variant='bodyLarge' sx={{mr: 'auto'}}>{group.category_name}</Typography>
                                                        {
                                                            group.product_options && group.product_options.map((option, optionIndex) => 
                                                                option.image_type_docx ?
                                                                <Chip label={option.product_option_name} onDelete={() => {if (group.product_options!.filter((po, i) => i !== optionIndex).every(po => po.image_type_docx === false)) {selectedOptionGroups.value = selectedOptionGroups.value.filter((_, i) => i !== groupIndex ); variants.value = []; createVariants()} else  {selectedOptionGroups.value = selectedOptionGroups.value.map((o, i) => i === groupIndex ? {...o, product_options: group.product_options?.map((op, i) => i === optionIndex ? {...op, image_type_docx: false} : op)} : {...o})} changeVariants(option.id)}} sx={{backgroundColor: '#D18C5C15', color: '#D18C5C', border: 'solid 1px #D18C5C', '& .MuiChip-deleteIcon': {color: '#D18C5C', "&:hover": {color: '#D18C5CBB'}}}}/>
                                                                :
                                                                <Chip label={option.product_option_name} deleteIcon={<ControlPointOutlinedIcon />} onDelete={() => {selectedOptionGroups.value = selectedOptionGroups.value.map((o, i) => i === groupIndex ? {...o, product_options: group.product_options?.map((op, i) => i === optionIndex ? {...op, image_type_docx: true} : op)} : o); variants.value = []; createVariants()}} sx={{backgroundColor: '#c4c4c415', color: '#c4c4c4', border: 'solid 1px #c4c4c4', '& .MuiChip-deleteIcon': {color: '#c4c4c4', "&:hover": {color: '#c4c4c4BB'}}}}/>
                                                            )
                                                        }
                                                    </Paper>
                                                )
                                                
                                            }
                                            </Box>
                                            <Typography variant='titleMedium' sx={{fontWeight: '700'}}>Varianten</Typography>
                                            <Typography variant='bodyLarge'>Deactiveer varianten die niet van toepassing zijn voor dit artikel.</Typography>
                                            <VariantsToProduct variants={variants} />
                                        </Box>
                                    </Collapse>
                                </Box>
                            </Collapse>
                        </>
                    }
                /> 
                :
                <Card 
                    title='Artikelen in samenstelling' 
                    children={
                        <>
                            <VariantsToComposedProduct compositions={compositions}/>
                        </>
                    }
                />
                }
                
            </form>
        </Box>
      </ThemeProvider>
    );
  }