import { Box, Divider, Link, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { useEffect, useRef } from "react";
import ButtonFull from "../../../components/buttons/ButtonFull";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { apiClient } from "../../../functions/Login";

interface SecondStepProps {
    setStep: any
    steps: Array<string>
    activeStep: Signal<number>
    email: Signal<string>
    func: () => Promise<void>
  }
  
const SecondStep: React.FC<SecondStepProps> = ({ setStep, steps, activeStep, email, func }) => {
    let firstRef = useRef<HTMLElement | null>(null)
    let secondRef = useRef<HTMLElement | null>(null)
    let thirdRef = useRef<HTMLElement | null>(null)
    let fourthRef = useRef<HTMLElement | null>(null)
    let fifthRef = useRef<HTMLElement | null>(null)
    let sixthRef = useRef<HTMLElement | null>(null)

    useSignals();
    const firstNumber: Signal<string> = useSignal('');
    const secondNumber: Signal<string> = useSignal('');
    const thirdNumber: Signal<string> = useSignal('');
    const fourthNumber: Signal<string> = useSignal('');
    const fifthNumber: Signal<string> = useSignal('');
    const sixthNumber: Signal<string> = useSignal('');

    const wrongCode: Signal<boolean> = useSignal(false);
    const loading: Signal<boolean> = useSignal(false);
    const isCorrect: Signal<boolean> = useSignal(false);
    const infoText: Signal<string> = useSignal('');

    const checkCode = async() => {
        loading.value = true
        apiClient().admin.adminVerifyEmailCodeAdminVerifyEmailCodePost({email: email.value, code: firstNumber.value + secondNumber.value + thirdNumber.value + fourthNumber.value + fifthNumber.value + sixthNumber.value}).then((r) => {
            if ( r.verified) {
                infoText.value = 'Jouw verificatiecode is goedgekeurd!';
                setTimeout(() => {
                    infoText.value = '...nog even geduld, we zijn jouw omgeving aan het opzetten';
                  }, 2000);
                func().then(() => {
                    activeStep.value = 2;
                })
            } else {
                wrongCode.value = true
                loading.value = false
            }
        }).catch(() => {
            loading.value = false
        }) 
    }

    useEffect(() => {
        if (wrongCode) {
            wrongCode.value = false
        }
    }, [firstNumber.value, secondNumber.value, thirdNumber.value, fourthNumber.value, fifthNumber.value, sixthNumber.value])


    return (
        <Box sx={{display: 'flex', height: '75%', width: '75%'}}>
            {/* Left side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '32px', width: '70%', backgroundColor: '#fff', height: '100%', borderRadius: '16px 0 0 16px'}}>
                <Stepper activeStep={activeStep.value}>
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step sx={{cursor: activeStep.value > index ? 'pointer' : ''}} onClick={(e) => {if (activeStep.value > index) setStep(e, index)}} key={label} {...stepProps}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Typography variant='headlineMedium'>
                    Vul de verificatiecode in
                </Typography>
                <Typography variant='bodyLarge'>
                    We hebben je een e-mail met verificatiecode gestuurd naar <Typography variant='bodyLarge' sx={{fontWeight: '700'}}>{email.value}</Typography>. Vul deze code hieronder in. 
                </Typography>
                <Typography variant='bodyLarge'>
                    Heb je geen e-mail ontvangen? Controleer je spamfolder of <Link onClick={() => {apiClient().admin.adminSendVerificationEmailAdminSendVerificationEmailEmailGet(email.value)}} sx={{cursor: 'pointer'}}>klik hier</Link> om de e-mail opnieuw te verzenden.
                </Typography>
                <Box sx={{display: 'flex', gap: '4px', alignItems: 'center', width: 'fit-content'}}>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={firstRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {firstNumber.value = e.currentTarget.value; if (e.currentTarget.value.length > 0) secondRef.current?.focus()}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={firstNumber.value} autoFocus sx={{width: '32px'}}/>
                    </Box>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={secondRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {secondNumber.value = e.currentTarget.value; if (e.currentTarget.value.length > 0) thirdRef.current?.focus()}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={secondNumber.value} sx={{width: '32px'}}/>
                    </Box>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={thirdRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {thirdNumber.value = e.currentTarget.value; if (e.currentTarget.value.length > 0) fourthRef.current?.focus()}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={thirdNumber.value} sx={{width: '32px'}}/>
                    </Box>
                    <Divider sx={{width: '20px', mx: '4px'}}/>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={fourthRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {fourthNumber.value = e.currentTarget.value; if (e.currentTarget.value.length > 0) fifthRef.current?.focus()}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={fourthNumber.value} sx={{width: '32px'}}/>
                    </Box>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={fifthRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {fifthNumber.value = e.currentTarget.value; if (e.currentTarget.value.length > 0) sixthRef.current?.focus()}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={fifthNumber.value} sx={{width: '32px'}}/>
                    </Box>
                    <Box sx={{display: 'flex', width: '64px', height: '64px', borderRadius: '8px', border: `solid 1px ${wrongCode.value ? 'red' : isCorrect.value ? 'green' : '#D5D8DC'}`, justifyContent: 'center', alignItems: 'center'}}>
                        <TextField disabled={loading.value} inputRef={sixthRef} variant='standard' onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/) || e.currentTarget.value.length === 0) {sixthNumber.value = e.currentTarget.value}}} inputProps={{ maxLength: 1, style: { textAlign: 'center' }}} value={sixthNumber.value}sx={{width: '32px'}}/>
                    </Box>
                </Box>
                <Typography variant='bodySmall' sx={{color: 'red', display: wrongCode.value ? 'flex' : 'none'}}>
                    De ingevulde code is onjuist, controleer deze nogmaals
                </Typography>
                <ButtonFull loading={loading.value} width='436px' text='Volgende' onClick={(e: React.FormEvent<HTMLFormElement>) => {checkCode()}}/>
                <Typography variant='titleMedium' sx={{display: loading.value ? 'flex' : 'none'}}>
                   {infoText.value}
                </Typography>
            </Box>

            {/* Right side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '64px', width: '30%', backgroundColor: 'primary.main', height: '100%', alignItems: 'start', justifyContent: 'center', borderRadius: '0 16px 16px 0'}}>
                <Typography variant='headlineLarge' sx={{fontWeight: '700', color: '#fff'}}>
                    All-in tarief
                </Typography>
                <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Wij vragen een <Typography variant='bodyLarge' sx={{fontWeight: '700', color: '#fff'}}>omzetfee van 6%</Typography>. Je betaalt pas als je iets verkoopt, zo creëeren wij een win-win situatie!
                </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Webshop in jouw huisstijl
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Drive-thru mobiele applicatie (inclusief bestellen tijdens het evenement)
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Drive-thru desk applicatie voor verzamelen en afmelden van bestellingen
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Dashboard met overzicht van bestellingen per tijdslot en details van bestellingen
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <CheckOutlinedIcon sx={{color: '#fff'}}/>
                    <Typography variant='bodyLarge' sx={{color: '#fff'}}>
                    Artikelen beheren en in één overzicht zien hoeveel artikelen jij moet produceren
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default SecondStep;