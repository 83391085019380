/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_tenant_auth_tenant_post } from '../models/Body_create_tenant_auth_tenant_post';
import type { Body_create_tenant_v1_auth_tenant_post } from '../models/Body_create_tenant_v1_auth_tenant_post';
import type { Body_login_auth_token_post } from '../models/Body_login_auth_token_post';
import type { Body_login_v1_auth_token_post } from '../models/Body_login_v1_auth_token_post';
import type { Body_signup_customer_invite_auth_signup_customer_invite_post } from '../models/Body_signup_customer_invite_auth_signup_customer_invite_post';
import type { Body_signup_customer_invite_v1_auth_signup_customer_invite_post } from '../models/Body_signup_customer_invite_v1_auth_signup_customer_invite_post';
import type { Body_signup_employee_invite_auth_signup_employee_invite_post } from '../models/Body_signup_employee_invite_auth_signup_employee_invite_post';
import type { Body_signup_employee_invite_v1_auth_signup_employee_invite_post } from '../models/Body_signup_employee_invite_v1_auth_signup_employee_invite_post';
import type { FirstTimeLogin } from '../models/FirstTimeLogin';
import type { FormAdvancedIn } from '../models/FormAdvancedIn';
import type { FormSimpleIn } from '../models/FormSimpleIn';
import type { HostOut } from '../models/HostOut';
import type { IdpInfoOut } from '../models/IdpInfoOut';
import type { TenantEdit } from '../models/TenantEdit';
import type { TenantOut } from '../models/TenantOut';
import type { Token } from '../models/Token';
import type { UserIn } from '../models/UserIn';
import type { UserOut } from '../models/UserOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthenticationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get User By Id
     * Get user by id
     * @param userId
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUserByIdAuthUsersUserIdGet(
        userId: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User By Email
     * Get user by email
     * @param userEmail
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUserByEmailAuthUsersEmailUserEmailGet(
        userEmail: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/users_email/{user_email}',
            path: {
                'user_email': userEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param formData
     * @returns Token Successful Response
     * @throws ApiError
     */
    public loginAuthTokenPost(
        formData: Body_login_auth_token_post,
    ): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Host
     * @param email
     * @returns HostOut Successful Response
     * @throws ApiError
     */
    public hostAuthHostPost(
        email: string,
    ): CancelablePromise<HostOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/host',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sync User
     * Endpoint for cognito syncing, assuming non business customer
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public syncUserAuthSyncUserPost(
        requestBody: UserIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/sync_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Signup Customer Invite
     * @param requestBody
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public signupCustomerInviteAuthSignupCustomerInvitePost(
        requestBody: Body_signup_customer_invite_auth_signup_customer_invite_post,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signup_customer_invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Signup Employee Invite
     * @param requestBody
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public signupEmployeeInviteAuthSignupEmployeeInvitePost(
        requestBody: Body_signup_employee_invite_auth_signup_employee_invite_post,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signup_employee_invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * First Time Login
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public firstTimeLoginAuthFirstTimeLoginPost(
        requestBody: FirstTimeLogin,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/first_time_login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Tenant
     * Create tenant for tenant company
     * @param requestBody
     * @returns TenantOut Successful Response
     * @throws ApiError
     */
    public createTenantAuthTenantPost(
        requestBody: Body_create_tenant_auth_tenant_post,
    ): CancelablePromise<TenantOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/tenant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Tenant
     * Update tenant host, should cause logout
     * @param tenantId
     * @param requestBody
     * @returns TenantOut Successful Response
     * @throws ApiError
     */
    public updateTenantAuthTenantTenantIdPatch(
        tenantId: string,
        requestBody: TenantEdit,
    ): CancelablePromise<TenantOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/auth/tenant/{tenant_id}',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tenant
     * @param tenantId
     * @returns void
     * @throws ApiError
     */
    public deleteTenantAuthTenantTenantIdDelete(
        tenantId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/auth/tenant/{tenant_id}',
            path: {
                'tenant_id': tenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Me
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public meAuthMeGet(): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/me',
        });
    }

    /**
     * Form Simple
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public formSimpleAuthFormSimplePost(
        requestBody: FormSimpleIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/form/simple',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Form Advanced
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public formAdvancedAuthFormAdvancedPost(
        requestBody: FormAdvancedIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/form/advanced',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Idp Info
     * Get idp info
     * @returns IdpInfoOut Successful Response
     * @throws ApiError
     */
    public getIdpInfoAuthIdpGet(): CancelablePromise<IdpInfoOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/idp',
        });
    }

    /**
     * Get User By Id
     * Get user by id
     * @param userId
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUserByIdV1AuthUsersUserIdGet(
        userId: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User By Email
     * Get user by email
     * @param userEmail
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public getUserByEmailV1AuthUsersEmailUserEmailGet(
        userEmail: string,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/users_email/{user_email}',
            path: {
                'user_email': userEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param formData
     * @returns Token Successful Response
     * @throws ApiError
     */
    public loginV1AuthTokenPost(
        formData: Body_login_v1_auth_token_post,
    ): CancelablePromise<Token> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Host
     * @param email
     * @returns HostOut Successful Response
     * @throws ApiError
     */
    public hostV1AuthHostPost(
        email: string,
    ): CancelablePromise<HostOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/host',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sync User
     * Endpoint for cognito syncing, assuming non business customer
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public syncUserV1AuthSyncUserPost(
        requestBody: UserIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/sync_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Signup Customer Invite
     * @param requestBody
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public signupCustomerInviteV1AuthSignupCustomerInvitePost(
        requestBody: Body_signup_customer_invite_v1_auth_signup_customer_invite_post,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/signup_customer_invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Signup Employee Invite
     * @param requestBody
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public signupEmployeeInviteV1AuthSignupEmployeeInvitePost(
        requestBody: Body_signup_employee_invite_v1_auth_signup_employee_invite_post,
    ): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/signup_employee_invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * First Time Login
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public firstTimeLoginV1AuthFirstTimeLoginPost(
        requestBody: FirstTimeLogin,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/first_time_login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Tenant
     * Create tenant for tenant company
     * @param requestBody
     * @returns TenantOut Successful Response
     * @throws ApiError
     */
    public createTenantV1AuthTenantPost(
        requestBody: Body_create_tenant_v1_auth_tenant_post,
    ): CancelablePromise<TenantOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/tenant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Tenant
     * Update tenant host, should cause logout
     * @param tenantId
     * @param requestBody
     * @returns TenantOut Successful Response
     * @throws ApiError
     */
    public updateTenantV1AuthTenantTenantIdPatch(
        tenantId: string,
        requestBody: TenantEdit,
    ): CancelablePromise<TenantOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/auth/tenant/{tenant_id}',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tenant
     * @param tenantId
     * @returns void
     * @throws ApiError
     */
    public deleteTenantV1AuthTenantTenantIdDelete(
        tenantId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/auth/tenant/{tenant_id}',
            path: {
                'tenant_id': tenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Me
     * @returns UserOut Successful Response
     * @throws ApiError
     */
    public meV1AuthMeGet(): CancelablePromise<UserOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/me',
        });
    }

    /**
     * Form Simple
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public formSimpleV1AuthFormSimplePost(
        requestBody: FormSimpleIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/form/simple',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Form Advanced
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public formAdvancedV1AuthFormAdvancedPost(
        requestBody: FormAdvancedIn,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/form/advanced',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Idp Info
     * Get idp info
     * @returns IdpInfoOut Successful Response
     * @throws ApiError
     */
    public getIdpInfoV1AuthIdpGet(): CancelablePromise<IdpInfoOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/idp',
        });
    }

}
