import { Hub } from "aws-amplify/utils";
import { ApiClient } from "../assets";
import { createSubDomainURL } from "./subDomainURL";
import { fetchAuthSession } from 'aws-amplify/auth';

let session: any;

Hub.listen('auth', async ({ payload }) => {
    switch (payload.event) {
        case 'signedIn':
        session = await fetchAuthSession().catch();
        localStorage.setItem('token', session.tokens.accessToken.toString())
        break;
    }
});

if (window.location.hostname.split('.').length >= 3 && window.location.hostname.split('.')[0] !== 'www') {
    localStorage.setItem('host', window.location.hostname.split('.')[0]);
} else {
    localStorage.setItem('host', 'admin');
}
if (localStorage.getItem('host') === 'loaf-it' || localStorage.getItem('host') === 'www') {
    localStorage.setItem('host', 'admin');
}

export const apiClient = () => new ApiClient({
    BASE: createSubDomainURL(process.env.REACT_APP_BACK_END as string, localStorage.getItem('host') as string),
    TOKEN: localStorage.getItem('token') as string,
});
