/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_subsidiary_users_create_subsidiary_post } from '../models/Body_create_subsidiary_users_create_subsidiary_post';
import type { Body_create_subsidiary_v1_users_create_subsidiary_post } from '../models/Body_create_subsidiary_v1_users_create_subsidiary_post';
import type { Body_get_customers_filtered_users_customers_post } from '../models/Body_get_customers_filtered_users_customers_post';
import type { Body_get_customers_filtered_v1_users_customers_post } from '../models/Body_get_customers_filtered_v1_users_customers_post';
import type { Body_get_employees_filtered_users_employees_post } from '../models/Body_get_employees_filtered_users_employees_post';
import type { Body_get_employees_filtered_v1_users_employees_post } from '../models/Body_get_employees_filtered_v1_users_employees_post';
import type { Body_get_subsidiaries_filtered_users_subsidiaries_post } from '../models/Body_get_subsidiaries_filtered_users_subsidiaries_post';
import type { Body_get_subsidiaries_filtered_v1_users_subsidiaries_post } from '../models/Body_get_subsidiaries_filtered_v1_users_subsidiaries_post';
import type { Body_update_subsidiary_users_subsidiaries__company_id__patch } from '../models/Body_update_subsidiary_users_subsidiaries__company_id__patch';
import type { Body_update_subsidiary_v1_users_subsidiaries__company_id__patch } from '../models/Body_update_subsidiary_v1_users_subsidiaries__company_id__patch';
import type { Body_upload_logo_to_s3_users_upload_logo_image_post } from '../models/Body_upload_logo_to_s3_users_upload_logo_image_post';
import type { Body_upload_logo_to_s3_v1_users_upload_logo_image_post } from '../models/Body_upload_logo_to_s3_v1_users_upload_logo_image_post';
import type { CompanyOut } from '../models/CompanyOut';
import type { CustomerMetricOut } from '../models/CustomerMetricOut';
import type { CustomerOut } from '../models/CustomerOut';
import type { EmployeeOut } from '../models/EmployeeOut';
import type { KlantDetailEdit } from '../models/KlantDetailEdit';
import type { LocationCategoryOutList } from '../models/LocationCategoryOutList';
import type { LocationNaamOutList } from '../models/LocationNaamOutList';
import type { MedewerkerDetailEdit } from '../models/MedewerkerDetailEdit';
import type { MedewerkerNaamOutList } from '../models/MedewerkerNaamOutList';
import type { MollieOrganizationOut } from '../models/MollieOrganizationOut';
import type { Page_BestelAccountOut_ } from '../models/Page_BestelAccountOut_';
import type { Page_CustomerDetailOrderOut_ } from '../models/Page_CustomerDetailOrderOut_';
import type { Page_KlantenOverzichtOut_ } from '../models/Page_KlantenOverzichtOut_';
import type { Page_LocationOverviewOut_ } from '../models/Page_LocationOverviewOut_';
import type { Page_MedewerkerOverzichtOut_ } from '../models/Page_MedewerkerOverzichtOut_';
import type { SubsidiaryOut } from '../models/SubsidiaryOut';
import type { SubsidiaryOverviewOut } from '../models/SubsidiaryOverviewOut';
import type { TenantStyleEdit } from '../models/TenantStyleEdit';
import type { TenantStyleOut } from '../models/TenantStyleOut';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserManagementService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Ultimate Company Location
     * Get the ultimate company
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyLocationUsersUltimateCompanyGet(): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/ultimate_company',
        });
    }

    /**
     * Get Ultimate Company Payment Organization
     * Get the ultimate company mollie account connection info
     * @param dryRun
     * @returns MollieOrganizationOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyPaymentOrganizationUsersUltimateCompanyMollieGet(
        dryRun?: (boolean | null),
    ): CancelablePromise<MollieOrganizationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/ultimate_company_mollie',
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subcompany Customers By Company
     * Get subcompany customers by company id (Bestelaccounts)
     * @param companyId
     * @param page Page number
     * @param size Page size
     * @returns Page_BestelAccountOut_ Successful Response
     * @throws ApiError
     */
    public getSubcompanyCustomersByCompanyUsersSubcompanyCustomersCompanyIdGet(
        companyId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_BestelAccountOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/subcompany_customers/{company_id}',
            path: {
                'company_id': companyId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Subsidiaries
     * Get all subsidiaries
     * @returns LocationNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllSubsidiariesUsersSubsidiariesGet(): CancelablePromise<LocationNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/subsidiaries',
        });
    }

    /**
     * Get Subsidiaries Filtered
     * Get all subsidiaries
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_LocationOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSubsidiariesFilteredUsersSubsidiariesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_subsidiaries_filtered_users_subsidiaries_post,
    ): CancelablePromise<Page_LocationOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/subsidiaries',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subsidiary By Company Id
     * Get all subsidiaries by company id
     * @param companyId
     * @returns SubsidiaryOverviewOut Successful Response
     * @throws ApiError
     */
    public getSubsidiaryByCompanyIdUsersSubsidiariesCompanyIdGet(
        companyId: string,
    ): CancelablePromise<SubsidiaryOverviewOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/subsidiaries/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Subsidiary
     * Update an existing subsidiary
     * @param companyId
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public updateSubsidiaryUsersSubsidiariesCompanyIdPatch(
        companyId: string,
        requestBody: Body_update_subsidiary_users_subsidiaries__company_id__patch,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/subsidiaries/{company_id}',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Subsidiary
     * Create a new subsidiary
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public createSubsidiaryUsersCreateSubsidiaryPost(
        requestBody: Body_create_subsidiary_users_create_subsidiary_post,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/create_subsidiary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Subsidiary
     * Delete a subsidiary
     * @param companyId
     * @returns void
     * @throws ApiError
     */
    public deleteSubsidiaryUsersSubsidiaryCompanyIdDelete(
        companyId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/subsidiary/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employees Filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_MedewerkerOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getEmployeesFilteredUsersEmployeesPost(
        search?: (string | null),
        sortingField?: 'Naam' | 'Functie' | 'Emailadres' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_employees_filtered_users_employees_post,
    ): CancelablePromise<Page_MedewerkerOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/employees',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Employees
     * @returns MedewerkerNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllEmployeesUsersEmployeeNamesGet(): CancelablePromise<MedewerkerNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/employee_names',
        });
    }

    /**
     * Get Employee By Id
     * Get an employee by ID
     * @param employeeId
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public getEmployeeByIdUsersEmployeesEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee
     * Edit an employee
     * @param employeeId
     * @param requestBody
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public updateEmployeeUsersEmployeesEmployeeIdPatch(
        employeeId: string,
        requestBody: MedewerkerDetailEdit,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Employee
     * Delete an employee
     * @param employeeId
     * @returns void
     * @throws ApiError
     */
    public deleteEmployeeUsersEmployeesEmployeeIdDelete(
        employeeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customers Filtered
     * Get all customers filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_KlantenOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getCustomersFilteredUsersCustomersPost(
        search?: (string | null),
        sortingField?: ('Naam' | 'Emailadres' | 'Status' | null),
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_customers_filtered_users_customers_post,
    ): CancelablePromise<Page_KlantenOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/customers',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer By Id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public getCustomerByIdUsersCustomersCustomerIdGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * @param customerId
     * @param requestBody
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public updateCustomerUsersCustomersCustomerIdPatch(
        customerId: string,
        requestBody: KlantDetailEdit,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Customer By Id
     * Soft Delete customer by id
     * @param customerId
     * @returns void
     * @throws ApiError
     */
    public deleteCustomerByIdUsersCustomersCustomerIdDelete(
        customerId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Order Metrics By Id
     * Get customer order metrics by ID
     * @param customerId
     * @returns CustomerMetricOut Successful Response
     * @throws ApiError
     */
    public getCustomerOrderMetricsByIdUsersCustomersCustomerIdOrderMetricsGet(
        customerId: string,
    ): CancelablePromise<CustomerMetricOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/order_metrics',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Orders By Id
     * Get customer orders by ID
     * @param customerId
     * @param page Page number
     * @param size Page size
     * @returns Page_CustomerDetailOrderOut_ Successful Response
     * @throws ApiError
     */
    public getCustomerOrdersByIdUsersCustomersCustomerIdOrdersGet(
        customerId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_CustomerDetailOrderOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/orders',
            path: {
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Customer By Id
     * Restore soft deleted customer by id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public restoreCustomerByIdUsersCustomersCustomerIdRestoreGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/customers/{customer_id}/restore',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Debiteuren
     * Get all names of locations that have a customer relation (debiteur)
     * @returns LocationNaamOutList Successful Response
     * @throws ApiError
     */
    public getDebiteurenUsersDebiteurenGet(): CancelablePromise<LocationNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/debiteuren',
        });
    }

    /**
     * Get All Location Categories
     * Get all location categories
     * @returns LocationCategoryOutList Successful Response
     * @throws ApiError
     */
    public getAllLocationCategoriesUsersLocationCategoriesGet(): CancelablePromise<LocationCategoryOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/location_categories',
        });
    }

    /**
     * Upload Logo To S3
     * Upload a file to a (private or public) S3 bucket, this endpoint also adds the url to the TenantStyle
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadLogoToS3UsersUploadLogoImagePost(
        formData: Body_upload_logo_to_s3_users_upload_logo_image_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/upload_logo_image',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tenant Style
     * Get tenant style
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public getTenantStyleUsersTenantStyleGet(): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/tenant_style',
        });
    }

    /**
     * Update Tenant Style
     * Update tenant style
     * @param requestBody
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public updateTenantStyleUsersTenantStylePatch(
        requestBody: TenantStyleEdit,
    ): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/tenant_style',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ultimate Company Location
     * Get the ultimate company
     * @returns CompanyOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyLocationV1UsersUltimateCompanyGet(): CancelablePromise<CompanyOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/ultimate_company',
        });
    }

    /**
     * Get Ultimate Company Payment Organization
     * Get the ultimate company mollie account connection info
     * @param dryRun
     * @returns MollieOrganizationOut Successful Response
     * @throws ApiError
     */
    public getUltimateCompanyPaymentOrganizationV1UsersUltimateCompanyMollieGet(
        dryRun?: (boolean | null),
    ): CancelablePromise<MollieOrganizationOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/ultimate_company_mollie',
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subcompany Customers By Company
     * Get subcompany customers by company id (Bestelaccounts)
     * @param companyId
     * @param page Page number
     * @param size Page size
     * @returns Page_BestelAccountOut_ Successful Response
     * @throws ApiError
     */
    public getSubcompanyCustomersByCompanyV1UsersSubcompanyCustomersCompanyIdGet(
        companyId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_BestelAccountOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/subcompany_customers/{company_id}',
            path: {
                'company_id': companyId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Subsidiaries
     * Get all subsidiaries
     * @returns LocationNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllSubsidiariesV1UsersSubsidiariesGet(): CancelablePromise<LocationNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/subsidiaries',
        });
    }

    /**
     * Get Subsidiaries Filtered
     * Get all subsidiaries
     * @param search
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_LocationOverviewOut_ Successful Response
     * @throws ApiError
     */
    public getSubsidiariesFilteredV1UsersSubsidiariesPost(
        search?: (string | null),
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_subsidiaries_filtered_v1_users_subsidiaries_post,
    ): CancelablePromise<Page_LocationOverviewOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/subsidiaries',
            query: {
                'search': search,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subsidiary By Company Id
     * Get all subsidiaries by company id
     * @param companyId
     * @returns SubsidiaryOverviewOut Successful Response
     * @throws ApiError
     */
    public getSubsidiaryByCompanyIdV1UsersSubsidiariesCompanyIdGet(
        companyId: string,
    ): CancelablePromise<SubsidiaryOverviewOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/subsidiaries/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Subsidiary
     * Update an existing subsidiary
     * @param companyId
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public updateSubsidiaryV1UsersSubsidiariesCompanyIdPatch(
        companyId: string,
        requestBody: Body_update_subsidiary_v1_users_subsidiaries__company_id__patch,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/subsidiaries/{company_id}',
            path: {
                'company_id': companyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Subsidiary
     * Create a new subsidiary
     * @param requestBody
     * @returns SubsidiaryOut Successful Response
     * @throws ApiError
     */
    public createSubsidiaryV1UsersCreateSubsidiaryPost(
        requestBody: Body_create_subsidiary_v1_users_create_subsidiary_post,
    ): CancelablePromise<SubsidiaryOut> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/create_subsidiary',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Subsidiary
     * Delete a subsidiary
     * @param companyId
     * @returns void
     * @throws ApiError
     */
    public deleteSubsidiaryV1UsersSubsidiaryCompanyIdDelete(
        companyId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/users/subsidiary/{company_id}',
            path: {
                'company_id': companyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Employees Filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_MedewerkerOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getEmployeesFilteredV1UsersEmployeesPost(
        search?: (string | null),
        sortingField?: 'Naam' | 'Functie' | 'Emailadres' | 'Status',
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_employees_filtered_v1_users_employees_post,
    ): CancelablePromise<Page_MedewerkerOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/employees',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Employees
     * @returns MedewerkerNaamOutList Successful Response
     * @throws ApiError
     */
    public getAllEmployeesV1UsersEmployeeNamesGet(): CancelablePromise<MedewerkerNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/employee_names',
        });
    }

    /**
     * Get Employee By Id
     * Get an employee by ID
     * @param employeeId
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public getEmployeeByIdV1UsersEmployeesEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee
     * Edit an employee
     * @param employeeId
     * @param requestBody
     * @returns EmployeeOut Successful Response
     * @throws ApiError
     */
    public updateEmployeeV1UsersEmployeesEmployeeIdPatch(
        employeeId: string,
        requestBody: MedewerkerDetailEdit,
    ): CancelablePromise<EmployeeOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Employee
     * Delete an employee
     * @param employeeId
     * @returns void
     * @throws ApiError
     */
    public deleteEmployeeV1UsersEmployeesEmployeeIdDelete(
        employeeId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/users/employees/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customers Filtered
     * Get all customers filtered
     * @param search
     * @param sortingField
     * @param sortingOrder
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_KlantenOverzichtOut_ Successful Response
     * @throws ApiError
     */
    public getCustomersFilteredV1UsersCustomersPost(
        search?: (string | null),
        sortingField?: ('Naam' | 'Emailadres' | 'Status' | null),
        sortingOrder: 'asc' | 'desc' = 'asc',
        page: number = 1,
        size: number = 50,
        requestBody?: Body_get_customers_filtered_v1_users_customers_post,
    ): CancelablePromise<Page_KlantenOverzichtOut_> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/customers',
            query: {
                'search': search,
                'sorting_field': sortingField,
                'sorting_order': sortingOrder,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer By Id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public getCustomerByIdV1UsersCustomersCustomerIdGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * @param customerId
     * @param requestBody
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public updateCustomerV1UsersCustomersCustomerIdPatch(
        customerId: string,
        requestBody: KlantDetailEdit,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Customer By Id
     * Soft Delete customer by id
     * @param customerId
     * @returns void
     * @throws ApiError
     */
    public deleteCustomerByIdV1UsersCustomersCustomerIdDelete(
        customerId: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/users/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Order Metrics By Id
     * Get customer order metrics by ID
     * @param customerId
     * @returns CustomerMetricOut Successful Response
     * @throws ApiError
     */
    public getCustomerOrderMetricsByIdV1UsersCustomersCustomerIdOrderMetricsGet(
        customerId: string,
    ): CancelablePromise<CustomerMetricOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/customers/{customer_id}/order_metrics',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Orders By Id
     * Get customer orders by ID
     * @param customerId
     * @param page Page number
     * @param size Page size
     * @returns Page_CustomerDetailOrderOut_ Successful Response
     * @throws ApiError
     */
    public getCustomerOrdersByIdV1UsersCustomersCustomerIdOrdersGet(
        customerId: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_CustomerDetailOrderOut_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/customers/{customer_id}/orders',
            path: {
                'customer_id': customerId,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Restore Customer By Id
     * Restore soft deleted customer by id
     * @param customerId
     * @returns CustomerOut Successful Response
     * @throws ApiError
     */
    public restoreCustomerByIdV1UsersCustomersCustomerIdRestoreGet(
        customerId: string,
    ): CancelablePromise<CustomerOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/customers/{customer_id}/restore',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Debiteuren
     * Get all names of locations that have a customer relation (debiteur)
     * @returns LocationNaamOutList Successful Response
     * @throws ApiError
     */
    public getDebiteurenV1UsersDebiteurenGet(): CancelablePromise<LocationNaamOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/debiteuren',
        });
    }

    /**
     * Get All Location Categories
     * Get all location categories
     * @returns LocationCategoryOutList Successful Response
     * @throws ApiError
     */
    public getAllLocationCategoriesV1UsersLocationCategoriesGet(): CancelablePromise<LocationCategoryOutList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/location_categories',
        });
    }

    /**
     * Upload Logo To S3
     * Upload a file to a (private or public) S3 bucket, this endpoint also adds the url to the TenantStyle
     * @param formData
     * @param _public
     * @returns any Successful Response
     * @throws ApiError
     */
    public uploadLogoToS3V1UsersUploadLogoImagePost(
        formData: Body_upload_logo_to_s3_v1_users_upload_logo_image_post,
        _public: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/users/upload_logo_image',
            query: {
                'public': _public,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tenant Style
     * Get tenant style
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public getTenantStyleV1UsersTenantStyleGet(): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/users/tenant_style',
        });
    }

    /**
     * Update Tenant Style
     * Update tenant style
     * @param requestBody
     * @returns TenantStyleOut Successful Response
     * @throws ApiError
     */
    public updateTenantStyleV1UsersTenantStylePatch(
        requestBody: TenantStyleEdit,
    ): CancelablePromise<TenantStyleOut> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/users/tenant_style',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
