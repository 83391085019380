import { Box,Checkbox,MenuItem, Select, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import {  useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { batch, Signal } from "@preact/signals-react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/table/CustomTable";
import { apiClient } from "../../functions/Login";
import { CancelablePromise, GroupBasicOut, RolesEnum } from "../../assets";
import { useEffect } from "react";

export default function PersonalOverview() {
    useSignals();
    // Define signals
    const status: Signal<string> = useSignal('');
    const groups: Signal<GroupBasicOut[]> = useSignal([]);
    const selectedRoles: Signal<string[]> = useSignal([]);
    const selectedFunctions: Signal<string[]> = useSignal([]);

    const filtering = useComputed(() => {
        return (status.value !== '' || selectedRoles.value.length > 0 || selectedFunctions.value.length > 0)
    })

    const clearFilters = () => {
        batch(() => {
            status.value = '';
            selectedRoles.value = [];
            selectedFunctions.value = [];
        })
    }

    const navigate = useNavigate();
    
    useEffect(() => {
        apiClient().policies.getAllGroupsPoliciesAllGroupsGet().then((r) => {
            groups.value = r.items
        })
    }, [])
    
    return(
        <ThemeProvider theme={theme}>

            {/* Filter selects */}
            <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={selectedFunctions.value}
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <BadgeOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Functie   
                                </Typography> 
                            </Box>)
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {selectedFunctions.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <BadgeOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Functie   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    {
                        groups.value.map((group) =>
                            <MenuItem value={group.name} key={group.name}>
                                <Checkbox checked={selectedFunctions.value.indexOf(group.name) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {group.name} 
                                </Typography> 
                            </MenuItem>
                        ) 
                    }
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    MenuProps={{ style: {maxHeight: '300px'}}}
                    value={selectedRoles.value}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <AdminPanelSettingsOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Toegangsrechten   
                                </Typography> 
                            </Box>
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {selectedRoles.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}}
                    sx={{minWidth: '200px', maxWidth: '200px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <AdminPanelSettingsOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Toegangsrechten   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    {
                        Object.values(RolesEnum).map((role) => 
                            <MenuItem value={role} key={role}>
                                <Checkbox checked={selectedRoles.value.indexOf(role) > -1} />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    {role}   
                                </Typography> 
                            </MenuItem>
                        )
                    }
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    value={status.value}
                    onChange={(e) => {status.value = e.target.value}}
                    sx={{minWidth: '150px', maxWidth: 'fit-content', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value={''} key={''}>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <EditAttributesOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Status   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    <MenuItem value={'Actief'} key={'Actief'}>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Actief   
                        </Typography> 
                    </MenuItem>
                    <MenuItem value={'Inactief'} key={'Inactief'}>
                        <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                            Inactief   
                        </Typography> 
                    </MenuItem>
                </Select>
                <Typography onClick={clearFilters} sx={{display: filtering.value ?  'flex': 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                    Filters wissen  
                </Typography> 
            </Box>

            {/* Personal Overview */}
            
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <CustomTable 
                    dataEndpoint={(...params: any[]): CancelablePromise<any> => {return apiClient().userManagement.getEmployeesFilteredUsersEmployeesPost(...params)}}
                    onClickLink='/personeel/medewerkers/'
                    menuItems={[
                        {
                            Icon: EditOutlinedIcon,
                            text: 'Gegevens wijzigen',
                            onClick: (id: string) => {navigate('/personeel/medewerkers/' + id)}
                        }
                    ]}
                    requestBody={
                        {
                            'status': status.value ? status.value : null,
                            'functies': selectedFunctions.value,
                            'toegangsrechten': selectedRoles.value
                        }
                    }
                />
            
            </Box>

        </ThemeProvider>
    )
}