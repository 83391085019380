import {Backdrop, Box, ThemeProvider } from "@mui/material";
import theme from "../../../Theme";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import BackgroundImage from "../../../SignUpBackground.png"
import ThirdPage from "./ThirdPage";
import FourthPage from "./FourthPage";
import { apiClient } from "../../../functions/Login";
import { Body_create_tenant_auth_tenant_post } from "../../../assets";

export default function BusinessSignUp() {
    const steps = ['Aanmelden', 'Email verifiëren', 'Start jouw drive-thru']
    
    useSignals();
    // Overall
    const activeStep: Signal<number> = useSignal(0);

    // First step
    const first_name: Signal<string> = useSignal('');
    const prefixes: Signal<string> = useSignal('');
    const last_name: Signal<string> = useSignal('');
    const kvk_number: Signal<string> = useSignal('');
    const business_name: Signal<string> = useSignal('');
    const phone_number: Signal<string> = useSignal('');
    const email: Signal<string> = useSignal('');
    const password: Signal<string> = useSignal('');
    const password_check: Signal<string> = useSignal('');
    const id: Signal<string> = useSignal('');
    const host: Signal<string> = useSignal('loefje');

    const setStep = (e: React.FormEvent<HTMLFormElement>, step: number) => {
        e.preventDefault();
        activeStep.value = step;
        
    }

    const createTenant = async() => {
        const body = {
            user: {
                first_name: first_name.value,
                preposition_name: prefixes.value,
                last_name: last_name.value,
                email: email.value,
                password: password.value
            },
            tenant: {
                tenant_company_id: id.value
            }
        } as Body_create_tenant_auth_tenant_post
        await apiClient().admin.adminPaymentCreateMandateAdminPaymentCreateMandateTenantCompanyIdPost(id.value, {payment_customer: {name: 'dryrun', email: email.value}, iban: 'dryrun', dry_run: true}).then(async() => {
            await apiClient().authentication.createTenantAuthTenantPost(body).then((r) => {   
                host.value = r.host ? r.host : ''
            })
        })
    }

    return (
        <ThemeProvider theme={theme}>
            {/* Render first page */}
            {
                activeStep.value === 0 &&
                <FirstPage 
                    first_name={first_name}
                    prefixes={prefixes}
                    last_name={last_name}
                    kvk_number={kvk_number}
                    business_name={business_name}
                    phone_number={phone_number}
                    email={email}
                    id={id}
                    password={password}
                    password_check={password_check}
                    setStep={setStep}
                    steps={steps}
                    activeStep={activeStep}
                />
            }
            {/* Render second page */}
            {
                activeStep.value > 0 &&
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <Backdrop open sx={{ backgroundColor: '#00000060'}}>
                            { activeStep.value === 1 ?
                                <SecondPage 
                                    setStep={setStep}
                                    steps={steps}
                                    activeStep={activeStep}
                                    email={email}
                                    func={createTenant}
                                /> 
                            // :
                            //     activeStep.value === 2 ?
                            //     <ThirdPage 
                            //         setStep={setStep}
                            //         steps={steps}
                            //         activeStep={activeStep}
                            //         email={email}
                            //         name={business_name}
                            //         id={id}
                            //     /> 
                            :
                                <FourthPage 
                                setStep={setStep}
                                steps={steps}
                                activeStep={activeStep}
                                host={host}
                                email={email}
                                password={password}
                            /> 
                            }
  
                    </Backdrop>
                </Box>
            }
        </ThemeProvider>
    )
}