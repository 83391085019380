import { Button, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ButtonOutlinedProps {
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    text: string
    onClick: any
    width?: string
    clickable?: boolean
    type?: 'button' | 'reset' | 'submit'
    form? : string
}

const ButtonOutlined: React.FC<ButtonOutlinedProps> = ({Icon, text, onClick, width = 'fit-content', clickable = true, type = 'button', form = 'form', ...props}) => {
    // ButtonOutlined used in most designs, so this way we can limit the amount redundency

    return (
        <Button form={form} type={type} onClick={onClick} sx={{pointerEvents: clickable ? 'all' : 'none', color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: width, px: '16px', height: '40px',
            '&:hover': {
            backgroundColor: 'secondary.light',
            color: '#fff',
        },}}>
            {
                Icon && <Icon />
            }    
            <Typography variant="labelLarge" sx={{ml: Icon ? '4px': 0}}>
                {text}
            </Typography>
        </Button>
    );
  };

export default ButtonOutlined;