import theme from '../../Theme'
import { ThemeProvider } from '@emotion/react';
import { Box, Checkbox, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonFull from '../../components/buttons/ButtonFull';
import ButtonOutlined from '../../components/buttons/ButtonOutlined';
import ButtonBack from '../../components/buttons/ButtonBack';
import Card from '../../components/layout/Card';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import { useEffect } from 'react';
import { apiClient } from '../../functions/Login';
import Confirm from '../../components/modals/Confirm';
import OptionsToOptiongroup from '../../components/products/OptionsToOptiongroup';
import { ProductOptionTypes } from '../../assets';

interface option {
    name: string
    subcode: string
    option_type: ProductOptionTypes | ''
    is_inclusive: boolean
    has_quantity: boolean
    quantity_factor: string
    price_factor: string
    price_bonus: string
    char_count: string
    per_char_bonus: string
    total_char_bonus: string
    min_amount: string
    max_amount: string
    per_amount_bonus: string
    total_amount_bonus: string
    files: ('jpg' | 'png' | 'pdf' | 'doc')[]
    total_file_bonus: string
    has_allergens: boolean
    allergens: string[]
  }

export default function ArticlesOptionsDetails() {
    const navigate = useNavigate();
    const group_id = String(useParams().group_id);
    
    useSignals();
    // Define signals
    const openDeleteModal: Signal<boolean> = useSignal(false);
    const option_name: Signal<string> = useSignal('');
    const moreOptions: Signal<boolean> = useSignal(false);
    const minOptions: Signal<number> = useSignal(0);
    const maxOptions: Signal<number> = useSignal(0);
    const options: Signal<option[]> = useSignal([{
        'name': '',
        'subcode': '',
        'option_type': '',
        'has_quantity': false,
        'is_inclusive': false,
        'quantity_factor': '1,00',
        'price_factor': '1,00',
        'price_bonus': '0,00',
        'char_count': '0',
        'per_char_bonus': '0,00',
        'total_char_bonus': '0,00',
        'min_amount': '0',
        'max_amount': '0',
        'per_amount_bonus': '0,00',
        'total_amount_bonus': '0,00',
        'files': [],
        'total_file_bonus': '0,00',
        'has_allergens': false,
        'allergens': []
      }]);

    const saveOptionGroup = () => {
        
    }

    useEffect(() => {
        apiClient().itemManagement.getProductOptionCategoryByIdItemsProductOptionCategoriesProductOptionCategoryIdGet(group_id).then((r) => {
            option_name.value = r.category_name ? r.category_name : '';
            moreOptions.value = r.multi_select_type;
            minOptions.value = r.select_options_min ? r.select_options_min : 1;
            maxOptions.value = r.select_options_max ? r.select_options_max : 1;

            if (r.product_options)
            {
                let option_list: option[] = []
                r.product_options.forEach((options) => {
                    let new_option = {
                        'name': '',
                        'subcode': '',
                        'option_type': '',
                        'has_quantity': false,
                        'is_inclusive': false,
                        'quantity_factor': '1,00',
                        'price_factor': '1,00',
                        'price_bonus': '0,00',
                        'char_count': '0',
                        'per_char_bonus': '0,00',
                        'total_char_bonus': '0,00',
                        'min_amount': '0',
                        'max_amount': '0',
                        'per_amount_bonus': '0,00',
                        'total_amount_bonus': '0,00',
                        'files': [],
                        'total_file_bonus': '0,00',
                        'has_allergens': false,
                        'allergens': []
                      } as option

                    new_option = {...new_option,
                        name: options.product_option_name ? options.product_option_name : '',
                        subcode: options.product_subcode ? options.product_subcode : '',
                        is_inclusive: options.surcharge_incl_vat ? options.surcharge_incl_vat : false,
                        option_type: options.product_option_type,
                        allergens: options.allergens ? options.allergens.map(a => a.allergen_name) : []
                    } 
                    if (options.allergens && options.allergens.length > 0) {
                        new_option = {...new_option, has_allergens: true}
                    }

                    // Different logic per option type
                    if (options.product_option_type === ProductOptionTypes.OPTIESELECTOR) {
                        // See if the option has values that differ from standard to set
                        if (options.quantity_factor! > 1 || options.price_factor! > 1) {
                            new_option = {...new_option, 
                                has_quantity: true,
                                price_factor: options.price_factor ? options.price_factor.toString() : '1,00',
                                quantity_factor: options.quantity_factor ? options.quantity_factor.toString() : '1,00'
                            }
                        }
                        new_option = {...new_option, 
                            price_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00',
                            
                        }
                    } else if (options.product_option_type === ProductOptionTypes.VRIJ_TEKSTVELD) {
                        new_option = {...new_option, 
                            char_count: options.multiply_surcharge_max ?  options.multiply_surcharge_max.toString() : '0',
                        }

                        if (options.surcharge_multiplies) {
                            new_option = {...new_option, 
                                per_char_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00'
                            }
                        } else {
                            new_option = {...new_option, 
                                total_char_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00'
                            }
                        }
                    } else if (options.product_option_type === ProductOptionTypes.VRIJ_NUMERIEK_VELD) {
                        new_option = {...new_option, 
                            min_amount: options.multiply_surcharge_min ?  options.multiply_surcharge_min.toString() : '0',
                            max_amount: options.multiply_surcharge_max ?  options.multiply_surcharge_max.toString() : '0',
                        }

                        if (options.surcharge_multiplies) {
                            new_option = {...new_option, 
                                per_amount_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00'
                            }
                        } else {
                            new_option = {...new_option, 
                                total_amount_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00'
                            }
                        }
                    } else {
                        new_option = {...new_option, 
                          ...(options.image_type_docx) && {files: [...new_option.files, 'doc']},
                          ...(options.image_type_jpeg) && {files: [...new_option.files, 'jpg']},
                          ...(options.image_type_pdf) && {files: [...new_option.files, 'pdf']},
                          ...(options.image_type_png) && {files: [...new_option.files, 'png']},
                        }

                        new_option = {...new_option, 
                            price_bonus: options.surcharge_price ? options.surcharge_price.toString() : '0,00',
                            
                        }
                    }
                    option_list.push(new_option)
                })

                options.value = option_list;
            }
               
        })
    }, [])

    return (
      <ThemeProvider theme={theme}>
        <Confirm 
            open={openDeleteModal} 
            func={() => {apiClient().itemManagement.deleteProductOptionCategoryItemsProductOptionCategoriesProductOptionCategoryIdDelete(group_id).then(() => {navigate('/artikelen/opties')})}}
            information_text='Door op bevestigen te drukken wordt deze optiegroep permanent verwijdert.'
        />
        <Box sx={{mr: '16px', mb: '16px'}}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '24px', gap: '8px'}}>
                <ButtonBack />

                <Typography variant="headlineLarge" sx={{mr: 'auto'}}>
                    {option_name}
                </Typography> 

                <ButtonOutlined Icon={DeleteOutlineOutlinedIcon} onClick={() => {openDeleteModal.value = true}} text='Optiegroep verwijderen'/>
                <ButtonFull type='submit' form='optiongroup' onClick={() => {}} text='Gegevens opslaan'/>
            </Box>

            {/* Body */}
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {e.preventDefault(); saveOptionGroup()}} id='optiongroup' style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
            
                <Card 
                    title='' 
                    children={
                        <>
                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>
                                Naam van de optiegroep
                            </Typography>
                            <TextField required label="Naam optiegroep" placeholder='B.v. Grootte of Tekst op taart' variant="outlined" value={option_name.value} onChange={(e) => {option_name.value = e.currentTarget.value}}/>
                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>
                                Aantal te kiezen opties
                            </Typography>
                            <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <Checkbox
                                    value={moreOptions.value}
                                    onChange={(e) => {moreOptions.value = !moreOptions.value}}
                                    color="secondary"
                                />
                                <Typography variant="labelLarge" >
                                    De klant mag meer dan één optie kiezen binnen deze optiegroep
                                </Typography>
                                <TextField sx={{ml: '8px'}} disabled={!moreOptions.value} required={moreOptions.value} label="Minimaal aantal opties" variant="outlined" value={minOptions.value} onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/)) minOptions.value = +e.currentTarget.value}}/>
                                <Typography variant="labelLarge" sx={{fontWeight: '700'}}>
                                    tot
                                </Typography>
                                <TextField disabled={!moreOptions.value} required={moreOptions.value} label="Maximaal aantal opties" variant="outlined" value={maxOptions.value} onChange={(e) => {if (e.currentTarget.value.match(/[0-9]/)) maxOptions.value = +e.currentTarget.value}}/>
                            </Box>
                            <Typography variant='labelLarge' sx={{fontWeight: '700'}}>
                                Opties
                            </Typography>
                            <OptionsToOptiongroup options={options}/>
                        </>
                    }
                />
            </form>
        </Box>
      </ThemeProvider>
    );
  }