import { Backdrop, Box, Fade, IconButton, Modal, TextField, Typography } from "@mui/material";
import { Signal } from "@preact/signals-react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useSignal, useSignals } from "@preact/signals-react/runtime";
import ButtonFull from "../buttons/ButtonFull";
import { apiClient } from "../../functions/Login";


interface DriveThruCategoryAddProps {
    open: Signal<boolean>
    category: Signal<string>
}

const DriveThruCategoryAdd: React.FC<DriveThruCategoryAddProps> = ({open, category}) => {
    useSignals();
    const name: Signal<string> = useSignal('');

    const createCategory = () => {
        apiClient().shops.createShopSubCategoryShopsShopSubCategoriesPost({shop_sub_category_name: name.value}).then((r) => {
            category.value = r.id;
            open.value = false;
            reset();
        })
    }

    const reset = () => {
       name.value = '';
    }

    const style = {
         position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        outline: 0,
        maxHeight: '80vh',
        overflowY: 'auto',
        alignItems: 'center'
      };


    return (
        <Modal
            aria-labelledby="add-category-modal"
            aria-describedby="Modal to add category"
            open={open.value}
            onClose={() => {open.value = false}}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open.value}>
                <Box sx={style}>
                    <IconButton sx={{width: 'fit-content', ml: 'auto', mt: '8px', mr: '8px'}} onClick={() => {open.value = false}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', px: '64px', pb: '64px'}}>
                        <Typography variant='titleLarge' sx={{mx: 'auto', textAlign: 'center'}}>Maak een nieuwe drive-thru categorie aan:</Typography>
                        <TextField required label="Naam categorie" variant="outlined" value={name.value} onChange={(e) => {name.value = e.currentTarget.value}}/>
                        <ButtonFull text='Categorie aanmaken' onClick={() => {createCategory()}} width='100%' />
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
  };

export default DriveThruCategoryAdd;