/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShopCategories } from './ShopCategories';

export type Body_get_subscriptions_filtered_v1_admin_subscriptions_post = {
    status?: Body_get_subscriptions_filtered_v1_admin_subscriptions_post.status;
    shop_categories?: Array<ShopCategories>;
};

export namespace Body_get_subscriptions_filtered_v1_admin_subscriptions_post {

    export enum status {
        ACTIEF = 'Actief',
        INACTIEF = 'Inactief',
    }


}

