import { Box,ThemeProvider } from "@mui/material";
import theme from "../../Theme";
import { useSignals } from "@preact/signals-react/runtime";
import CustomTable from "../../components/table/CustomTable";
import { apiClient } from "../../functions/Login";
import { CancelablePromise } from "../../assets";
import { useEffect } from "react";
import { Signal } from "@preact/signals-react";

interface props {
    openModal: Signal<boolean>
    groups: Signal<string[]>
    options: Signal<string[]>
    allergens: Signal<string[]>
}

const ArticlesOptionsOverview: React.FC<props> = ({openModal, groups, options, allergens}) => {
    useSignals();
    
    useEffect(() => {

    }, [openModal.value])
    
    return(
        <ThemeProvider theme={theme}>
            {/* Article options Overview */}
            
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <CustomTable 
                    placeholder='Zoek optiegroep, optie of optiecode'
                    dataEndpoint={(...params: any[]): CancelablePromise<any> => {return apiClient().itemManagement.getProductOptionCategoriesFilteredItemsProductOptionCategoriesPost(...params)}}
                    onClickLink='/artikelen/opties/bewerk/'
                    enableSorting={false}
                    enableSelect={false}
                    requestBody={
                        {
                            group_ids: groups.value ? groups.value : null ,
                            option_ids: options.value ? options.value : null ,
                            allergens: allergens.value ? allergens.value : null 
                        }
                    }
                />
            
            </Box>

        </ThemeProvider>
    )
}

export default ArticlesOptionsOverview;