/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MailingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send Drive Thru Qr Email
     * Send drive thru qr email
     * @param orderEmail
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendDriveThruQrEmailMailingSendDriveThruQrEmailOrderEmailPost(
        orderEmail: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/mailing/send_drive_thru_qr_email/{order_email}',
            path: {
                'order_email': orderEmail,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Drive Thru Qr Email
     * Send drive thru qr email
     * @param orderEmail
     * @param dryRun
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendDriveThruQrEmailV1MailingSendDriveThruQrEmailOrderEmailPost(
        orderEmail: string,
        dryRun?: (boolean | null),
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/mailing/send_drive_thru_qr_email/{order_email}',
            path: {
                'order_email': orderEmail,
            },
            query: {
                'dry_run': dryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
