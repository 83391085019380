import {Box, Divider, Fade, IconButton, LinearProgress, Paper, ThemeProvider, Tooltip, Typography } from '@mui/material';
import theme from '../Theme';
import Logo from '../Logo_Geel_transparant.png'
import appDriveThru from '../images/landing/appDriveThru.svg';
import heroDriveThru from '../images/landing/heroDriveThru.svg';
import artikellenOverview from '../images/landing/artikellenOverview.svg';
import orderDashboard from '../images/landing/orderDashboard.svg';
import webshopDriveThru from '../images/landing/webshopDriveThru.svg';
import ButtonText from '../components/buttons/ButtonText';
import ButtonOutlined from '../components/buttons/ButtonOutlined';
import ButtonFull from '../components/buttons/ButtonFull';
import { useMediaQuery } from 'react-responsive';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import Split from '../components/layout/Split';
import USPCard from '../components/USPCard';
import { createRef, useEffect, useState } from 'react';
import USPCardSimple from '../components/USPCardSimple';
import CheckText from '../components/CheckText';
import FadeImage from '../components/FadeImage';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';
import ContactForm from '../components/modals/ContactForm';

function Landing() {
  const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
  const [isTop, setIsTop] = useState(true);

  useSignals();
  const open: Signal<boolean> = useSignal(false);
  const scrolled: Signal<number> = useSignal(0);

  const klantgemak = createRef<HTMLDivElement>();
  const afhaalproces = createRef<HTMLDivElement>();
  const tijdsloten = createRef<HTMLDivElement>();
  const afzet = createRef<HTMLDivElement>();
  const prijs = createRef<HTMLDivElement>();
  const usps = createRef<HTMLDivElement>();
  
  const scrollTo = (ref: HTMLDivElement) => {
    ref.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleScroll = () => {
    const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    scrolled.value = winScroll / height * 100;

    if(window.scrollY >= 10){
      if (isTop !== false)
        setIsTop(false);
    } else {
        setIsTop(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ContactForm open={open} width={isMobile ? '95%' : '500px'} mobile={isMobile}/>
      <Box sx={{display: 'flex', flexDirection: 'column', background: '#fff', px: isMobile ? '16px' : '32px', pb: isMobile ? '16px' : '32px',  gap: '64px' , WebkitOverflowScrolling: 'touch'}}>
        {/* To Top button */}
        <Fade in={!isTop}>
          <Tooltip title='Terug naar boven' placement='top-end'>
            <IconButton onClick={scrollToTop} sx={{display: 'flex', position: 'sticky', zIndex: 100, top: 'calc(100svh - 60px)', ml: 'auto', height: '40px', width: '40px', borderRadius: '32px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                <ArrowUpwardIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
            </IconButton>
          </Tooltip>
          
        </Fade>
         {/* Scroll button */}
         <Fade in={isTop && !isMobile}>
          <Tooltip title='Bekijk meer'>
            <IconButton onClick={() => {scrollTo(usps.current!)}} sx={{display: 'flex', position: 'sticky', mx: 'auto', zIndex: 100, top: 'calc(100svh - 100px)', height: '50px', width: '50px', borderRadius: '32px', backgroundColor: '#00668933', color: '#006689', p: '10px', boxSizing: 'border-box'}}>
                <ArrowDownwardIcon preserveAspectRatio="none" sx={{width: '100%', height: '100%'}}/>
            </IconButton>
          </Tooltip>
          
        </Fade>
        {/* Header */}
        <Box sx={{position: isMobile ? 'sticky' : '', zIndex: 100, top: 0, backgroundColor: 'white', display: 'flex', gap: isMobile ? '8px' : '16px', height: '100px', alignItems: 'center', justifyContent: 'space-between', mt: '-210px'}}>
         {  
            isMobile ? 
              <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: '4px'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '8px', alignItems: 'center'}}>
                  <img alt='Loaf IT' src={Logo} height='60px' width='130px' style={{}}/>
                  <ButtonFull text='Start vooraanmelding' onClick={() => {open.value = true;}}/>
                </Box>
                <LinearProgress variant="determinate" value={scrolled.value} sx={{transition: 'none'}}/>
              </Box>
            
            :
              <>
                <img alt='Loaf IT' src={Logo} height='70px' width='150px'/>
                <Box sx={{display: 'flex', gap: '32px'}}>
                  <ButtonText text='Klantgemak' onClick={() => {scrollTo(klantgemak.current!)}}/>
                  <ButtonText text='Afhaalproces' onClick={() => {scrollTo(afhaalproces.current!)}}/>
                  <ButtonText text='Tijdsloten' onClick={() => {scrollTo(tijdsloten.current!)}}/>
                  <ButtonText text='Voorspelbare afzet' onClick={() => {scrollTo(afzet.current!)}}/>
                  <ButtonText text='Prijs' onClick={() => {scrollTo(prijs.current!)}}/>
                </Box>
                <Box sx={{display: 'flex', gap: '8px'}}>
                  {/* <ButtonOutlined text='Inloggen' onClick={() => {navigate('/home')}} width='125px'/> */}
                  <ButtonFull text='Start nu jouw vooraanmelding' onClick={() => {open.value = true;}}/>
                </Box>
                
              </>
         }
         
        </Box>
        {/* Hero section */}
        {
          isMobile ?
          <Box sx={{borderRadius: '16px', overflow: 'hidden', backgroundColor: '#F2F5EF', height: 'fit-content', p: '16px', display: 'flex', flexDirection: 'column', gap: '16px', mt: '-64px'}}>
             <iframe width={'300px'} height="375px"
                src="https://www.youtube-nocookie.com/embed/I9BzX8xBgS8?rel=0&autoplay=1&mute=1&loop=1&playlist=I9BzX8xBgS8"
                title="Demo oliebollen drive-thru"
                style={{border: 0, WebkitOverflowScrolling: 'touch', overflowY: 'scroll', marginLeft: 'auto', marginRight: 'auto'}}
                loading='lazy'
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              >
              </iframe>
                
             <Box sx={{display: 'flex', flexDirection: 'column', my:'16px'}}>
                <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                  Maximaliseer jouw 
                </Typography>
                <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                  oliebollen omzet met 
                </Typography>
                <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                  drive-thru software
                </Typography>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: isMobile ? '16px' : '32px', justifyContent: 'center', height: isMobile ? 'fit-content' : '100%', width: isMobile ? '100%' : '70%', mb: '16px'}}>
                <Typography variant='bodyLarge'>
                  Een oplossing die je proces van bestelling tot uitgifte efficiënter maakt, waardoor je meer volume in korte tijd kunt verwerken. Dit biedt extra gemak voor je klanten en meer omzet voor jouw bakkerij. Ideaal voor bijvoorbeeld jouw oliebollenverkoop op Oudjaarsdag!               
                </Typography>
                <ButtonFull text='Start nu jouw vooraanmelding' onClick={() => {open.value = true;}}/>
              </Box>
              <Box sx={{display: 'flex', position: 'relative', height: isMobile ? '200px' : '100%', mt: '32px'}}>
                <img src={heroDriveThru} height={isMobile ? '100%' : '220%'} alt="" style={{position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, top: isMobile ? 0 : -210, maxWidth: '100%'}}/>
              </Box>
          </Box>
          :
          <Box sx={{borderRadius: '16px', overflow: 'hidden', backgroundColor: '#F2F5EF', height:'calc(100svh - 130px)', p: '64px', display: 'flex', gap: '16px', mt: '-64px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '64px', height: '100%', width: '50%'}}>
                <Box sx={{display: 'flex', position: 'relative', height: isMobile ? '200px' : '100%', mt: '32px'}}>
                  <img src={heroDriveThru} height={'100%'} alt="" style={{position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, top: 0, maxWidth: '100%'}}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', justifyContent: 'center', height:'fit-content', width:'70%'}}>
                  <Typography variant='bodyLarge'>
                    Een oplossing die je proces van bestelling tot uitgifte efficiënter maakt, waardoor je meer volume in korte tijd kunt verwerken. Dit biedt extra gemak voor je klanten en meer omzet voor jouw bakkerij. Ideaal voor bijvoorbeeld jouw oliebollenverkoop op Oudjaarsdag!               
                  </Typography>
                  <ButtonFull text='Start nu jouw vooraanmelding' onClick={() => {open.value = true;}}/>
                </Box>
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '64px', height: '100%', width: '50%', alignItems: 'center'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', height: 'fit-content'}}>
                  <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                    Maximaliseer jouw 
                  </Typography>
                  <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                    oliebollen omzet met 
                  </Typography>
                  <Typography variant={isMobile ? 'headlineLarge' : 'displayLarge'} >
                    drive-thru software
                  </Typography>
                </Box>
                <iframe width={'380px'} height="475px"
                  src="https://www.youtube-nocookie.com/embed/I9BzX8xBgS8?rel=0&autoplay=1&mute=1&loop=1&playlist=I9BzX8xBgS8"
                  title="Demo oliebollen drive-thru"
                  style={{border: 0, marginLeft: '-180px'}}
                  loading='lazy'
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                >
                </iframe>
              </Box>
          </Box>
        }
        {/* Overview */}
        <Box ref={usps} sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', justifyContent: 'space-between', px: '32px', gap: '16px', scrollMarginTop: '32px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <USPCard 
            isMobile={isMobile}
            title='Verhoog je omzet'
            Icon={DirectionsCarFilledOutlinedIcon}
            text={'Een drive-thru evenement maakt het mogelijk om hoger volume in korte tijd te verwerken en geeft jou de mogelijkheid om nieuwe klanten aan te spreken die kiezen voor snelheid, gemak én jouw kwaliteit!'}
          />
          <USPCard 
            isMobile={isMobile}
            title='Spreidt de drukte'
            Icon={EventRepeatOutlinedIcon}
            text={'Met behulp van tijdsloten per bestelling wordt de drukte over de tijd verdeelt. Daarnaast is het mogelijk om verschillende locaties te bedienen vanuit dezelfde webshop, waardoor je niet alleen de drukte over tijd, maar ook per locatie verdeelt.'}
          />
          <USPCard 
            isMobile={isMobile}
            title='Optimaliseer je proces'
            Icon={ShoppingCartOutlinedIcon}
            text={'Van te voren weet jij hoeveel er besteld is en stem je dat af met je productieplanning. Met onze drive-thru app is het afhaalproces gemakkelijker dan ooit! Scan de QR-code, verzamel de bestelling en meld deze af.'}
          />
        </Box>
        {/* Klantgemak */}
        <Box ref={klantgemak} sx={{display: 'flex', backgroundColor: '#F2F3F4', width: '100%', borderRadius: '16px', overflow: 'hidden', p: isMobile ? '32px' : '64px', scrollMarginTop: '32px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <Split
            horizontal={isMobile ? false : true} 
            leftWidth={40}
            left={
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', height: '100%'}}>
                <Typography variant='headlineMedium'>
                  Efficiëntie en gemak voor jouw klanten
                </Typography>
                <Typography variant='bodyLarge'>
                  Klanten bestellen online en kiezen een afhaaltijdslot. Is de klant iets vergeten te bestellen? Via de mobiele drive-thru app kunnen ze alsnog hun bestelling plaatsen bij jouw collega’s.
                </Typography>
                <USPCardSimple 
                  title='Afrekenen met iDeal'
                  text={'Jouw klanten bestellen en betalen vooraf met iDeal.'}
                />
                <USPCardSimple 
                  title='Geen omzet mislopen'
                  text={'Tijdens het evenement kunnen extra bestellingen geplaatst worden in de mobiele drive-thru app. Hierdoor loop jij geen omzet mis én stuur jij alle klanten tevreden door!'}
                />
                <USPCardSimple 
                  title='In jouw eigen huisstijl'
                  text={'De webshop kan je instellen in jouw huisstijl kleuren en voorzien van een eigen logo, daardoor ben jij herkenbaar voor jouw klanten.'}
                />
              </Box>
            }
            right={
              <FadeImage 
              height={isMobile ? '200px' : '100%'}
              mainImage={<img src={webshopDriveThru} height={isMobile ? '100%' : '420px'} width={isMobile ? '100%' : ''} alt="" style={{marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, maxWidth: '100%'}}/>}
            />
            }
          />
        </Box>
        {/* Afhaalproces */}
        <Box ref={afhaalproces} sx={{display: 'flex', p: isMobile ? '32px' : '64px', width: '100%', scrollMarginTop: '32px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <Split 
            horizontal={isMobile ? false : true} 
            leftWidth={60}
            left={
              <FadeImage 
                height={isMobile ? '200px' : '100%'}
                left={true}
                mainImage={<img src={appDriveThru} height={'100%'} width={isMobile ? '100%' : ''} alt="" style={{position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, maxWidth: '100%'}}/>}
              />
            }
            right={
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', width: '100%'}}>
                <Typography variant='headlineMedium'>
                  Gemakkelijk afhaalproces met de drive-thru app
                </Typography>
                <Typography variant='bodyLarge'>
                  Jouw afhaal-proces was nog nooit zo makkelijk als met de drive-thru applicatie: scan de QR-code, verzamel de bestelling en meld deze af.
                </Typography>
                <USPCardSimple 
                  title='Scan bestellingen voor extra snelheid'
                  text={'Scan bestellingen met de mobiele drive-thru app vanaf jouw eigen telefoon en stuur deze door naar de drive-thru desk app (bestellingen kunnen ook handmatig worden opgezocht).'}
                />
                <USPCardSimple 
                  title='De bestelling gecontroleerd verzamelen'
                  text={'Ontvang een overzicht van de bestelling op de drive-thru desk app, verzamel de artikelen en meld de bestelling gemakkelijk af.'}
                />
                <USPCardSimple 
                  title='Uitgiftepunten op één of meer locaties'
                  text={'Maak gebruik van meerdere uitgiftepunten op dezelfde locatie, of zelfs meerdere locaties!'}
                />
                <USPCardSimple 
                  title='Bepaal hoe klanten hun bestelling ophalen'
                  text={'Per locatie kan jij opgeven of jouw klanten met de auto, fiets of te voet langs kunnen komen.'}
                />
              </Box>
            }
          />
        </Box>
        {/* Tijdsloten */}
        <Box ref={tijdsloten} sx={{display: 'flex', backgroundColor: '#F2F3F4', width: '100%' , borderRadius: '16px', overflow: 'hidden', p: isMobile ? '32px' : '64px', scrollMarginTop: '32px', height: 'fit-content', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <Split 
            horizontal={isMobile ? false : true} 
            leftWidth={40}
            left={
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', height: '100%'}}>
                <Typography variant='headlineMedium'>
                  Spreidt de drukte met behulp van tijdsloten
                </Typography>
                <Typography variant='bodyLarge'>
                  Door tijdsloten in te stellen spreidt jij de drukte en kan je jouw productieplanning afstemmen op de afhaaltijden.
                </Typography>
                <USPCardSimple 
                  title='Voorkom onnodige wachttijd'
                  text={'Door tijdsloten hoeven jouw klanten niet te wachten op hun bestelling.'}
                />
                <USPCardSimple 
                  title='Spreidt drukte en voorkom lange rijen'
                  text={'Jij spreidt de drukte bij de afhaallocatie en voorkomt daarmee lange rijen.'}
                />
                <USPCardSimple 
                  title='Stem je productieplanning af op tijdsloten'
                  text={'Je kan jouw productieplanning afstemmen op de tijdsloten, doordat de bestelde artikelen per tijdslot inzichtelijk gemaakt worden.'}
                />
                
              </Box>
            }
            right={
              <FadeImage 
                height={isMobile ? '200px' : '100%'}
                mainImage={<img src={artikellenOverview} height={isMobile ? '100%' : '420px'} width={isMobile ? '100%' : ''} alt="" style={{ marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, maxWidth: '100%'}}/>}
              />
            }
          />
        </Box>
        {/* Afzet */}
        <Box ref={afzet} sx={{display: 'flex', width: '100%', p: isMobile ? '32px' : '64px', scrollMarginTop: '32px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <Split 
            horizontal={isMobile ? false : true} 
            leftWidth={60}
            left={
              <FadeImage 
                height={isMobile ? '200px' : '100%'}
                left={true}
                mainImage={<img src={orderDashboard} height='100%' width={isMobile ? '100%' : ''} alt="" style={{position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, maxWidth: '100%'}}/>}
              />
            }
            right={
              <>
                <Typography variant='headlineMedium'>
                  Jouw afzet van te voren bekend
                </Typography>
                <Typography variant='bodyLarge'>
                  Doordat bestellingen vooraf geplaatst zijn weet jij hoeveel je moet produceren. De bestelde artikelen worden weergeven als totalen, per tijdslot en per bestelling.
                </Typography>
                <USPCardSimple 
                  title='Overzicht van bestelde artikelen'
                  text={'Bestellingen en bestelde artikelen kunnen worden opgezocht in het systeem. Alle bestellingen en artikelen in één overzicht'}
                />
                <USPCardSimple 
                  title='Details van bestellingen'
                  text={'Van iedere bestelling kan jij de details opzoeken in het systeem op basis van bestelnummer, klantnaam of mailadres.'}
                />
                <USPCardSimple 
                  title='QR-code’s opnieuw verzenden'
                  text={'Wanneer een klant de QR-code kwijt is, kan deze met één druk op de knop opnieuw worden verzonden naar jouw klant.'}
                />
                <USPCardSimple 
                  title='Makkelijk artikelbeheer'
                  text={'Artikelen kan je makkelijk invoeren in het systeem, inclusief wettelijke naamgevingen en allergenendeclaratie.'}
                />
              </>
            }
          />
        </Box>
        {/* prijs */}
        <Box ref={prijs} sx={{borderRadius: '16px', overflow: 'hidden', backgroundColor: '#F2F5EF', width: '100%', py: isMobile ? '32px' : '64px', display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
            <Typography variant={isMobile ? 'headlineLarge' : 'displayMedium'} sx={{textAlign: isMobile ? 'center' : 'auto'}}>
              Simpel & Transparant
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant='bodyLarge' sx={{textAlign: 'center'}}>
              Wij vragen een vaste omzetfee van 6% voor het gebruik van onze software.   
            </Typography>
            <Typography variant='bodyLarge' sx={{textAlign: 'center', mb: '16px'}}>
              Je betaalt pas als je iets verkoopt, zo creëeren wij een win-win situatie!
            </Typography>
            </Box>
            <Paper variant='outlined' sx={{p: isMobile ? '32px' : '64px', width: isMobile ? '90%' : '50%', maxWidth: '700px', borderRadius: '16px', border: 0, display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center'}}>
              <Typography variant='headlineMedium' sx={{mb: '16px', textAlign: 'center'}}>
                Drive-thru applicatie
              </Typography>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'center'}}> 
                <Typography variant='bodyLarge'>
                  All-in tarief
                </Typography>
                <Typography variant='headlineMedium' sx={{fontWeight: '700'}}>
                  6% omzetfee
                </Typography>
                <Typography variant='bodyLarge'>
                + €0,29 per iDeal transactie
                </Typography>
              </Box>
              <Divider flexItem/>
              <Split 
                horizontal={isMobile ? false : true}
                left={
                  <>
                    <CheckText 
                      text={'Webshop in jouw huisstijl'}
                    />
                    <CheckText 
                      text={'Drive-thru mobiele applicatie (inclusief  bestellen tijdens het evenement)'}
                    />
                    <CheckText 
                      text={'Drive-thru desk applicatie voor verzamelen en afmelden van bestellingen'}
                    />
                  </>
                }
                right={
                  <>
                    <CheckText 
                      text={'Dashboard met overzicht van bestellingen per tijdslot en details van bestellingen'}
                    />
                    <CheckText 
                      text={'Artikelen beheren en in één overzicht zien hoeveel artikelen jij moet produceren '}
                    />
                  </>
                }
              />
              <Box sx={{display: 'flex', justifyContent: 'center', mt: '16px'}}>
                <ButtonFull text='Start nu jouw vooraanmelding' onClick={() => {open.value = true;}}/>
              </Box>
            </Paper>
        </Box>
        {/* Demo */}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', backgroundColor: '#F2F3F4', borderRadius: '16px', width: '100%', overflow: 'hidden', p: isMobile ? '16px' : '64px', alignItems: 'center', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
          <Typography variant={isMobile ? 'headlineLarge' : 'displayMedium'} sx={{textAlign: isMobile ? 'center' : 'auto'}}>
            Start jouw drive-thru evenement
          </Typography>
          <Typography variant={isMobile ? 'headlineSmall' : 'headlineLarge'} sx={{textAlign: isMobile ? 'center' : 'auto'}}>
            ... en maak van jouw oudjaarsdag net zo’n succes als onze klanten!
          </Typography>
          <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '8px', alignItems: 'center'}}>
            <ButtonFull text='Start nu jouw vooraanmelding' onClick={() => {open.value = true;}}/>
            <ButtonOutlined text='Neem contact op' onClick={() => {window.open('mailto:info@loaf-it.com')}}/>
          </Box>
        </Box>
        {/* Footer */}
        <Box sx={{display: 'flex', flexDirection: 'column', backgroundColor: 'primary.main', borderRadius: '16px', overflow: 'hidden', width: '100%', p: '64px', gap: '32px', maxWidth: '2000px', maxHeight: '1000px', alignSelf: 'center'}}>
              <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '32px'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', color: 'white', alignItems: isMobile ? 'center' : 'flex-start'}}>
                  <Typography variant='titleLarge'>
                    Oplossingen
                  </Typography>
                  <ButtonText text='Klantgemak' color='white' onClick={() => {scrollTo(klantgemak.current!)}} margin='0'/>
                  <ButtonText text='Afhaalproces' color='white' onClick={() => {scrollTo(afhaalproces.current!)}} margin='0'/>
                  <ButtonText text='Tijdsloten' color='white' onClick={() => {scrollTo(tijdsloten.current!)}} margin='0'/>
                  <ButtonText text='Voorspelbare afzet' color='white' onClick={() => {scrollTo(afzet.current!)}} margin='0'/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', color: 'white', ml: isMobile ? '0' : 'auto', alignItems: isMobile ? 'center' : 'flex-start'}}>
                  <Typography variant='titleLarge'>
                    Bedrijfsgegevens
                  </Typography>
                  <Typography variant='bodyLarge'>
                    Loaf IT
                  </Typography>
                  <Typography variant='bodyLarge'>
                    Nieuwe Schaft 25
                  </Typography>
                  <Typography variant='bodyLarge'>
                    3991 AS Houten
                  </Typography>
                  <Typography variant='bodyLarge'>
                    KVK-nummer: 90430018
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', color: 'white', alignItems: isMobile ? 'center' : 'flex-start'}}>
                  <Typography variant='titleLarge'>
                    Contact
                  </Typography>
                  <ButtonText text='info@loaf-it.com' color='white' onClick={() => {window.open('mailto:info@loaf-it.com')}} margin='0'/>
                </Box>
              </Box>
              <Divider flexItem sx={{backgroundColor: 'white'}}/>
              <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', gap: isMobile ? '16px' : '0'}}>
                <img src={Logo} alt="" style={{filter: 'brightness(0) invert(1)'}} height='50px' width='110px'/>
                <Typography variant='bodyLarge' sx={{color: 'white'}}>
                  © 2024 Copyright by Loaf IT
                </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                  <IconButton onClick={() => {window.open('https://nl.linkedin.com/company/loafit')}}>
                    <LinkedInIcon sx={{color: 'white'}}/>
                  </IconButton>
                  <IconButton onClick={() => {window.open('https://www.instagram.com/loafitsoftware')}}>
                    <InstagramIcon sx={{color: 'white'}}/>
                  </IconButton>
                  <IconButton onClick={() => {window.open('https://www.facebook.com/loafit')}}>
                    <FacebookIcon sx={{color: 'white'}}/>
                  </IconButton>
                </Box>
              </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Landing;