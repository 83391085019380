import { Box, Link, Menu, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import Split from "../../../components/layout/Split";
import ButtonFull from "../../../components/buttons/ButtonFull";
import { useComputed, useSignal, useSignals } from "@preact/signals-react/runtime";
import { Signal } from "@preact/signals-react";
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { apiClient } from "../../../functions/Login";
import PasswordChecklist from "react-password-checklist"
import { Body_admin_signup_tenant_admin_signup_tenant_post } from "../../../assets";

interface FirstStepProps {
    first_name: Signal<string>;
    prefixes: Signal<string>;
    last_name: Signal<string>;
    kvk_number: Signal<string>;
    business_name: Signal<string>;
    id: Signal<string>;
    phone_number: Signal<string>;
    email: Signal<string>; 
    password: Signal<string>; 
    password_check: Signal<string>; 
    setStep: any
    steps: Array<string>
    activeStep: Signal<number>
  }
  
const FirstStep: React.FC<FirstStepProps> = ({ first_name, prefixes, last_name, kvk_number, business_name, phone_number, email, password, password_check, id, setStep, steps, activeStep }) => {
    useSignals();

    const anchorEl: Signal<null | HTMLElement> = useSignal(null);
    const correctPasswords: Signal<boolean> = useSignal(false);

    const handleClose = () => {
        anchorEl.value = null;
    }

    const open = useComputed(() => {
        return Boolean(anchorEl.value)
    })

    const dontMatch = useComputed(() => {
        if (password.value.length > 0 && password_check.value.length > 0 && password.value !== password_check.value) {
            return true
        }
        return false
    })

    const nextStep = () => {
        let body = {
            company:
            {
                name: business_name.value,
                coc_number: kvk_number.value,
                email: email.value,
                phone_number: phone_number.value,
                contact_first_name: first_name.value,
                contact_preposition_name: prefixes.value,
                contact_last_name: last_name.value,
            }
           
        } as Body_admin_signup_tenant_admin_signup_tenant_post

        apiClient().admin.adminSignupTenantAdminSignupTenantPost(body).then((r) => {
            id.value = r.id ? r.id : '';
            apiClient().admin.adminSendVerificationEmailAdminSendVerificationEmailEmailGet(email.value).then(() => {activeStep.value += 1})
        })
    }

    return (
        <Box sx={{display: 'flex', height: '100vh', width: '100vw'}}>
            {/* Left side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', p: '64px', width: '55%', backgroundColor: '#fff', height: '100%'}}>
                <Stepper activeStep={activeStep.value}>
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Typography variant='headlineLarge' sx={{fontSize: '57px', fontWeight: '700', lineHeight: '64px'}}>
                    Start jouw winstgevende online omgeving
                </Typography>
                <form onSubmit={(e) => {setStep(e, 1)}} autoComplete="chrome-off" style={{display: 'flex', flexDirection: 'column', gap: '8px', width: '80%', height: '100%'}}>
                    <TextField required onChange={(e) => {first_name.value = e.currentTarget.value}} value={first_name.value} label="Voornaam" variant="outlined" />
                    <Split 
                        left={
                            <TextField onChange={(e) => {prefixes.value = e.currentTarget.value}} value={prefixes.value} label="Tussenvoegsel" variant="outlined" /> 
                        }
                        right={
                            <TextField required onChange={(e) => {last_name.value = e.currentTarget.value}} value={last_name.value} label="Achternaam" variant="outlined" />
                        }
                    />
                    <Split 
                        left={
                            <TextField required onChange={(e) => {business_name.value = e.currentTarget.value}} value={business_name.value} label="Bedrijfsnaam" variant="outlined" />
                        }
                        right={
                            <TextField inputProps={{pattern: '^[0-9]{8}$'}}  type='tel' required onChange={(e) => {kvk_number.value = e.currentTarget.value}} value={kvk_number.value} label="KVK-nummer (8 cijfers)" variant="outlined" />
                        }
                    />
                     <Split 
                        left={
                            <TextField type='email' required onChange={(e) => {email.value = e.currentTarget.value}} value={email.value} label="Email" variant="outlined" />
                        }
                        right={
                            <TextField type='tel' onChange={(e) => {phone_number.value = e.currentTarget.value}} value={phone_number.value} label="Telefoonnummer" variant="outlined" />        
                        }
                    />
                    <TextField aria-controls={ open.value ? 'password-menu' : undefined} aria-haspopup="true" aria-expanded={ open.value ? 'true' : undefined} onBlur={handleClose} onFocus={(e) => {anchorEl.value = e.currentTarget}} required type='password' onChange={(e) => {password.value = e.currentTarget.value}} value={password.value} label="Wachtwoord" variant="outlined" />
                   
                    <TextField  required type='password'  onChange={(e) => {password_check.value = e.currentTarget.value}} value={password_check.value} label="Herhaal Wachtwoord" variant="outlined" sx={{mb: '16px'}} error={(dontMatch.value)} helperText={dontMatch.value ? 'Wachtwoord komt niet overeen.' : ''}/>
                    <Menu
                        id="password-menu"
                        anchorEl={anchorEl.value}
                        open={open.value}
                        disableAutoFocus
                        sx={{zIndex: '100'}}
                    >
                        <PasswordChecklist
                        style={{padding: '8px'}}
                        rules={["minLength", "lowercase", "number", "capital"]}
                        minLength={8}
                        value={password.value}
                        valueAgain={password_check.value}
                        onChange={(isValid) => {correctPasswords.value = isValid}}
                        messages={{
                            minLength: "Wachtwoord heeft minimaal 8 tekens",
                            lowercase: "Wachtwoord heeft minimaal één kleine letter",
                            number: "Wachtwoord heeft minimaal één cijfer",
                            capital: "Wachtwoord heeft minimaal één hoofdletter",
                        }}
                    />
                    </Menu>

                    <ButtonFull disabled={!correctPasswords.value || dontMatch.value || password_check.value.length === 0} type='submit' text='Account aanmaken' onClick={nextStep} width='100%'/>
                    <Typography variant='bodyLarge' sx={{mt: '8px'}}>
                        Heb je al een account? <Link href='/home' underline='none'>Log in</Link>
                    </Typography>
                    <Typography variant='bodySmall' sx={{mt: 'auto'}}>
                    Door op account aanmaken te klikken ga je akkoord met onze algemene voorwaarden en ons privacybeleid.
                    </Typography>
                </form>
            </Box>

            {/* Right side */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '64px', p: '64px', width: '45%', backgroundColor: 'primary.main', height: '100%', alignItems: 'center', justifyContent: 'space-evenly'}}>
                    <Typography variant="headlineMedium" sx={{color: '#fff', fontWeight: '700', textAlign: 'center'}}>
                    Maximaliseer jouw oliebollen omzet met drive-thru software
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '16px'}}>
                            <Box sx={{display: 'flex', width: '40px', height: '40px', backgroundColor: '#F4D03F', borderRadius: '32px', alignItems: 'center', justifyContent: 'center'}}>
                                <DevicesOutlinedIcon sx={{color: 'primary.main'}}/>
                            </Box>
                            <Typography variant='headlineSmall' sx={{color: '#fff'}}>
                                Spreidt de drukte
                            </Typography>
                        </Box> 
                        <Typography variant='bodyMedium' sx={{color: '#fff', ml: '56px', fontWeight: '700', mb: '8px'}}>
                        met behulp van tijdsloten
                        </Typography>
                        <Typography variant='bodyLarge' sx={{color: '#fff', ml: '56px'}}>
                        Met behulp van tijdsloten per bestelling wordt de drukte over de tijd verdeelt. Daarnaast is het mogelijk om verschillende locaties te bedienen vanuit dezelfde webshop, waardoor je niet alleen de drukte over tijd, maar ook per locatie verdeelt.
                        </Typography>
                    </Box>
                    <AddOutlinedIcon sx={{color: '#fff', transform: 'scale(2)'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '16px'}}>
                            <Box sx={{display: 'flex', width: '40px', height: '40px', backgroundColor: '#F4D03F', borderRadius: '32px', alignItems: 'center', justifyContent: 'center'}}>
                                <BarChartOutlinedIcon sx={{color: 'primary.main'}}/>
                            </Box>
                            <Typography variant='headlineSmall' sx={{color: '#fff'}}>
                            Optimaliseer je proces
                            </Typography>
                        </Box>
                        <Typography variant='bodyMedium' sx={{color: '#fff', ml: '56px', fontWeight: '700', mb: '8px'}}>
                        met de drive-thru app en een van te voren bekende afzet
                        </Typography> 
                        <Typography variant='bodyLarge' sx={{color: '#fff', ml: '56px'}}>
                        Van te voren weet jij hoeveel er besteld is en stem je dat af met je productieplanning. Met onze drive-thru app is het afhaalproces gemakkelijker dan ooit! Scan de QR-code, verzamel de bestelling en meld deze af.
                        </Typography>
                    </Box>
                    <DragHandleOutlinedIcon sx={{color: '#fff', transform: 'scale(2)'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '16px'}}>
                            <Box sx={{display: 'flex', width: '40px', height: '40px', backgroundColor: '#F4D03F', borderRadius: '32px', alignItems: 'center', justifyContent: 'center'}}>
                                <EuroSymbolOutlinedIcon sx={{color: 'primary.main'}}/>
                            </Box>
                            <Typography variant='headlineSmall' sx={{color: '#fff'}}>
                                Verhoog je omzet
                            </Typography>
                        </Box> 
                        <Typography variant='bodyMedium' sx={{color: '#fff', ml: '56px', fontWeight: '700', mb: '8px'}}>
                            door efficiëntie en gemak voor jouw klanten
                        </Typography>
                        <Typography variant='bodyLarge' sx={{color: '#fff', ml: '56px'}}>
                            Een drive-thru evenement maakt het mogelijk om hoger volume in korte tijd te verwerken en geeft jou de mogelijkheid om nieuwe klanten aan te spreken die kiezen voor snelheid, gemak én jouw kwaliteit!
                        </Typography>
                    </Box>
                </Box>
        </Box>
    )
}

export default FirstStep;