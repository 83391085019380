/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Body_get_all_shops_filtered_v1_shops_shops_filtered_post = {
    subcategories?: (Array<string> | null);
    date_time_from?: (string | null);
    date_time_to?: (string | null);
    date_order?: Body_get_all_shops_filtered_v1_shops_shops_filtered_post.date_order;
    is_live?: (boolean | null);
};

export namespace Body_get_all_shops_filtered_v1_shops_shops_filtered_post {

    export enum date_order {
        ASC = 'asc',
        DESC = 'desc',
    }


}

