import React, { ReactElement } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface DragableItemProps {
    id: string
    children: (params: { listeners: any; attributes: any }) => React.ReactNode;
}

const DragableItem: React.FC<DragableItemProps> = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isOver,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab',
    backgroundColor: (isOver && !isDragging) ? 'rgba(0, 123, 255, 0.1)' : 'inherit', // Highlight when hovered
    border: (isOver && !isDragging) ? '2px dashed rgba(0, 123, 255, 0.5)' : '2px dashed rgba(0, 123, 255, 0)',
    boxShadow: (isOver && !isDragging) ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children({ listeners, attributes })}
    </div>
  );
}

export default DragableItem;
