import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useEffect } from "react";
import { Signal } from "@preact/signals-react";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { Dayjs } from "dayjs";
import locale from 'antd/es/date-picker/locale/nl_NL';
import { TimePicker } from "antd";

interface Location {
  selectedLocation: string
  startEvent: Dayjs | null
  endEvent: Dayjs | null
  timeslotTime: Dayjs | null
  openingTimes: OpeningTime[]
  desks: number
  vehicles: Vehicle[]
}

interface OpeningTime {
  active: boolean
  date:  Dayjs
  startTime: Dayjs | null
  endTime: Dayjs | null
}

interface Vehicle {
  type: string
  amount: number
}

interface OpeningTimesProps {
  locations: Signal<Location[]>
  index: number
}


const OpeningTimesOverview: React.FC<OpeningTimesProps> = ({locations, index}) => {
  useSignals();
  const type: Signal<string> = useSignal('weekday');

  const updateLocation = (i: number, value: any, field: string) => {
    locations.value = locations.value.map((location, ii) => ii === index ? {...location, openingTimes: location.openingTimes.map((ot, iii) => i === iii ? {...ot, [field]: value} : {...ot})} : {...location})
  }

  const updateLocations = (day: number, value: any, field: string) => {
    locations.value = locations.value.map((location, ii) => ii === index ? {...location, openingTimes: location.openingTimes.map((ot, iii) => day === ot.date.day() ? {...ot, [field]: value} : {...ot})} : {...location})
  }

  const weekdays = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag'
  ]

  useEffect(() => {
  }, [])

  return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        <Typography variant='labelLarge' sx={{fontWeight: '700'}}>Openingstijden</Typography>
        <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
        <Typography variant='bodyMedium'>Aangeven per:</Typography>
          <RadioGroup
            row
            value={type.value}
            onChange={(e) => {type.value = e.currentTarget.value}}
          >
            <FormControlLabel value={'weekday'} control={<Radio />} label={'Weekdag'} />
            <FormControlLabel value={'date'} control={<Radio />} label={'Datum'} />
          </RadioGroup>
        </Box>
        {
          type.value === 'weekday' ?
          <>
            {
              locations.value[index].openingTimes.map((time, i) =>
                i < 7 ?
                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                  <Checkbox checked={time.active} onChange={() => {updateLocations(time.date.day(), !time.active, 'active')}}/>
                  <Typography variant='bodyMedium'>{weekdays[time.date.day()]}</Typography>
                  <TimePicker required style={{marginLeft: 'auto', maxWidth: '200px', height: '56px'}} minuteStep={5} placeholder='Open van' onChange={(e) => {updateLocations(time.date.day(), e, 'startTime')}} value={time.startTime} format={'HH:mm'} locale={locale} />
                  <TimePicker required style={{maxWidth: '200px', height: '56px'}} minuteStep={5} placeholder='Open tot' onChange={(e) => {updateLocations(time.date.day(), e, 'endTime')}} value={time.endTime} format={'HH:mm'} locale={locale} />
                </Box>
                :
                <></>
              )
            }
          </>
          :
          <>
            {
              locations.value[index].openingTimes.map((time, i) =>
                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                  <Checkbox checked={time.active} onChange={() => {updateLocation(i, !time.active, 'active')}}/>
                  <Typography variant='bodyMedium'>{time.date.format('DD-MM-YYYY')}</Typography>
                  <TimePicker required style={{marginLeft: 'auto', maxWidth: '200px', height: '56px'}} minuteStep={5} placeholder='Open van' onChange={(e) => {updateLocation(i, e, 'startTime')}} value={time.startTime} format={'HH:mm'} locale={locale} />
                  <TimePicker required style={{maxWidth: '200px', height: '56px'}} minuteStep={5} placeholder='Open tot' onChange={(e) => {updateLocation(i, e, 'endTime')}} value={time.endTime} format={'HH:mm'} locale={locale} />
                </Box>
              )
            }
          </>
        }
      </Box>
  );
};

export default OpeningTimesOverview; 