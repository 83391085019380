import { Box, Button, Checkbox, IconButton, MenuItem, Select, Slide, ThemeProvider, Typography } from "@mui/material";
import theme from "../../Theme";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import BreakfastDiningOutlinedIcon from '@mui/icons-material/BreakfastDiningOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dashboard from "../../components/orders/Dashboard";
import { Signal, batch } from "@preact/signals-react";
import { DatasetType } from "@mui/x-charts/models/seriesType/config";
import { Dayjs } from "dayjs";
import Details from "../../components/orders/Details";
import { useComputed, useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import locale from 'antd/es/date-picker/locale/nl_NL';

import 'dayjs/locale/nl';
import { ConfigProvider, DatePicker } from "antd";
import { useEffect } from "react";
import { apiClient } from "../../functions/Login";
import { Body_get_order_overview_metrics_shops_order_overview_metrics_post, CombinedOrderStatusEnum, LocationOut, PaymentStatus, ShopCategories } from "../../assets";

export default function DriveThruOrders() {
    useSignals();
    // Define signals
    const customerCount: Signal<number> = useSignal(0);
    const orderCount: Signal<number> = useSignal(0);
    const revenue: Signal<number> = useSignal(0);
    const profit: Signal<number> = useSignal(0);
    const graphData: Signal<DatasetType> = useSignal([]);

    const orderDateRange: Signal<(Dayjs | null)[]> = useSignal([null]);
    const dueDateRange: Signal<(Dayjs | null)[]> = useSignal([null]);
    const location: Signal<string[]> = useSignal([]);
    const status: Signal<CombinedOrderStatusEnum[]> = useSignal([]);
    const payment: Signal<Extract<PaymentStatus, PaymentStatus.BETAALD | PaymentStatus.OPEN | PaymentStatus.TERUGBETAALD>[]> = useSignal([]);
    const moreFiltering: Signal<boolean> = useSignal(false);

    const locations: Signal<LocationOut[]> = useSignal([]);
    // Define Computed values
    const filtering = useComputed(() => {
        return (orderDateRange.value[0] !== null || dueDateRange.value[0] !== null || status.value.length !== 0 || payment.value.length !== 0 || location.value.length !== 0)
      });
    
    
    const clearFiltering = () => {
        batch(() => {
            orderDateRange.value = [null];
            dueDateRange.value = [null];
            status.value = [];
            location.value = [];
            payment.value = [];
        });
    }

    const { RangePicker } = DatePicker;

    // Initial data load
    useEffect(() => {
        // apiClient().userManagement.getAllLocationsUsersLocationsGet().then((r) => {
        //     locations.value = r.items;
        // })
        return () => {
        };
      }, []);

    // Variable data load
    useSignalEffect(() => {
        const body = {
            order_date_time_from: orderDateRange.value[0] == null ? undefined : orderDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
            order_date_time_to:  orderDateRange.value[1] == null ? undefined : orderDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'),
            delivery_date_time_from: dueDateRange.value[0] == null ? undefined : dueDateRange.value[0]!.format('YYYY-MM-DD HH:mm:ss'),
            delivery_date_time_to: dueDateRange.value[1] == null ? undefined : dueDateRange.value[1]!.format('YYYY-MM-DD HH:mm:ss'),
            shop_type: ShopCategories.DRIVE_THRU,
            betaling_status: payment.value,
            order_status: status.value,
            locations: location.value
        } as Body_get_order_overview_metrics_shops_order_overview_metrics_post
        apiClient().shops.getOrderOverviewMetricsShopsOrderOverviewMetricsPost('',
            body
        ).then(r => {
            orderCount.value = r.order_count;
            customerCount.value = r.customer_count;
            revenue.value = r.total_revenue;
        })
      });

    return(
        <ThemeProvider theme={theme}>
            {/* Filter selects */}
            <Box sx={{position: 'relative', display: 'flex', gap: '8px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                {/* Meer filters box */}
                <Slide direction='left' in={moreFiltering.value} mountOnEnter unmountOnExit>
                    <Box sx={{position: 'fixed', right: 0, mt: '-16px', width: '250px', display: 'flex', flexDirection: 'column', minHeight: '300px', backgroundColor: '#fff', zIndex: '2', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', border: '1px solid #cecece'}}>
                    <IconButton onClick={(e) => {moreFiltering.value = false}} sx={{position: 'absolute'}}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', mt: '24px'}}>

                    {/* <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <EventOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Eventdatum   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select> */}
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <BreakfastDiningOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Artikelen   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <RouteOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Routenummer   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <ReceiptOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Ordernummer   
                                </Typography> 
                            </Box>
                        </MenuItem>
                    </Select>
                    <Select
                        IconComponent={ExpandMoreOutlinedIcon}
                        displayEmpty
                        value={''}
                        onChange={(e) => {}}
                        sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                    >
                        <MenuItem disabled value='' key=''>
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <PeopleAltOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Klant   
                                </Typography> 
                            </Box>
                        </MenuItem>

                    </Select>
                    </Box>
                    </Box>
                </Slide> 
                <ConfigProvider
                theme={{
                    components: {
                    DatePicker: {
                        activeBorderColor: '#28555e',
                        cellRangeBorderColor: '#28555e',
                        hoverBorderColor: '#28555e',
                        colorPrimary: '#28555e',
                        colorBorder: '#c4c4c4',
                        borderRadius: 12,
                        colorTextPlaceholder: '#000',
                        activeShadow: 'none',
                        presetsWidth: 360,
                    },
                    },
                }}
                >
                <RangePicker style={{minWidth: '300px'}} onChange={(e) => {e ? orderDateRange.value = [e[0], e[1]!.hour(23).minute(59)] : orderDateRange.value = [null]}} value={[orderDateRange.value[0], orderDateRange.value[1]]} placeholder={['Orderdatum van', 'Orderdatum tot']} locale={locale} />
                <RangePicker style={{minWidth: '300px'}} onChange={(e) => {e ? dueDateRange.value = [e[0], e[1]!.hour(23).minute(59)] : dueDateRange.value = [null]}} value={[dueDateRange.value[0], dueDateRange.value[1]]} placeholder={['Afleverdatum van', 'Afleverdatum tot']} locale={locale} />
                </ConfigProvider>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={location.value}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                <PlaceOutlinedIcon />
                                <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                    Locatie   
                                </Typography> 
                            </Box>)
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {location.value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value }}
                    sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <PlaceOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', mx: '16px'}}>
                                Locatie   
                            </Typography> 
                        </Box>
                    </MenuItem>
                    {
                        locations.value.map(l => {
                            return (
                                <MenuItem value={l.name} key={l.id}>
                                    <Checkbox checked={location.value.indexOf(l.name) > -1} />
                                    <Typography variant="labelLarge">
                                        {l.name}
                                    </Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={payment.value}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                                <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                                    Betaling   
                                </Typography>
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {payment.value = typeof e.target.value === 'string' ? e.target.value.split(',') as Extract<PaymentStatus, PaymentStatus.BETAALD | PaymentStatus.OPEN | PaymentStatus.TERUGBETAALD>[] : e.target.value}}
                    sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value='' key=''>
                        <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                            Betaling   
                        </Typography> 
                    </MenuItem>
                    {
                        ['Betaald', 'Terugbetaald','Open'].map(p => {
                            return (
                                <MenuItem value={p} key={p}>
                                     <Checkbox checked={payment.value.indexOf(p as Extract<PaymentStatus, PaymentStatus.BETAALD | PaymentStatus.OPEN | PaymentStatus.TERUGBETAALD>) > -1} />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                                        {p}   
                                    </Typography> 
                                </MenuItem>
                            )
                        })
                    }
                </Select>
                <Select
                    IconComponent={ExpandMoreOutlinedIcon}
                    displayEmpty
                    multiple
                    value={status.value}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                                <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                                    Status   
                                </Typography> 
                            )
                        }
            
                        return selected.join(', ');
                    }}
                    onChange={(e) => {status.value = typeof e.target.value  === 'string' ? e.target.value.split(',') as CombinedOrderStatusEnum[] : e.target.value}}
                    sx={{minWidth: '150px', maxWidth: '150px', maxHeight: '50px', borderRadius: '12px'}}
                >
                    <MenuItem disabled value={''} key={''}>
                        <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                            Status   
                        </Typography> 
                    </MenuItem>
                    {
                        Object.values(CombinedOrderStatusEnum).map(s => {
                            return (
                                <MenuItem value={s} key={s}>
                                    <Checkbox checked={status.value.indexOf(s) > -1} />
                                    <Typography variant="labelLarge" sx={{my: 'auto', mr: '16px'}}>
                                        {s}   
                                    </Typography> 
                                </MenuItem>
                            )
                        })
                    }
                </Select>
                {/* <Select
                    displayEmpty
                    open={false}
                    inputProps={{
                        IconComponent: () => null
                    }}
                    value={''}
                    onClick={(e) => {moreFiltering.value = true}}
                    sx={{minWidth: '150px', maxHeight: '50px', borderRadius: '12px' }}
                >
                    <MenuItem disabled value='' key=''>
                        <Box sx={{display: 'flex', justifyContent: 'start'}}>
                            <FilterListOutlinedIcon />
                            <Typography variant="labelLarge" sx={{my: 'auto', ml: '16px'}}>
                                Meer filters   
                            </Typography> 
                        </Box>
                    </MenuItem>

                </Select> */}
                <Typography onClick={() => {clearFiltering()}} sx={{display: filtering.value ? 'flex' : 'none', cursor: 'pointer', alignSelf: 'center', color: 'secondary.main', borderRadius: '20px', ml:'auto', mr: '8px'}} variant="labelLarge" >
                    Filters wissen  
                </Typography> 
            </Box>

            {/* Order Dashboard */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="titleLarge" sx={{fontWeight: 700}}>
                        Order dashboard  
                    </Typography>
                    <Button sx={{color: 'secondary.main', borderColor: 'secondary.main', border: 1, borderRadius: '20px', width: '280px',
                        '&:hover': {
                        backgroundColor: 'secondary.light',
                        color: '#fff',
                    },}}>
                        <BarChartOutlinedIcon />
                        <Typography variant="labelLarge" sx={{ml: '4px'}}>
                            Bekijk meer data & inzichten
                        </Typography>
                    </Button> 
                </Box>
                <Dashboard 
                    orders={orderCount}
                    revenue={revenue}
                    customers={customerCount}
                    profit={profit}
                    data={graphData}
                />
            </Box>
            {/* Order details */}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', p: '16px', my: '16px', borderRadius: '16px', backgroundColor: '#fff'}}>
                <Details 
                    filters={filtering}
                    orderDateRange={orderDateRange}
                    dueDateRange={dueDateRange}
                    status={status}
                    payment={payment}
                    locations={location}
                    shopType={ShopCategories.DRIVE_THRU}
                />
            </Box>

        </ThemeProvider>
    )
}